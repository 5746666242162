import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddressForm from "./address_form";

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const theme = useTheme();
  return (
    <Dialog
      onClose={() => {
        component.props.setOpen(false);
        component.resetAddress();
      }}
      open={open}
      fullScreen={fullScreen}
    >
      <form onSubmit={(e) => component.addAddress(e)}>
        <MyDialogTitle
          onClose={() => {
            component.props.setOpen(false);
            component.resetAddress();
          }}
        >
          {component.ucfirst("add-new-address-dialog-title")}
        </MyDialogTitle>
        <DialogContent sx={{ p: 1 }}>
          <AddressForm {...props} />
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          <Button
            sx={{
              ...theme.custom.buttons.button2,
              ...{ mb: 2, display: "block", mx: "auto" },
            }}
            type="submit"
          >
            {component.ucfirst("submit-btn")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
