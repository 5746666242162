import Page from "../../Page";
import templateDefault from "../../../templates/default/pages/weekly_steps/weekly_plans";

export default class WeeklyPlans extends Page {
  title = "weekly-plans";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({ "default.step": 1 });
    this.getApp().updateWindowTitle(
      `${this.ucfirst("weekly-meal-packs")} | ${this.trans("window-title")}`
    );
  }
}
