import Page from "../Page";
import templateDefault from "../../templates/default/pages/faq";

export default class Faq extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("faq")} | ${this.trans("window-title")}`
    );
  }
}
