import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/address_dialog";
import Services from "../../../Services/Services";

export default class AddressDialog extends Component {
  id = "address-dialog";

  template = templateDefault;

  default_address = {
    street: "",
    street_number: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
    lat: "",
    lng: "",
    bell: "",
    floor: "",
    notes: "",
  };

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.address": { ...this.default_address },
    });
  }

  selectAddress(id) {
    Services.get("address").then(([addressService]) => {
      addressService
        .getAddress(id)
        .then((addressService) => {
          this.setData({
            "default.address": addressService.getData("address", {
              ...this.default_address,
            }),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  resetAddress() {
    this.setData({
      "default.address": { ...this.default_address },
    });
    this.deleteData("error");
  }

  addAddress(e) {
    e.preventDefault();

    var { address } = this.getData("default", { ...this.default_address });
    const addressId = address._id;
    const onUpdate = addressId ? true : false;

    address = this.validateAddress(address);

    const error = this.getData("error", {});

    if (!error.address) {
      address.address = `${address.street} ${address.street_number}, ${address.city} ${address.postal_code}`;

      Services.get("address").then(([addressService]) => {
        if (onUpdate) {
          addressService
            .updateAddress(addressId, address)
            .then((addressService) => {
              this.props.retrieveAddresses();
              this.props.setOpen(false);
              this.resetAddress();
            })
            .catch((err) => {
              console.log("error", err);
            });
        } else {
          addressService
            .createAddress(address)
            .then((addressService) => {
              this.props.retrieveAddresses();
              this.props.setOpen(false);
              this.resetAddress();
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      });
    }
  }

  validateAddress(address) {
    const error = {
      address: this.getHelpers("validate").validate(address, {
        street: [
          {
            rule: "required",
            message: this.ucfirst("street_address-helpertext"),
          },
        ],
        street_number: [
          {
            rule: "required",
            message: this.ucfirst("street_number-helpertext"),
          },
        ],
        city: [
          {
            rule: "required",
            message: this.ucfirst("city-helpertext"),
          },
        ],
        state: [
          {
            rule: "required",
            message: this.ucfirst("state-helpertext"),
          },
        ],
        postal_code: [
          {
            rule: "required",
            message: this.ucfirst("postal_code-helpertext"),
          },
        ],
        phone: [
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    return address;
  }

  initGoogleAutocomplete() {
    setTimeout(() => {
      let autocomplete;
      if (document.getElementById("autocomplete")) {
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          {
            componentRestrictions: { country: ["gr"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          var place = autocomplete.getPlace();

          this.parseAddressComponents(place);
        });
      }
    }, 500);
  }

  parseAddressComponents(place) {
    let valid = false;
    let address = this.getData("default.address", {
      ...this.default_address,
    });
    if (place.geometry) {
      this.deleteData("error");
      const uluru = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      address["lat"] = uluru.lat;
      address["lng"] = uluru.lng;

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "route": {
            address["street"] = component.short_name;
            break;
          }
          case "street_number": {
            address["street_number"] = component.long_name;
            break;
          }
          case "locality": {
            address["city"] = component.short_name;
            break;
          }
          case "postal_code": {
            address["postal_code"] = component.long_name;
            break;
          }
          case "postal_code_suffix": {
            address[
              "postal_code"
            ] = `${address["postal_code"]}-${component.long_name}`;
            break;
          }
          case "administrative_area_level_1": {
            address["state"] = component.short_name;
            break;
          }
          case "administrative_area_level_3": {
            address["state"] = component.short_name;
            break;
          }
          case "country":
            address["country"] = component.long_name;
            break;
          default:
            break;
        }
      }
      valid = !!(address["street"] && address["street_number"]);

      if (valid) {
        // address["street"] = `${address.street} ${address.street_number}`;
        address[
          "address"
        ] = `${address.street} ${address.street_number}, ${address.city} ${address.postal_code}`;
      }
    }

    this.setData({
      "default.address": address,
      "default.valid": valid,
    });

    if (!valid) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          message: {
            text: this.trans("incomplete-address-message"),
            type: "error",
          },
        });
    }
  }
}
