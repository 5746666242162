import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/steps/plans";
import Services from "../../../Services/Services";

export default class Plans extends Component {
  id = "plans";

  template = templateDefault;

  onAuth() {
    this.getPlans();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({
      "default.discountAllowed":
        this.getHelpers("auth").getUserAttribute("role") === "default",
    });
    this.getPlans();
  }

  getPlans() {
    Services.get("hook").then(async ([service]) => {
      let spot = this.getPage().getParam("company", null);
      let user = null;

      if (
        this.isAuthenticated() &&
        this.getAuth().getUserAttribute("role") !== "default"
      ) {
        user = this.getAuth().getUserAttribute("username");

        try {
          const response = await service.getAuth("business-user-spot", {});
          spot = response.getData();
        } catch (e) {
          //
        }
      }

      try {
        const response = await service.exec("plans", {
          params: {
            spot,
            user,
          },
        });

        let plans = response.getData() || [];

        this.setData({
          "default.plans": plans,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  selectPlan(plan) {
    const c_plan = { ...{ id: plan._id }, ...plan._values };

    if (!c_plan) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          message: {
            text: this.trans("plan not found"),
            type: "error",
          },
        });
    }

    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.createOrder();
        await orderService.updatePlan({ plan: c_plan });
        const order = orderService.getData("order");

        this.getComponents()
          .findByGroup("order")
          .forEach((comp) => {
            comp.setData({ "default.order": order });
          });

        this.getComponents()
          .findById("cart-drawer")
          .forEach((c) => c.processOrder(order));

        this.getPage().redirect(
          c_plan.weekly ? "/weekly/select-address" : "/select-address"
        );
      } catch (orderService) {
        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            message: {
              text: this.trans("plan error"),
              type: "error",
            },
          });
      }
    });
  }
}
