import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Skeleton,
  CardHeader,
  Card,
  Box,
  CardContent,
  Divider,
} from "@mui/material";
import AddressDialog from "../../../../Components/Widgets/Dialogs/AddressDialog";
import CloseIcon from "@mui/icons-material/Close";

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const addresses = component.getData("default.addresses", null);

  const [newAddressDialogOpen, setNewAddressDialogOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [address_id, setId] = React.useState(null);

  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  return (
    <Container maxWidth="sm" sx={{ my: 3, px: { xs: 0, md: 2 } }}>
      <Card elevation={0}>
        <CardHeader
          sx={{ "& .MuiCardHeader-title": { fontSize: 24 } }}
          // action={
          //   <Button
          //     startIcon={
          //       <i className="fak fa-light-location-dot-circle-plus" />
          //     }
          //     variant="outlined"
          //     size="small"
          //     onClick={() => {
          //       setNewAddressDialogOpen(true);
          //       component.activateAutocomplete();
          //     }}
          //     sx={{ textTransform: "none" }}
          //   >
          //     {component.trans("add-new-address-btn")}
          //   </Button>
          // }
          title={component.ucfirst("addresses-title")}
        />
        <CardContent>
          {!addresses ? (
            <Stack spacing={2}>
              {[...new Array(5)].map((date, index) => (
                <Box key={`skeleton-${index}`}>
                  <Stack spacing={1}>
                    <Skeleton variant="rounded" width={100} height={24} />
                    <Skeleton variant="rounded" width={60} height={20} />
                    <Skeleton variant="rounded" width={200} height={16} />
                  </Stack>
                  {5 > index + 1 && <Divider sx={{ my: 2 }} />}
                </Box>
              ))}
            </Stack>
          ) : (
            <Stack spacing={2}>
              {addresses.map((address, index) => (
                <Box key={devHelper.getObjectValue(address, "id")}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                          "& i": { mr: 1 },
                        }}
                      >
                        <i className="fa-solid fa-location-dot" />
                        {`${devHelper.getObjectValue(
                          address,
                          "street"
                        )} ${devHelper.getObjectValue(
                          address,
                          "street_number"
                        )}`}
                      </Typography>
                      <Typography variant="body2">
                        {`${devHelper.getObjectValue(
                          address,
                          "city"
                        )} ${devHelper.getObjectValue(address, "postal_code")}`}
                      </Typography>
                      <Typography variant="caption" sx={{ color: "#707070" }}>
                        {devHelper.getObjectValue(address, "floor") &&
                          `${component.ucfirst(
                            "floor-textfield"
                          )}: ${devHelper.getObjectValue(address, "floor")}`}
                        {devHelper.getObjectValue(address, "bell") &&
                          `, ${component.ucfirst(
                            "bell-textfield"
                          )}: ${devHelper.getObjectValue(address, "bell")}`}
                        {devHelper.getObjectValue(address, "phone") &&
                          `, ${component.ucfirst(
                            "phone-textfield"
                          )}: ${devHelper.getObjectValue(address, "phone")}`}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <IconButton
                        variant="contained"
                        sx={{ fontSize: 16 }}
                        onClick={() => {
                          component.setAddressId(
                            devHelper.getObjectValue(address, "id")
                          );
                          setNewAddressDialogOpen(true);
                          component.activateAutocomplete();
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square" />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        sx={{ fontSize: 16 }}
                        onClick={() =>
                          handleClickOpen(
                            devHelper.getObjectValue(address, "id")
                          )
                        }
                      >
                        <i className="fa-solid fa-trash-can" />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {addresses.length > index + 1 && <Divider sx={{ mt: 2 }} />}
                </Box>
              ))}
              {addresses.length === 0 && (
                <Typography align="center" sx={{ my: 3 }}>
                  {component.ucfirst("zero-addresses")}
                </Typography>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>
      <AddressDialog
        {...{
          ...props,
          ...{
            open: newAddressDialogOpen,
            setOpen: (boolean) => setNewAddressDialogOpen(boolean),
            retrieveAddresses: () => component.retrieveAddresses(),
          },
        }}
      />
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        sx={{
          "& .MuiDialogContent-root": { padding: "16px" },
          "& .MuiDialogActions-root": {
            padding: "8px",
          },
          "& .MuiPaper-rounded": {
            borderRadius: { md: "25px" },
          },
        }}
        maxWidth="xs"
      >
        <MyDialogTitle onClose={() => setOpen(false)}>
          {component.ucfirst("delete-address-title")}
        </MyDialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={(e) => {
              component.deleteAddress(e, address_id);
              setOpen(false);
            }}
          >
            <Typography gutterBottom>
              {component.ucfirst("delete-address-text")}
            </Typography>
            <Button
              type="submit"
              sx={{
                ...theme.custom.buttons.button2,
                ...{ display: "block", mx: "auto", mt: 2 },
              }}
              disableRipple
            >
              {component.ucfirst("confirm-btn")}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
