import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/steps/datetime_picker";
import Services from "../../../Services/Services";

export default class DatetimePicker extends Component {
  id = "datetime-picker";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    Services.get("order,hook").then(async ([orderService, hookService]) => {
      const time = this.getHelpers("date-time").getCurrentTime();
      const datetime = [time.split(":")[0]]
        .join("")
        .replaceAll(" ", "")
        .replaceAll("-", "");

      try {
        orderService = await orderService.fetchOrder();

        const order = orderService.getOrder();
        if (order.spot && order.spot._id) {
          hookService = await hookService.exec("spot-menus", {
            params: { spotId: order.spot._id, datetime },
          });

          var dates = hookService.getData() || {};

          // for (var i = 0; i <= 3; i++) {
          //   dates = dates.concat(dates);
          // }

          this.setData({
            "default.order": order,
            "default.dates": dates,
          });
        } else {
          this.getPage().redirect("/select-address");
        }
      } catch (service) {
        this.checkOrderError(service);
      }
    });
  }

  selectDayTime(day, time) {
    const c_day = { ...{ id: day._id }, ...day._values };
    const c_time = { ...{ id: time.item._id }, ...time.item._values };

    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.updateDate({ date: c_day });
        await orderService.updateTime({ time: c_time });
        const order = orderService.getData("order");
        this.getComponents()
          .findByGroup("order")
          .forEach((comp) => {
            comp.setData({ "default.order": order });
          });
        this.getPage().redirect("/menu");
      } catch (orderService) {}
    });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          this.getPage().redirect("/meal-packs");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
