import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/weekly_steps/weekly_menu";
import Services from "../../../Services/Services";

export default class WeeklyMenu extends Component {
  id = "weekly-menu";
  group = "order";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.getMenu();
  }

  getMenu() {
    Services.get("order,hook").then(async ([orderService, hookService]) => {
      var order = null;
      const devHelper = this.getHelpers("dev");
      const dateTimeHelper = this.getHelpers("date-time");

      try {
        await orderService.fetchOrder();
        order = orderService.getData("order");
        const spotId = devHelper.getObjectValue(order, "spot._id");

        const { days_amount, weeks_amount, day_meals, delivery_days } =
          devHelper.getObjectValue(order, "weekly");
        const v = dateTimeHelper
          .getCurrentTime()
          .replaceAll("-", "")
          .replace(" ", "")
          .split(":")
          .shift();

        const hookService1 = await hookService.exec("spot-weekly", {
          params: {
            spotId,
            days: delivery_days,
            daysAmount: delivery_days.split(",").length * weeks_amount,
            v: v,
          },
        });

        const spotMenus = hookService1.getData();

        const promises = spotMenus
          .map(({ _values }) => {
            const { menu } = _values;
            return menu;
          })
          .map((menu) => {
            return hookService.exec("category-meals", {
              params: {
                categorySlug: menu ? menu : "default",
              },
            });
          });

        let weekMenus = {};

        const items = devHelper.getObjectValue(order, "items", []);

        (await Promise.all(promises)).forEach((response, index) => {
          const spotMenu = spotMenus[index];
          const { date, day, delivery_date, menu, week_index } =
            spotMenu._values;
          var meals = response
            .getData()
            .filter(
              (meal) =>
                meal.item._values.meal_types[0].item._values.slug !== "extra"
            );

          if (!weekMenus.hasOwnProperty(`week-${week_index}`)) {
            weekMenus[`week-${week_index}`] = [];
          }

          var datesItems = items.filter((item) => item.date === date);

          meals.forEach((meal) => {
            var cartItem = datesItems.find(
              (item) => item.item.slug === meal.item._values.slug
            );

            meal.in_cart = cartItem ? 1 : 0;
          });

          meals.sort((a, b) => b.in_cart - a.in_cart);

          weekMenus[`week-${week_index}`].push({
            id: spotMenu._id,
            date,
            day,
            delivery_date,
            menu,
            meals,
            max_meals: this.getMaxMeals(day, day_meals, days_amount),
          });
        });

        this.setData({
          "default.order": order,
          "default.menus": weekMenus,
        });
      } catch (orderService) {
        this.checkOrderError(orderService);
      }
    });
  }

  getMaxMeals(day, day_meals, days_amount) {
    const combinatios = {
      5: { monday: 3, tuesday: 3, thursday: 2, friday: 2 },
      6: { monday: 3, tuesday: 3, thursday: 3, friday: 3 },
      7: { monday: 3, tuesday: 3, thursday: 4, friday: 4 },
    };

    return combinatios[days_amount][day] * day_meals;
  }

  updateCart(meal, amount, date, delivery_date) {
    Services.get("order").then(async ([orderService]) => {
      try {
        const { _id } = meal.item;

        var order = orderService.getOrder();

        const cart_amount = this.getProductAmount(date, _id, order);

        amount = cart_amount + amount;

        await orderService.updateItem({
          itemId: _id,
          itemType: "meal",
          amount: amount,
          itemDate: date,
          itemDeliveryDate: delivery_date,
          itemInstance: date,
        });

        order = orderService.getOrder();

        if (amount > cart_amount) {
          this.getApp().sendToGA4({ ...meal }, "add_to_cart", "item._values");
        }

        this.getComponents()
          .findByGroup("order")
          .forEach((comp) => {
            comp.setData({ "default.order": order });
          });

        this.getComponents()
          .findById("cart-drawer")
          .first()
          .processOrder(order);

        this.setData({
          "default.amount": amount,
        });
      } catch (orderService) {
        this.checkOrderError(orderService);
      }
    });
  }

  getProductAmount(date, id, order) {
    if (order) {
      const { items = [] } = order;

      const { amount = 0 } =
        items.find((orderItem) => {
          return id === orderItem.item.id && date === orderItem.date;
        }) || {};
      return amount;
    }
    return 0;
  }

  getDatesTotal(date, order) {
    if (order) {
      const { items = [] } = order;
      let total = 0;
      items
        .filter((orderItem) => {
          return date === orderItem.date;
        })
        .forEach((orderItem) => (total += orderItem.amount));

      return total;
    }
    return 0;
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          this.getPage().redirect("/meal-packs");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
