import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product/first_section";
import Services from "../../Services/Services";

export default class Product extends Component {
  id = "product";
  template = templateDefault;
  group = "order";

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();
    this.getOrder();
  }

  setProduct() {
    //Get product
    const slug = this.getPage().getParam("slug", "");
    Services.get("hook").then(async ([hookService]) => {
      try {
        var response = await hookService.exec("meal-allergen", {
          params: {},
        });

        const allergens = response.getData();

        response = await hookService.exec("meal", {
          params: { slug: slug },
        });
        const meal = response.getData();
        //Set window title
        const account = this.getHelpers("env").getDefaultAccount();
        const repository = this.getHelpers("env").getDefaultRepository();
        const translation =
          (await this.getHelpers("translate").scope({
            account: account,
            repository: repository,
            entity: "item",
            entity_slug: slug,
            scope: "default",
            slug: "name",
          })) || [];

        const name = (
          translation.find((tran) => tran.slug === "title") || {
            translation: "",
          }
        ).translation;

        if (name) {
          this.getApp().updateWindowTitle(
            `${name} | ${this.ucfirst("window-title")}`
          );
        }

        this.setData({
          "default.meal": meal,
          "default.allergens": allergens,
        });

        this.getApp().sendToGA4(meal, "view_item", "_values");
      } catch (e) {
        console.log(e);
      }
    });
  }

  addToRecentlyViewed(slug) {
    Services.get("recentlyViewed").then(([recentlyViewed]) => {
      recentlyViewed.addToRecentlyViewed(slug);
    });

    return this;
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  updateCart(meal, amount) {
    Services.get("order,hook").then(async ([orderService, hookService]) => {
      const { _id } = meal;
      var order = orderService.getOrder();

      //process the cart action

      const cart_amount = this.getProductAmount(_id, order);
      amount = cart_amount + amount;

      await orderService.updateItem({
        itemId: _id,
        itemType: "meal",
        amount: amount,
      });

      order = orderService.getOrder();

      const { plan = {} } = order;

      if (plan.changed) {
        const { min, total, price } = plan;

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            message: {
              text: `${this.ucfirst("meal-pack-changed")} ${min}${
                total - min > 0 ? "+" : ""
              } ${this.trans("with-price")} ${this.getApp().priceFormater(
                price
              )} / ${this.trans("meal")}`,
              type: "info",
              duration: 2000,
            },
          });
      }

      if (amount > cart_amount) {
        this.getApp().sendToGA4({ ...meal }, "add_to_cart", "_values");
      }

      this.getComponents()
        .findByGroup("order")
        .forEach((comp) => {
          comp.setData({ "default.order": order });
        });

      this.getComponents().findById("cart-drawer").first().processOrder(order);

      this.setData({
        "default.amount": amount,
      });
    });
  }

  getProductAmount(id, order) {
    if (order) {
      const { items = [] } = order;

      const { amount = 0 } =
        items.find((orderItem) => {
          return id === orderItem.item.id;
        }) || {};

      return amount;
    }
    return 0;
  }
}
