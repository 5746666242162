import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Grid,
  Chip,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  IconButton,
  InputAdornment,
  FormControl,
} from "@mui/material";

const NewCouponField = styled(FormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#800080",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#800080",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#800080",
    },
  },
}));

export default function CouponsSection(props) {
  const { component, order } = props;
  const devHelper = component.getHelpers("dev");

  const coupons = component.getData("default.coupons", []);
  const activeCoupon = devHelper.getObjectValue(order, "coupon") ? true : false;

  return (
    <Box
      sx={{
        px: { xs: 2, md: 3 },
        py: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: { xs: "20px", sm: "24px", md: "28px" },
          lineHeight: 1,
          mb: 2,
        }}
      >
        {component.ucfirst("checkout-title-2")}
      </Typography>
      <Box>
        {(coupons.length > 0 || activeCoupon) && (
          <Typography
            sx={{
              fontSize: 16,
              mb: 1,
            }}
          >
            {component.trans(activeCoupon ? "active-coupon" : "my-coupons")}
          </Typography>
        )}
        <Grid container spacing={1}>
          {activeCoupon ? (
            <Grid item>
              <Chip
                label={`${devHelper
                  .getObjectValue(order, "coupon.slug")
                  .toUpperCase()} ${devHelper
                  .getObjectValue(order, "coupon.name")
                  .toUpperCase()}`}
                variant="filled"
                color="secondary"
                onDelete={() => component.releaseCoupon()}
              />
            </Grid>
          ) : (
            <>
              {coupons.map((c) => {
                const { name, slug, _id } = c;

                return (
                  <Grid item key={_id}>
                    <Chip
                      label={`${slug.toUpperCase()} ${name.toUpperCase()}`}
                      onClick={() => component.activateCoupon(_id)}
                      variant={
                        devHelper.getObjectValue(order, "coupon._id") !== _id
                          ? "outlined"
                          : "filled"
                      }
                      color="secondary"
                      onDelete={
                        devHelper.getObjectValue(order, "coupon._id") !== _id
                          ? undefined
                          : () => component.releaseCoupon()
                      }
                    />
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 16,
            mb: 1,
          }}
        >
          {component.ucfirst("checkout-subtitle-2-1")}
        </Typography>
        <form onSubmit={(e) => component.updateCouponByCode(e)}>
          <NewCouponField
            variant="outlined"
            fullWidth
            name="couponCode"
            type="text"
            id="couponCode"
            error={component.dataExists("error.couponCode")}
            sx={{ maxWidth: 340 }}
          >
            <InputLabel>{component.trans("promo-code")}</InputLabel>
            <OutlinedInput
              placeholder={component.trans("promo-holder")}
              value={component.getData("default.couponCode", "")}
              onChange={(e) => {
                component.setData({
                  "default.couponCode": e.target.value,
                });
              }}
              label={component.trans("promo-code")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    disableRipple
                    type="submit"
                    size="medium"
                    color="secondary"
                  >
                    <i className="fa-solid fa-circle-chevron-right" />
                  </IconButton>
                </InputAdornment>
              }
            />
            {component.dataExists("error.couponCode") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.couponCode")}
              </FormHelperText>
            )}
          </NewCouponField>
        </form>
      </Box>
    </Box>
  );
}
