import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/drawers/cart_drawer";
import Services from "../../../Services/Services";

export default class CartDrawer extends Component {
  id = "cart-drawer";
  group = "order";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.getOrder();
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");

          this.processOrder(order);
        })
        .catch((e) => {
          // console.log(e);
        });
    });
  }

  processOrder(order) {
    try {
      const meals = [];
      const extra = [];
      var type = null;
      const devHelper = this.getHelpers("dev");
      let weekMenus = {};
      let dates = [];

      if (devHelper.getObjectValue(order, "plan.weekly")) {
        const { days_amount, day_meals } = devHelper.getObjectValue(
          order,
          "weekly",
          {}
        );

        const items = devHelper.getObjectValue(order, "items", []);

        items.forEach((meal) => {
          dates.push(meal.date);
        });

        dates
          .filter((item, index) => dates.indexOf(item) === index)
          .sort()
          .forEach((date) => {
            let week_index = this.getWeekNumber(date);

            if (!weekMenus.hasOwnProperty(`week-${week_index}`)) {
              weekMenus[`week-${week_index}`] = [];
            }

            let dates_meals = items.filter((meal) => meal.date === date);

            weekMenus[`week-${week_index}`].push({
              date,
              dates_total: this.getDatesTotal(date, dates_meals),
              meals: dates_meals,
              max_meals: this.getMaxMeals(
                this.getApp().dayFormater(date),
                day_meals,
                days_amount
              ),
            });
          });
      } else {
        devHelper.getObjectValue(order, "items", []).forEach((meal) => {
          if (meal.item.meal_types.length) {
            type = meal.item.meal_types[0].item._values.slug;
            switch (type) {
              case "deal": {
                meals.push(meal);
                break;
              }
              case "extra": {
                extra.push(meal);
                break;
              }
              default:
                break;
            }
          }
        });
      }

      this.setData({
        "default.order": order,
        "default.meals": meals,
        "default.extra": extra,
        "default.menus": weekMenus,
      });
    } catch (error) {
      console.log(error);
    }

    return this;
  }

  getMaxMeals(day, day_meals, days_amount) {
    const combinatios = {
      5: { monday: 3, tuesday: 3, thursday: 2, friday: 2 },
      7: { monday: 3, tuesday: 3, thursday: 4, friday: 4 },
    };

    return combinatios[days_amount][day] * day_meals;
  }

  updateCart(meal, new_amount) {
    Services.get("order,hook").then(async ([orderService, hookService]) => {
      const { id } = meal.item;
      const { amount } = meal;
      var order = orderService.getOrder();

      //process the cart action

      new_amount = amount + new_amount;

      await orderService.updateItem({
        itemId: id,
        itemType: "meal",
        amount: new_amount,
      });

      order = orderService.getOrder();

      const { plan = {} } = order;

      if (plan.changed) {
        const { min, total, price } = plan;

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            message: {
              text: `${this.ucfirst("meal-pack-changed")} ${min}${
                total - min > 0 ? "+" : ""
              } ${this.trans("with-price")} ${this.getApp().priceFormater(
                price
              )} / ${this.trans("meal")}`,
              type: "info",
              duration: 2000,
            },
          });
      }

      if (new_amount > amount) {
        this.getApp().sendToGA4({ ...meal }, "add_to_cart", "item");
      }

      this.getComponents()
        .findByGroup("order")
        .forEach((comp) => {
          comp.setData({ "default.order": order });
        });

      this.processOrder(order);

      this.setData({
        "default.amount": new_amount,
      });
    });
  }

  updateWeeklyCart(meal, new_amount, date, delivery_date) {
    Services.get("order,hook").then(async ([orderService, hookService]) => {
      const { id } = meal.item;
      const { amount } = meal;
      var order = orderService.getOrder();

      new_amount = amount + new_amount;

      await orderService.updateItem({
        itemId: id,
        itemType: "meal",
        amount: new_amount,
        itemDate: date,
        itemDeliveryDate: delivery_date,
        itemInstance: date,
      });

      order = orderService.getOrder();

      if (new_amount > amount) {
        this.getApp().sendToGA4({ ...meal }, "add_to_cart", "item");
      }

      this.getComponents()
        .findByGroup("order")
        .forEach((comp) => {
          comp.setData({ "default.order": order });
        });

      this.processOrder(order);

      this.setData({
        "default.amount": new_amount,
      });
    });
  }

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }

  clearCart() {
    this.deleteData("default");
    return this;
  }

  getWeekNumber(dateString) {
    const date = new Date(dateString);
    const firstJan = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstJan) / 86400000;
    return Math.ceil((pastDaysOfYear + firstJan.getDay() + 1) / 7);
  }

  getDatesTotal(date, meals) {
    if (meals) {
      let total = 0;
      meals
        .filter((orderItem) => {
          return date === orderItem.date;
        })
        .forEach((orderItem) => (total += orderItem.amount));

      return total;
    }
    return 0;
  }
}
