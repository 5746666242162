import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Hook extends Service {
  exec(hook, options) {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();
    const version = 1;

    return new Promise((resolve, reject) => {
      MainApi.hook(account, repository, hook, version, options).then((r) => {
        if (r.isValid()) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }

  getAuth(hook, options) {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();
    const version = 1;

    return new Promise((resolve, reject) => {
      MainApi.getAuth(account, repository, hook, version, options).then((r) => {
        if (r.isValid()) {
          resolve(r);
        } else {
          reject(r);
        }
      });
    });
  }

  getAuthOpen(hook, options) {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();
    const version = 1;

    return new Promise((resolve, reject) => {
      MainApi.hookAuthOpen(account, repository, hook, version, options).then(
        (r) => {
          if (r.isValid()) {
            resolve(r);
          } else {
            reject(r);
          }
        }
      );
    });
  }

  post(hook, options) {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();
    const version = 1;

    return new Promise((resolve, reject) => {
      MainApi.hookPost(account, repository, hook, version, options).then(
        (r) => {
          if (r.isValid()) {
            resolve(r);
          } else {
            reject(r);
          }
        }
      );
    });
  }
}

export default new Hook();
