import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/menu_sample";
// import Services from "../../../Services/Services";

export default class MenuSample extends Component {
  id = "menu-sample";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.categories": [
        { slug: "low-carb" },
        { slug: "high-protein" },
        { slug: "low-calories" },
      ],
      "default.lists": [
        {
          meals: [
            "50-00008",
            "50-00063",
            "50-00021",
            "50-00042",
            "50-00050",
            "50-00002",
          ],
        },
        {
          meals: [
            "50-00035",
            "50-00048",
            "50-00049",
            "50-00004",
            "50-00019",
            "50-00032",
          ],
        },
        {
          meals: [
            "50-00045",
            "50-00001",
            "50-00057",
            "50-00039",
            "50-00056",
            "50-00062",
          ],
        },
      ],
    });
  }

  // getFeaturedCategories() {
  //   Services.get("hook").then(([service]) => {
  //     service
  //       .exec("meal-categories-featured", {
  //         params: {},
  //       })
  //       .then((response) => {
  //         const categories = response.getData() || {};

  //         this.setData({
  //           "default.categories": categories,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   });
  // }

  // getFeaturedItems() {
  //   Services.get("hook").then(([service]) => {
  //     service
  //       .exec("meals-featured", {
  //         params: {},
  //       })
  //       .then((response) => {
  //         const meals = response.getData() || {};

  //         this.setData({
  //           "default.meals": meals,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   });
  // }

  // getCategorysMeals(cat_slug, meals) {
  //   const cat_meals = meals
  //     .filter(
  //       (item) =>
  //         item._values.meal_categories.findIndex(
  //           (cat) => cat.item._values.slug === cat_slug
  //         ) !== -1
  //     )
  //     .sort((a, b) => {
  //       const mealA =
  //         a._values.meal_categories.find(
  //           (cat) => cat.item._values.slug === cat_slug
  //         ) || {};

  //       const positionA = mealA.position || 1000;

  //       const mealB =
  //         b._values.meal_categories.find(
  //           (cat) => cat.item._values.slug === cat_slug
  //         ) || {};

  //       const positionB = mealB.position || 1000;

  //       return positionA - positionB;
  //     });

  //   return cat_meals;
  // }
}
