import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Typography,
  Box,
  Paper,
  Button,
  Stack,
} from "@mui/material";

export default function PlanDesktop(props) {
  const theme = useTheme();
  const { component, plan, index } = props;
  const { discount = false } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const { plans = [], discountAllowed = null } = component.getData(
    "default",
    {}
  );
  const company = component.getPage().getParam("company");
  const { name, total, price, min, weekly } = devHelper.getObjectValue(
    plan,
    "_values"
  );

  const showDiscount = discount && discountAllowed;

  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("top-banner").offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <Paper
      elevation={6}
      sx={{
        border: weekly ? "10px solid #993366" : "10px solid #FF6F4D",
        bgcolor: "#fff",
        borderRadius: "20px",
        position: "relative",
        textAlign: "center",
        maxWidth: 320,
        height: 360,
        mx: "auto",
        mt: { sm: 10, lg: 4 },
        pt: 8,
        pb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() =>
        company !== null ? handleClick() : component.selectPlan(plan)
      }
    >
      {weekly ? (
        <>
          <Box
            sx={{
              bgcolor: "#fff",
              width: 90,
              height: 90,
              mx: "auto",
              position: "absolute",
              top: -60,
              left: "50%",
              transform: "translate(-50%,0)",
              "& lord-icon": {
                width: 90,
                height: 90,
              },
            }}
          >
            <lord-icon
              src="https://cdn.lordicon.com/hffrpcip.json"
              trigger="loop"
              stroke="bold"
              delay="1500"
              colors="primary:#121331,secondary:#993366"
            />
          </Box>
          <Stack
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mt: -1 }}>
              <Typography
                component="h2"
                sx={{
                  color: "#000",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                {component.ucfirst("weekly")}
              </Typography>
              <Typography
                align="center"
                component="h3"
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: 1,
                }}
              >
                {component.trans("meal-packs")}
              </Typography>
            </Box>
            <Typography
              align="center"
              component="h4"
              sx={{
                fontSize: 16,
                color: "#000",
                maxWidth: 220,
              }}
            >
              {component.trans("weekly-plan-desc")}
            </Typography>
            <Stack
              spacing={3}
              sx={{
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  "& span": {
                    lineHeight: 1,
                    color: "#000",
                  },
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  {component.trans("up-to")}
                </Box>
                <Box
                  component="span"
                  sx={{
                    fontSize: 22,
                    fontWeight: 600,
                  }}
                >
                  {component
                    .getApp()
                    .priceFormater(component.trans("min-weekly-price") * 1)}
                </Box>
                <Box
                  component="span"
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {` / ${component.trans("meal")}`}
                </Box>
              </Stack>
              <Button
                sx={{
                  ...theme.custom.buttons.button3,
                  bgcolor: "transparent",
                  color: "#000",
                  border: "2px solid #000",
                  "&:hover": {
                    bgcolor: "transparent",
                    color: "#000",
                  },
                }}
              >
                {component.ucfirst("select")}
              </Button>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <CardMedia
            component="img"
            image={`${filesUrl}/marketing/bag.webp?v=${img_version}`}
            sx={{
              width: `${80 + (plans.length - index) * 8}px`,
              height: `${80 + (plans.length - index) * 8}px`,
              mx: "auto",
              position: "absolute",
              top: -46 - (plans.length - index) * 8,
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            alt={name}
            title={name}
          />
          <Stack
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack spacing={0}>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.title,
                  ...{
                    position: "relative",
                    mx: "auto",
                    lineHeight: 1,
                    fontSize: 65,
                  },
                }}
              >
                {total - min > 0 && (
                  <Typography
                    style={{
                      fontSize: 30,
                      position: "absolute",
                      top: -15,
                      right: -15,
                      fontWeight: 600,
                      color: "#000",
                      lineHeight: 1,
                    }}
                  >
                    +
                  </Typography>
                )}
                <Typography
                  sx={{
                    display: showDiscount ? "block" : "none",
                    position: "absolute",
                    top: "50%",
                    right: -80,
                    transform: "translate(0, -50%)",
                    "& i": {
                      fontSize: 24,
                      color: "#FF6F4D",
                    },
                  }}
                >
                  <i
                    className="fa-solid fa-minus fa-bounce"
                    style={{ fontSize: 16 }}
                  />
                  <i className="fa-solid fa-1 fa-bounce" />
                  <i className="fa-solid fa-0 fa-bounce" />
                  <i className="fa-solid fa-percent fa-bounce" />
                </Typography>
                {min}
              </Typography>
              <Typography
                align="center"
                component="h3"
                sx={{
                  ...theme.custom.titles.title,
                  ...{
                    fontSize: {
                      xs: 20,
                      sm: 22,
                      md: 25,
                      lg: 25,
                    },
                  },
                }}
              >
                {component.trans(min > 1 ? "meals" : "meal")}
              </Typography>
              <Typography
                align="center"
                component="h4"
                sx={{
                  ...theme.custom.titles.description,
                  ...{ fontSize: 16 },
                }}
              >
                {component.trans("per-order")}
              </Typography>
            </Stack>
            <Stack
              spacing={3}
              sx={{
                alignItems: "center",
              }}
            >
              <Stack>
                <Box
                  sx={{
                    visibility: showDiscount ? "visible" : "hidden",
                    color: "#000",
                    opacity: 0.6,
                    fontSize: 15,
                  }}
                >
                  <del>{component.getApp().priceFormater(price)}</del>
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: "flex-end",
                    justifyContent: "center",
                    "& span": {
                      lineHeight: 1,
                      color: "#000",
                    },
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      color: showDiscount ? "#FF6F4D !important" : "#000",
                      fontSize: 22,
                      fontWeight: 600,
                    }}
                  >
                    {showDiscount
                      ? component.getApp().priceFormater(price - price * 0.1)
                      : component.getApp().priceFormater(price)}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {` / ${component.trans("meal")}`}
                  </Box>
                </Stack>
              </Stack>
              <Button
                sx={{
                  ...theme.custom.buttons.button2,
                  ...{
                    bgcolor: "#000",
                    color: "#fff",
                    border: "2px solid #000",
                    "&:hover": {
                      bgcolor: "#000",
                    },
                  },
                }}
              >
                {component.ucfirst("select")}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </Paper>
  );
}
