import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Link,
  Grid,
  Container,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import ContactUs from "../../../../Components/Widgets/Dialogs/ContactUs";
import { isMobile } from "react-device-detect";

function DevelopedBy() {
  return (
    <Box sx={{ bgcolor: "#e8e8e8", pt: 2, pb: { xs: "60px", md: 2 } }}>
      <Typography variant="body2" align="center" sx={{ color: "#818181" }}>
        {"Developed by "}
        <Link
          color="inherit"
          href="https://www.automatapp.com/"
          underline="none"
          sx={{ fontWeight: 600 }}
        >
          Automatapp
        </Link>
        {` ${new Date().getFullYear()}`}
      </Typography>
    </Box>
  );
}

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#fff",
  fontWeight: "600",
  marginBottom: "15px",
}));

const InfoLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#fff",
  "& i": {
    fontSize: "20px",
    marginRight: "16px",
  },
}));

export default function StickyFooter(props) {
  const { component } = props;
  // const filesUrl = component.getHelpers("env").get("files_url");
  const theme = useTheme();
  // const filesUrl = component.getHelpers("env").get("files_url");
  // const img_version = component.getHelpers("value").getValue("images-version");

  const [contactUsOpen, setContactUsOpen] = React.useState(false);

  const myLink = {
    textDecoration: "none",
    color: "#fff",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 500,
    "& i": {
      minWidth: 45,
      fontSize: "30px",
    },
    "& div": {
      textAlign: "center",
      display: "inline-flex",
    },
    "&:hover": {
      color: "#d1d1d1",
    },
  };

  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        backgroundColor: "#000",
      }}
    >
      <Container maxWidth="lg" sx={{ pb: 1, pt: 4 }}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 6 }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <FooterTitle>{component.trans("footer-title-1")}</FooterTitle>
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                component
                  .getApp()
                  .newsletterSubscribe(
                    component.getData("default.subscribe.email")
                  );

                component.setData({ "default.subscribe.email": "" });
              }}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                component="h1"
                sx={{
                  fontSize: 16,
                  color: "#fff",
                }}
              >
                {component.ucfirst("subscribe-info")}
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                type="text"
                placeholder="Email"
                onChange={(e) =>
                  component.setData({
                    "default.subscribe.email": e.target.value,
                  })
                }
                inputProps={{ style: { textAlign: "center" } }}
                value={component.getData("default.subscribe.email", "")}
                sx={{
                  borderRadius: 50,
                  width: "200px",
                  mx: "auto",
                  fontSize: 16,
                  bgcolor: "#fff",
                  verticalAlign: "middle",
                  "& > *": {
                    borderRadius: 50,
                    height: "48px",
                  },
                  input: {
                    color: "#000",
                    "&::placeholder": {
                      opacity: 0.8,
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
              <Button
                sx={{
                  ...theme.custom.buttons.button2,
                  ...{
                    mt: 1,
                    bgcolor: "transparent",
                    color: "#fff",
                    border: "2px solid #FF6F4D",
                    mx: "auto",
                    "&:hover": { bgcolor: "#000", color: "#fff" },
                  },
                }}
                type="submit"
              >
                {component.ucfirst("subscribe-btn")}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FooterTitle>{component.trans("footer-title-2")}</FooterTitle>
            <Link sx={myLink} component={RouterLink} to="/faq">
              <Box>
                <i className="fa-light fa-circle-question" />
              </Box>
              {component.ucfirst("faq")}
            </Link>
            <Link
              sx={myLink}
              href={"mailto:" + component.trans("shop-email")}
              target="_blank"
            >
              <Box>
                <i className="fa-light fa-circle-envelope" />
              </Box>
              {component.trans("shop-email")}
            </Link>
            <Link sx={myLink} href={"tel:" + component.trans("phone-number")}>
              <Box>
                <i className="fa-light fa-circle-phone" />
              </Box>
              {component.trans("phone-number")}
            </Link>
            <Button
              sx={{
                ...myLink,
                ...{
                  p: 0,
                  textTransform: "none",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "transparent",
                    color: "#d1d1d1",
                  },
                },
              }}
              disableRipple
              onClick={() => setContactUsOpen(true)}
            >
              <Box>
                <i className="fa-light fa-comments-question-check" />
              </Box>
              {component.trans("contact-form")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FooterTitle>{component.trans("footer-title-3")}</FooterTitle>
            <Stack direction="column">
              <Box>
                <InfoLink
                  href="https://www.instagram.com/coozein/"
                  target="_blank"
                  aria-label="instagram"
                >
                  <i
                    className="fa-brands fa-instagram"
                    style={{ fontSize: "35px" }}
                  />
                </InfoLink>
                <InfoLink
                  href="https://www.facebook.com/coozein"
                  target="_blank"
                  aria-label="facebook"
                >
                  <i
                    className="fa-brands fa-square-facebook"
                    style={{ fontSize: "35px" }}
                  />
                </InfoLink>
                <InfoLink
                  href=" https://www.linkedin.com/company/coozein"
                  target="_blank"
                  aria-label="linkedin"
                >
                  <i
                    className="fa-brands fa-linkedin"
                    style={{ fontSize: "35px" }}
                  />
                </InfoLink>
              </Box>
              <Button
                sx={{
                  fontSize: 18,
                  mt: 6,
                  color: "#fff",
                  textTransform: "none",
                  width: 210,
                  border: "1px solid #fff",
                }}
                variant="outlined"
                startIcon={
                  <i className="fa-solid fa-person-walking-with-cane" />
                }
                onClick={() => {
                  const el = document.getElementById(
                    "userwayAccessibilityIcon"
                  );

                  if (el) {
                    if (!isMobile) {
                      el.click();
                    } else {
                      var event1 = new Event("touchstart");
                      var event2 = new Event("touchend");
                      el.dispatchEvent(event1);
                      el.dispatchEvent(event2);
                    }
                  }
                }}
              >
                {component.ucfirst("accessibility")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: 14,
            color: "#fff",
            mt: 3,
            textAlign: { xs: "center", md: "right" },
          }}
        >
          {`Copyright © Coozein ${new Date().getFullYear()} - `}{" "}
          <Link
            component={RouterLink}
            to="/terms-and-conditions/terms-of-use"
            underline="hover"
            sx={{
              color: "#fff",
              "&:hover": {
                color: "#d1d1d1",
              },
            }}
          >
            {component.trans("terms-and-conditions")}
          </Link>
        </Typography>
      </Container>
      <DevelopedBy />
      <ContactUs
        {...{
          ...props,
          ...{
            open: contactUsOpen,
            setOpen: (boolean) => setContactUsOpen(boolean),
          },
        }}
      />
    </Box>
  );
}
