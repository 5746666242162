import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/forgot_password_dialog";
import Services from "../../../Services/Services";

export default class ForgotPasswordDialog extends Component {
  id = "forgot-password-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }

  requestPassword(e) {
    if (e) e.preventDefault();

    const { username } = this.getData("default.fields.request", {});
    const error = {};

    if (!username) {
      error["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username, {})) {
        error["username"] = this.ucfirst("invalid-email");
      }
    }

    this.setData({
      "error.fields.request": error,
    });

    if (Object.keys(error).length === 0) {
      Services.get("auth").then(([authService]) => {
        authService
          .passwordRequest({ username })
          .then((authService) => {
            if (this.props.setOpen) {
              this.props.setOpen(false);
            }
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                message: {
                  text: authService.getData("_response").getMessage(),
                  type: "error",
                },
              });
          });
      });
    }
  }
}
