import React from "react";
import Box from "@mui/material/Box";
import Navbar from "../../../../Components/Widgets/Navbar/Navbar";
import WeeklyPlans from "../../../../Components/Widgets/WeeklySteps/WeeklyPlans";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#f4f4f4",
      }}
    >
      <Navbar {...props} />
      <WeeklyPlans {...props} />
    </Box>
  );
};

export default ExportDefault;
