import React, { useLayoutEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Skeleton,
} from "@mui/material";

export default function Poduct_List_View(props) {
  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(targetRef.current.clientWidth);
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();

  return (
    <Grid item lg={3} md={4} xs={6}>
      <Card
        elevation={0}
        sx={{
          borderRadius: "12px",
          position: "relative",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          pt: 1,
        }}
      >
        <Box sx={{ px: { xs: 2.5, md: 4.5 } }}>
          <Box ref={targetRef}>
            {img_height > 0 && (
              <Skeleton variant="circular" height={img_height} width="100%" />
            )}
          </Box>
        </Box>
        <CardContent sx={{ px: 1, py: 1, pt: 0 }}>
          <Skeleton variant="rounded" height={35} width="100%" sx={{ mt: 1 }} />
        </CardContent>
        <CardActions
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            mt: { xs: 1, md: 3 },
            px: 1,
            py: 1,
            pt: 0,
          }}
        >
          <Skeleton variant="rounded" height={28} width={60} />
          <Skeleton
            variant="rounded"
            height={20}
            width={200}
            sx={{ mt: 1, ml: 0 }}
          />
          <Skeleton
            variant="rectangular"
            height={46}
            width={150}
            sx={{ mt: 2, borderRadius: 50, p: 0, ml: 0 }}
          />
        </CardActions>
      </Card>
    </Grid>
  );
}
