import React from "react";
import {
  Toolbar,
  useScrollTrigger,
  AppBar,
  useMediaQuery,
  Box,
  Container,
  CardMedia,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";

export default function Navbar(props) {
  const { component } = props;
  const theme = useTheme();
  const img_version = component.getHelpers("value").getValue("images-version");
  const appbarHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.custom.appbar_height.desktop
    : theme.custom.appbar_height.mobile;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  });

  return (
    <>
      <AppBar
        position="fixed"
        elevation={trigger ? 3 : 0}
        sx={{
          bgcolor: "#fff",
        }}
      >
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 1, md: 2, lg: 4 },
            height: {
              xs: theme.custom.appbar_height.mobile,
              md: theme.custom.appbar_height.desktop,
            },
          }}
        >
          <CardMedia
            component="img"
            image={`/logos/logo.webp?v=${img_version}`}
            sx={{
              width: { xs: 160, lg: 180 },
              height: "auto",
            }}
            alt="Coozein Home"
            title="Coozein Home"
          />
          <LanguageSelect {...props} />
        </Container>
      </AppBar>
      <Box sx={{ height: appbarHeight }} />
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: "0.1px !important" }} />
    </>
  );
}
