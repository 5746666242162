import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/login";
import Services from "../../Services/Services";

export default class Login extends Component {
  template = templateDefault;

  googleAuthLoaded = false;

  onLoad(data) {
    super.onLoad(data);

    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loadFB({ window, document, page: this.getPage() });
    });

    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loadGoogle({
        window,
        document,
        page: this.getPage(),
        cb: async () => await this.createAddress(),
      });
    });
  }

  loginFB() {
    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loginFB({
        window,
        document,
        page: this.getPage(),
        cb: async () => await this.createAddress(),
      });
    });
  }

  deleteOrder() {
    Services.get("order").then(([orderService]) => {
      orderService.delete();
    });
  }

  login(e) {
    e.preventDefault();

    const { username, password } = this.getData("default.fields.auth", {});
    const error = {};

    if (!username) {
      error["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username, {})) {
        error["username"] = this.ucfirst("invalid-email");
      }
    }

    if (!password) {
      error["password"] = this.ucfirst("password-helpertext");
    }

    this.setData({
      "error.fields.auth": error,
    });

    if (Object.keys(error).length === 0) {
      Services.get("auth,order,address").then(async ([authService]) => {
        // var order = null;

        try {
          await authService.login({ username, password });
          this.getHelpers("Auth").set(
            authService.getData("_response").getData()
          );
          this.getPage().login();

          await this.createAddress();

          this.getComponents()
            .findById("login-register-dialog")
            .first()
            .setData({
              "default.open": false,
            });
        } catch (service) {
          this.checkOrderError(service);
        }
      });
    }
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("login-register-dialog")
            .first()
            .setData({
              "default.open": false,
            });
          break;
        }
        default:
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          break;
      }
    }

    return this;
  }

  async createAddress() {
    const [orderService, addressService] = await Services.get("order,address");
    const devHelper = this.getHelpers("dev");

    try {
      await orderService.fetchOrder();
      let order = orderService.getData("order");

      if (order) {
        var address = devHelper.getObjectValue(order, "shipping_address", {});

        if (address && !address._id) {
          await addressService.createAddress(address);

          address = addressService.getData("new_address");

          await orderService.updateAddress({
            shippingAddressId: address._id,
            billingAddressId: address._id,
          });

          order = orderService.getData("order");

          this.getComponents()
            .findByGroup("order")
            .forEach((comp) => {
              comp.setData({ "default.order": order });
            });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}
