import Home from "./Page/Home";
import Business from "./Page/Business";
import RegisterBusiness from "./Page/RegisterBusiness";
import TermsAndConditions from "./Page/TermsAndConditions";
import Product from "./Page/Product";
import Redirect from "./Page/Redirect";
import Faq from "./Page/Faq";
import ThankYouPage from "./Page/ThankYouPage";
import SampleMenu from "./Page/SampleMenu";

// User Section
import Profile from "./Page/User/Profile";
import Addresses from "./Page/User/Addresses";
import Orders from "./Page/User/Orders";
import Order from "./Page/User/Order";
import Coupons from "./Page/User/Coupons";

// Steps Section
import Plans from "./Page/Plans";
import Address from "./Page/Address";
import DatetimePicker from "./Page/DatetimePicker";
import Menu from "./Page/Menu";
import Checkout from "./Page/Checkout";

// Weekly Steps Section
import WeeklyPlans from "./Page/WeeklySteps/WeeklyPlans";
import WeeklyMenu from "./Page/WeeklySteps/WeeklyMenu";

// Epay
import EpayResult from "./Page/Epay/Result";

const Module = {
  Home,
  Business,
  RegisterBusiness,
  TermsAndConditions,
  Product,
  Redirect,
  Faq,
  ThankYouPage,
  SampleMenu,
  // User Section
  Profile,
  Addresses,
  Orders,
  Order,
  Coupons,
  // Steps Section
  Plans,
  Address,
  DatetimePicker,
  Menu,
  Checkout,

  // Epay
  EpayResult,

  // Weekly Steps Section
  WeeklyPlans,
  WeeklyMenu,
};

export default Module;
