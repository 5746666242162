import React from "react";
import {
  Box,
  ListItem,
  Typography,
  Skeleton,
  IconButton,
  CardMedia,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function ProductWeeklyListView(props) {
  const { component, meal, order, date, delivery_date, datesTotal, max_meals } =
    props;
  const devHelper = component.getHelpers("dev");
  const {
    slug,
    name,
    meal_types = [],
    trans = [],
    price_coozein,
    meal_nutrients = [],
  } = devHelper.getObjectValue(meal, "item._values", {});

  const amount = component.getProductAmount(
    date,
    devHelper.getObjectValue(meal, "item._id"),
    order
  );

  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const lang = component.getHelpers("language").getLang("en");
  const isSpecial =
    component.isAuthenticated() &&
    component.getAuth().getUserAttribute("role") === "special";

  const dimension = 100;

  const icons = [
    "fa-regular fa-meat",
    "fa-regular fa-wheat",
    "fa-regular fa-droplet",
  ];

  return (
    <>
      {meal && (
        <ListItem
          disablePadding
          sx={{
            mt: 1,
            position: "relative",
            borderBottom: "1px solid #f7f7f7",
            py: 1,
          }}
        >
          <Box
            sx={{
              minWidth: "unset",
              height: dimension,
              width: dimension,
              marginRight: 1,
              display: "flex",
              position: "relative",
            }}
          >
            <Link component={RouterLink} to={`/meal/${slug}`}>
              {!component.getData("default.imageReady", false) && (
                <Skeleton
                  variant="circular"
                  height={dimension}
                  width={dimension}
                />
              )}
              {amount !== 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "30%",
                    height: "100%",
                    backgroundColor: "rgb(255, 130, 90,0.9)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: 18,
                    }}
                  >
                    {amount}
                  </Typography>
                </Box>
              )}
              <CardMedia
                component="img"
                sx={{
                  height: dimension,
                  width: dimension,
                }}
                image={`${filesUrl}/products/webp/${slug}.webp?v=${img_version}`}
                alt={`${name} image`}
                onLoad={() => {
                  component.setData({ "default.imageReady": true });
                }}
                loading="lazy"
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Link component={RouterLink} to={`/meal/${slug}`} underline="none">
              <Typography
                sx={{
                  fontSize: { xs: 16, sm: 18 },
                  fontWeight: 600,
                  lineHeight: 1.2,
                }}
              >
                {trans.length !== 0 &&
                  trans.find((tran) => tran.lang === lang).translation}
              </Typography>
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 1,
              }}
            >
              {meal_nutrients
                .filter((nutr) => nutr.item._values.slug === "calories")
                .sort(
                  (a, b) => b.item._values.position - a.item._values.position
                )
                .map((nutr, i) => {
                  const { slug } = devHelper.getObjectValue(
                    nutr,
                    "item._values"
                  );

                  return (
                    <Typography
                      key={slug}
                      sx={{
                        mr: 1,
                        fontSize: 18,
                        lineHeight: 1,
                        fontWeight: 500,
                        color: "#000",
                        display: "inline-flex",
                        cursor: "pointer",
                        "& i": {
                          color: "#800080",
                          mr: 0.5,
                        },
                      }}
                      title={component.ucfirst(slug)}
                    >
                      <i className="fa-regular fa-fire" />
                      {`${
                        devHelper
                          .getObjectValue(nutr, "value")
                          .toString()
                          .split(".")[0] || ""
                      }cal `}
                    </Typography>
                  );
                })}
              {meal_nutrients
                .filter(
                  (nutr) =>
                    nutr.item._values.slug === "total-fat" ||
                    nutr.item._values.slug === "protein" ||
                    nutr.item._values.slug === "total-carbohydrate"
                )
                .sort(
                  (a, b) => b.item._values.position - a.item._values.position
                )
                .map((nutr, i) => {
                  const { slug } = devHelper.getObjectValue(
                    nutr,
                    "item._values"
                  );

                  return (
                    <Typography
                      key={slug}
                      sx={{
                        mr: 1,
                        fontSize: 16,
                        lineHeight: 1,
                        fontWeight: 500,
                        color: "#000",
                        display: "inline-flex",
                        cursor: "pointer",
                        "& i": {
                          color: "#FF6F4D",
                          mr: 0.5,
                        },
                      }}
                      title={component.ucfirst(slug)}
                    >
                      <i className={icons[i]} />
                      {`${
                        devHelper
                          .getObjectValue(nutr, "value")
                          .toString()
                          .split(".")[0] || ""
                      }g`}
                    </Typography>
                  );
                })}
            </Box>
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                width: "100%",
                pr: { xs: 0, sm: 2 },
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  visibility: price_coozein === 0 ? "hidden" : "visible",
                  fontSize: 18,
                  fontWeight: 500,
                  display: "inline-flex",
                  color: "#fff",
                  bgcolor: "#800080",
                  borderBottomLeftRadius: 30,
                  borderTopRightRadius: 30,
                  p: "2px 12px",
                }}
              >
                +
                {component
                  .getApp()
                  .priceFormater(
                    amount > 0 ? price_coozein * amount : price_coozein
                  )}
              </Typography>
              <Box
                sx={{
                  mt: 1,
                  "& .MuiIconButton-root": {
                    bgcolor: "transparent",
                    border: "2px solid #000",
                    color: "#000",
                    width: 35,
                    height: 30,
                    fontSize: 14,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                    "&:disabled": {
                      bgcolor: "gray",
                      border: "2px solid gray",
                      color: "#fff",
                    },
                  },
                }}
              >
                <IconButton
                  onClick={() => {
                    component.updateCart(meal, -1, date, delivery_date);
                  }}
                  sx={{
                    mr: 1,
                    visibility: amount > 0 ? "visible" : "hidden",
                  }}
                >
                  <i className="fa-solid fa-minus"></i>
                </IconButton>
                <IconButton
                  onClick={() => {
                    component.updateCart(meal, 1, date, delivery_date);
                  }}
                  disabled={
                    meal_types.length !== 0 &&
                    devHelper.getObjectValue(
                      meal_types[0],
                      "item._values.slug"
                    ) !== "extra" &&
                    !isSpecial &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) !== 0 &&
                    datesTotal === max_meals
                  }
                >
                  <i className="fa-solid fa-plus" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </ListItem>
      )}
    </>
  );
}
