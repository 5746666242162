import Page from "../../Page";
import templateDefault from "../../../templates/default/pages/user/coupons";

export default class Coupons extends Page {
  title = "coupons";
  template = templateDefault;

  fullAuth = true;

  onAuth(data) {
    const { auth } = data;
    if (!auth.isAuthenticated()) {
      this.redirectTo("/");
    }
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("coupons-title")} | ${this.trans("window-title")}`
    );
  }
}
