import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/extra_info_grid";
import Services from "../../../Services/Services";

export default class ExtraInfoGrid extends Component {
  id = "extra-info-grid";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: this.props.scope },
        })
        .then((response) => {
          const sliders = response.getData() || {};
          this.setData({
            "default.sliders": sliders,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
