import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Fade,
  CardMedia,
  Box,
  Button,
  Typography,
  Container,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";
import { Link as RouterLink } from "react-router-dom";

export default function SingleBanner(props) {
  const { component } = props;
  const { business = false, image } = component.props;
  const theme = useTheme();
  const img_version = component.getHelpers("value").getValue("images-version");
  const { slug } = component.getData("default.slider._values", {});
  const filesUrl = component.getHelpers("env").get("files_url");
  const companySlug = component.getPage().getParam("company", "");

  const src = `${filesUrl}/marketing/${image}`;

  const [loader, setLoader] = useState(true);
  const [businessImage, setBusinessImage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  return (
    <Box id="top-banner">
      <Fade in={loader}>
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#FF6F4D" }} size={100} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        </Box>
      </Fade>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          py: 1,
        }}
      >
        <Fade in={true}>
          <Box sx={{ position: "relative" }}>
            <Box sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                image={`${src}-1200w.webp?v=${img_version}`}
                srcSet={`${src}-480.webp 480w,${src}-600.webp 600w,${src}-750.webp 750w,${src}-900.webp 900w,${src}-1050.webp 1050w,${src}-1200.webp 1200w`}
                alt={`${image} image`}
                onLoad={() => setLoader(false)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: "50%", md: businessImage ? 20 : 0 },
                  left: "50%",
                  display: business ? "block" : "none",
                  transform: {
                    xs: "translate(-50%,-50%)",
                    md: "translate(-50%,0)",
                  },
                }}
              >
                {businessImage ? (
                  <CardMedia
                    component="img"
                    image={`${filesUrl}/b2b/${companySlug}.webp?v=${img_version}`}
                    alt={`${companySlug} image`}
                    sx={{ maxWidth: 240 }}
                    onError={() => setBusinessImage(false)}
                  />
                ) : (
                  <Typography
                    component="h1"
                    sx={{
                      ...theme.custom.titles.title,
                      ...{
                        lineHeight: "1.2",
                        color: "#fff",
                      },
                    }}
                  >
                    {component.trans(companySlug) ===
                    companySlug.replace("-", " ")
                      ? "Business"
                      : component.ucfirst(companySlug)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                position: { xs: "relative", md: "absolute" },
                top: { xs: "unset", md: "50%" },
                left: { xs: "unset", md: "50%" },
                transform: { xs: "unset", md: "translate(-100%,-50%)" },
                flexDirection: { xs: "unset", md: "column" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                component="h1"
                sx={{
                  ...theme.custom.titles.title,
                  ...{
                    mt: { xs: 2, md: 0 },
                    color: { xs: "#000", md: "#fff" },
                    maxWidth: { xs: "unset", md: "400px" },
                    lineHeight: 1.2,
                    mb: 2,
                  },
                }}
              >
                {component.trans(companySlug) ===
                companySlug.replace("-", " ") ? (
                  <Translate
                    entity="item"
                    eslug={slug}
                    scope="default"
                    slug="title"
                    auto
                  />
                ) : (
                  <Translate
                    entity="literal"
                    eslug="collaborators-slogan"
                    scope="default"
                    slug="title"
                    auto
                  />
                )}
              </Typography>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.subtitle,
                  ...{
                    mt: { xs: 2, md: 0 },
                    color: { xs: "#000", md: "#fff" },
                    maxWidth: { xs: "unset", md: "400px" },
                  },
                }}
              >
                <Translate
                  entity="item"
                  eslug={slug}
                  scope="default"
                  slug="subtitle"
                  auto
                />
              </Typography>
              <Box
                sx={{
                  mt: 5,
                  alignItems: "center",
                  display: business ? "none" : { xs: "flex", md: "block" },
                  flexDirection: { xs: "column", md: "unset" },
                }}
              >
                <Button
                  sx={{
                    ...theme.custom.buttons.button2,
                    ...{
                      mr: { xs: 0, md: "24px" },
                      my: 1,
                      width: { xs: "90%", sm: "300px", md: "150px" },
                      bgcolor: { xs: "#000", md: "#fff" },
                      color: { xs: "#fff", md: "#28282B" },
                      border: {
                        xs: "2px solid #000",
                        md: "2px solid #fff",
                      },
                      "&:hover": {
                        bgcolor: { xs: "#000", md: "#fff" },
                        color: { xs: "#fff", md: "#28282B" },
                      },
                    },
                  }}
                  component={RouterLink}
                  to="/meal-packs"
                >
                  {component.ucfirst("start-now")}
                </Button>
                <Button
                  sx={{
                    ...theme.custom.buttons.button2,
                    ...{
                      my: 1,
                      width: { xs: "90%", sm: "300px", md: "150px" },
                      bgcolor: { xs: "transparent", md: "transparent" },
                      color: { xs: "#000", md: "#fff" },
                      border: {
                        xs: "2px solid #000",
                        md: "2px solid #fff",
                      },
                      "&:hover": {
                        bgcolor: { xs: "transparent", md: "transparent" },
                        color: { xs: "#000", md: "#fff" },
                      },
                    },
                  }}
                  component={RouterLink}
                  to="/menu"
                >
                  {component.ucfirst("menu")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
}
