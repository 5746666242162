import React from "react";
import Box from "@mui/material/Box";
import Footer from "../widgets/footer/footer";
import NavBar from "../../../Components/Widgets/Navbar/Navbar";
import FirstSection from "../widgets/business/first_section";
import StepsGrid from "../../../Components/Widgets/Sliders/StepsGrid";
import ExtraInfoGrid from "../../../Components/Widgets/Sliders/ExtraInfoGrid";
import BusinessForm from "../../../Components/Widgets/BusinessForm";
import BottomCart from "../../../Components/Widgets/BottomCart";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar />
      <BusinessForm {...props} />
      <StepsGrid
        {...{
          ...props,
          ...{
            scope: "business-steps",
            business: true,
          },
        }}
      />
      <FirstSection {...props} />
      <ExtraInfoGrid
        {...{
          ...props,
          ...{
            scope: "home-extra-info",
          },
        }}
      />
      <BottomCart {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
