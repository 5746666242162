import Service from "../Service";
import Services from "../Services";

class SocialLogin extends Service {
  loadFB({ window, document, page }) {
    const facebookAppId = page.getHelpers("env").get("facebook_app_id");
    const facebookApiVersion = page
      .getHelpers("env")
      .get("facebook_api_version");

    if (facebookAppId && facebookApiVersion) {
      window.fbAsyncInit = function () {
        if (window.FB) {
          const FB = window.FB;

          FB.init({
            appId: facebookAppId,
            cookie: true,
            xfbml: true,
            version: facebookApiVersion,
          });

          FB.AppEvents.logPageView();
        }
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  }

  loginFB({ window, document, page, cb }) {
    if (window.FB) {
      const FB = window.FB;
      const company = page.getParam("company");
      const special = page.getQuery("special");

      FB.login(
        (response) => {
          if (response.authResponse) {
            FB.api("/me?fields=name,id,email", (response) => {
              const { name, email } = response;

              if (name && email) {
                Services.get("auth").then(([authService]) => {
                  authService
                    .loginFB({ username: email, name, company, special })
                    .then(async (authService) => {
                      page.deleteData("error.message");
                      page
                        .getHelpers("Auth")
                        .set(authService.getData("_response").getData());

                      if (cb) await cb();

                      if (!company) {
                        page
                          .getComponents()
                          .findById("login-register-dialog")
                          .forEach((dialog) => {
                            dialog.closeDialog();
                          });
                      } else {
                        page.redirect("/meal-packs");
                      }

                      page.login();
                    })
                    .catch((authService) => {
                      console.log(authService);
                      page.setData({
                        "error.message": authService
                          .getData("_response")
                          .getMessage(),
                      });
                    });
                });
              } else {
                alert(page.ucfirst("parmissions error"));
              }
            });
          }
        },
        { scope: "public_profile,email" }
      );
    }
  }

  loadGoogle({ window, document, page, cb }) {
    const googleClientId = page.getHelpers("env").get("google_client_id");
    const element = document.getElementById("google-sign-in-btn");
    const googleSdk = document.getElementById("google-sdk");

    if (googleClientId && element) {
      if (googleSdk) {
        this.loadGoogleLogin({ window, document, page, cb });
      }

      const onLoad = () => this.loadGoogleLogin({ window, document, page, cb });

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://accounts.google.com/gsi/client";
        js.onload = () => onLoad();
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "google-sdk");
    }
  }

  loadGoogleLogin({ window, document, page, cb }) {
    const googleClientId = page.getHelpers("env").get("google_client_id");
    const company = page.getParam("company");
    const special = page.getQuery("special");

    window.google.accounts.id.initialize({
      client_id: googleClientId,
      callback: (response) => {
        const payload = parseJwt(response.credential);
        const { email, name } = payload;
        // const { email, name } = JSON.parse(
        //   atob(response.credential.split(".")[1])
        // );

        Services.get("auth", "test").then(([authService]) => {
          authService
            .loginGoogle({ username: email, name, company, special })
            .then(async (authService) => {
              page.deleteData("error.message");
              page
                .getHelpers("Auth")
                .set(authService.getData("_response").getData());
              // page.redirectToRedirect("/");

              if (cb) await cb();

              if (!company) {
                page
                  .getComponents()
                  .findById("login-register-dialog")
                  .forEach((dialog) => {
                    dialog.closeDialog();
                  });
              } else {
                page.redirect("/meal-packs");
              }

              page.login();
            })
            .catch((authService) => {
              console.log(authService);
              this.setData({
                "error.message": authService.getData("_response").getMessage(),
              });
            });
        });
      },
    });

    const parseJwt = (token) => {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
      } catch (error) {
        throw new Error("Invalid token");
      }
    };

    window.google.accounts.id.renderButton(
      document.getElementById("google-sign-in-btn"),
      { theme: "outline", size: "large" } // customization attributes
    );

    window.google.accounts.id.prompt();
  }
}

export default new SocialLogin();
