import React from "react";
import {
  Toolbar,
  useScrollTrigger,
  AppBar,
  useMediaQuery,
  Box,
} from "@mui/material";
import MainAppbar from "../navbar/main_appbar";
import { useTheme } from "@mui/material/styles";

export default function Navbar(props) {
  // const { component } = props;
  const theme = useTheme();

  const appbarHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.custom.appbar_height.desktop
    : theme.custom.appbar_height.mobile;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1,
  });

  return (
    <>
      <AppBar
        position="fixed"
        elevation={trigger ? 3 : 0}
        sx={{
          bgcolor: "#fff",
        }}
      >
        <MainAppbar {...props} />
      </AppBar>

      <Box sx={{ height: appbarHeight }} />
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: "0.1px !important" }} />
    </>
  );
}
