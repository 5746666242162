import React from "react";
import {
  Box,
  Typography,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  LinearProgress,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import ProductCheckoutView from "../product_checkout_view";
import Translate from "../../../../../modules/Core/Components/Translate";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SummarySection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const {
    order = null,
    meals = [],
    extra = [],
    menus = {},
  } = component.getData("default", {});
  const [selectedDay, setDay] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    days_amount = null,
    weeks_amount = null,
    day_meals = null,
  } = devHelper.getObjectValue(order, "weekly", {});

  const MyButton = {
    fontSize: 16,
    py: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    px: 1.5,
    textTransform: "none",
    background: "#FF6F4D",
    borderRadius: 50,
    border: "1px solid #FF6F4D",
    color: "#fff",
    "&:hover": {
      background: "#FF6F4D",
    },
  };

  const table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 18,
    paddingTop: 0,
    fontWeight: 400,
    maxWidth: 170,
    py: 1,
    px: 2,
  };

  const right_table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 22,
    fontWeight: 600,
    py: 1,
    px: 2,
  };

  const handleDayChange = (index) => {
    setDay(index === selectedDay ? false : index);
  };

  return (
    <>
      {order && (
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            px: { xs: 2, md: 3 },
            py: 2,
            borderRadius: "12px",
          }}
        >
          <Box style={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                lineHeight: 1,
                mb: 2,
              }}
            >
              {component.ucfirst("checkout-title-4")}
            </Typography>

            {devHelper.getObjectValue(order, "plan.weekly") ? (
              <Box>
                {Object.keys(menus).map((week, index) => (
                  <Box key={week}>
                    {menus[week].map((del_date, i) => {
                      const { meals, date, dates_total } = del_date;

                      return (
                        <Accordion
                          key={date}
                          expanded={selectedDay === date}
                          onChange={() => handleDayChange(date)}
                          disableGutters
                          elevation={0}
                          square
                          sx={{
                            borderBottom: "1px solid #000",
                            "&:before": {
                              display: "none",
                            },
                          }}
                        >
                          <AccordionSummary
                            sx={{ height: 50 }}
                            expandIcon={
                              <i className="fa-solid fa-chevron-down"></i>
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                pr: 1,
                              }}
                            >
                              <Box
                                component="span"
                                sx={{
                                  fontSize: { xs: 18, sm: 25 },
                                  fontWeight: 500,
                                  conor: "#000",
                                }}
                              >
                                {component
                                  .getApp()
                                  .dateFormater(date)
                                  .slice(0, 3)
                                  .map((value) => component.ucfirst(value))
                                  .join(" ")}
                              </Box>
                              <Box
                                sx={{
                                  width: 126,
                                  position: "relative",
                                  ml: 2,
                                }}
                              >
                                <LinearProgress
                                  value={100}
                                  variant="determinate"
                                  {...props}
                                  sx={{
                                    height: 24,
                                    borderRadius: 50,
                                    bgcolor: "grey",
                                    "& .MuiLinearProgress-bar": {
                                      bgcolor: "#FF6F4D",
                                      transition: "transform .2s linear",
                                      WebkitTransition: "transform .2s linear",
                                    },
                                  }}
                                />
                                <Typography
                                  align="center"
                                  sx={{
                                    fontWeight: 600,
                                    position: "absolute",
                                    color: "#fff",
                                    fontSize: 14,
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "100%",
                                  }}
                                >
                                  {`${dates_total} ${component.trans("meals")}`}
                                </Typography>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails sx={{ pb: 2 }}>
                            <List
                              dense
                              sx={{
                                width: "100%",
                                justifyContent: "top",
                                p: 0,
                              }}
                            >
                              {meals.map((meal, index) => (
                                <ProductCheckoutView
                                  key={index}
                                  {...{
                                    ...props,
                                    ...{
                                      meal,
                                      order,
                                    },
                                  }}
                                />
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                ))}
              </Box>
            ) : (
              <List dense sx={{ width: "100%", justifyContent: "top" }}>
                {meals.map((meal, index) => (
                  <ProductCheckoutView
                    key={index}
                    {...{
                      ...props,
                      ...{
                        meal,
                        order,
                      },
                    }}
                  />
                ))}
                {extra.length !== 0 && (
                  <Typography
                    component="h1"
                    align="center"
                    sx={{
                      fontSize: { xs: 20, sm: 22, md: 25, lg: 30 },
                      fontWeight: 500,
                      color: "#000",
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    {component.ucfirst("extras")}
                  </Typography>
                )}
                {extra.map((meal, index) => (
                  <ProductCheckoutView
                    key={index}
                    {...{
                      ...props,
                      ...{
                        meal,
                        order,
                      },
                    }}
                  />
                ))}
              </List>
            )}
            <Typography
              variant="h3"
              align="right"
              sx={{
                fontSize: 18,
                mt: 1,
                fontWeight: 600,
                color: "#800080",
              }}
            >
              {component.ucfirst("shelf-life")}
            </Typography>
            <Box sx={{ maxWidth: 400, mx: "auto", mt: 3 }}>
              {days_amount && (
                <Box
                  sx={{
                    borderBottom: "2px solid #800080",
                    bgcolor: "#fff",
                    py: 1.5,
                    width: 260,
                    mx: "auto",
                    mb: 2,
                  }}
                >
                  <Typography
                    align="center"
                    component="h1"
                    sx={{
                      fontSize: 18,
                      color: "#000",
                      fontWeight: 500,
                      lineHeight: 1,
                    }}
                  >
                    {days_amount * day_meals}
                    {` ${component.trans("meals")} / ${component.trans(
                      "week"
                    )}`}
                  </Typography>
                </Box>
              )}
              <Table>
                <TableBody>
                  {days_amount && (
                    <TableRow>
                      <TableCell sx={{ ...table_cell }}>
                        {component.ucfirst("price-per-meal")}
                      </TableCell>
                      <TableCell sx={{ ...right_table_cell }} align="right">
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items") /
                              (days_amount * weeks_amount * day_meals)
                          )}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("subtotal")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.items")
                        )}
                    </TableCell>
                  </TableRow>
                  {devHelper.getObjectValue(order, "totals.discount", 0) !==
                    0 && (
                    <TableRow>
                      <TableCell sx={{ ...table_cell }}>
                        {component.ucfirst("discount")}
                      </TableCell>
                      <TableCell sx={{ ...right_table_cell }} align="right">
                        -
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.discount")
                          )}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("shipping")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      {devHelper.getObjectValue(order, "totals.shipping", 0) ===
                      0
                        ? component.ucfirst("free")
                        : component
                            .getApp()
                            .priceFormater(
                              devHelper.getObjectValue(order, "totals.shipping")
                            )}
                    </TableCell>
                  </TableRow>
                  {devHelper.getObjectValue(order, "totals.cutlery", 0) !==
                    0 && (
                    <TableRow>
                      <TableCell sx={{ ...table_cell }}>
                        {component.ucfirst("cutlery")}
                      </TableCell>
                      <TableCell sx={{ ...right_table_cell }} align="right">
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.cutlery")
                          )}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ ...table_cell, fontWeight: 600 }}>
                      {component.ucfirst("total")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.grand")
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <form
                onSubmit={(e) => {
                  component.deleteData("error");
                  setLoading(true);
                  component.complete(e);
                }}
                style={{ textAlign: "center" }}
              >
                <Typography sx={{ textAlign: "center", mt: 3, fontSize: 14 }}>
                  <Translate
                    entity="literal"
                    eslug="agree-privacy-policy-terms-checkbox"
                    scope="default"
                    slug="default"
                    auto
                  />
                </Typography>
                <LoadingButton
                  type="submit"
                  fullWidth
                  sx={{
                    ...MyButton,
                    "&:disabled": {
                      visibility: loading ? "visible" : "hidden",
                      bgcolor: "#FF6F4D",
                      color: loading ? "#FF6F4D" : "#fff",
                    },
                  }}
                  disableRipple
                  loadingIndicator={
                    <CircularProgress size={30} sx={{ color: "#fff" }} />
                  }
                  disabled={loading && !component.dataExists("error")}
                  loading={loading && !component.dataExists("error")}
                >
                  <Box sx={{ fontWeight: 600 }}>
                    <Avatar
                      sx={{
                        visibility:
                          loading && !component.dataExists("error")
                            ? "hidden"
                            : "visible",
                        width: 24,
                        height: 24,
                        bgcolor: "#000",
                        fontSize: 16,
                        mr: 1,
                        display: "inline-flex",
                      }}
                    >
                      {devHelper.getObjectValue(order, "amounts.items", 0)}
                    </Avatar>
                    {component.ucfirst("place-order-btn")}
                  </Box>
                  <Box
                    sx={{
                      ml: 2.5,
                    }}
                  >
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.grand")
                      )}
                  </Box>
                </LoadingButton>
              </form>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
}
