import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Grid,
  Typography,
  Container,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function StepsGrid(props) {
  const theme = useTheme();
  const { component, home } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  const img_version = component.getHelpers("value").getValue("images-version");

  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top:
          document.getElementById("business_form").offsetTop -
          theme.custom.appbar_height[view].replace("px", "") * 1,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Container maxWidth="xl" disableGutters sx={{ px: { xs: 1, md: 2 } }}>
        <Typography
          component="h1"
          align="center"
          variant="h1"
          sx={{
            ...theme.custom.titles.title,
            ...{ mt: 4 },
          }}
        >
          {component.ucfirst("business-title-1")}
        </Typography>
        <Grid
          container
          direction="row"
          sx={{ justifyContent: "center", alignItems: "center", mt: 1 }}
          rowSpacing={4}
          columnSpacing={2}
        >
          <Grid item xs={8} sm={4} md={3}>
            <CardMedia
              component="img"
              image={`${filesUrl}/marketing/business-image-1.webp?v=${img_version}`}
              sx={{
                borderRadius: "50%",
                maxWidth: "100%",
              }}
              alt="business image 1"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              align="center"
              sx={{
                ...theme.custom.titles.subtitle,
                ...{},
              }}
            >
              {component.ucfirst("business-subtitle-1")}
            </Typography>
            <Typography
              component="h3"
              align="center"
              sx={{
                ...theme.custom.titles.description,
                ...{ mt: { xs: 2, md: 2 } },
              }}
            >
              {component.ucfirst("business-text-1")}
            </Typography>
            <Typography
              component="h3"
              align="center"
              sx={{
                ...theme.custom.titles.description,
                mt: 2,
                fontWeight: 500,
              }}
            >
              {component.ucfirst("business-text-3")}
            </Typography>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center" }}
              spacing={2}
            >
              <Grid item xs={5} sm={3}>
                <CardMedia
                  component="img"
                  image={`${filesUrl}/marketing/edenred.webp?v=${img_version}`}
                  alt="edenred image"
                />
              </Grid>
              <Grid item xs={5} sm={3}>
                <CardMedia
                  component="img"
                  image={`${filesUrl}/marketing/up-hellas.webp?v=${img_version}`}
                  alt="up hellas image"
                />
              </Grid>
            </Grid>
            <Box sx={{ textAlign: "center", mt: 3 }}>
              {home ? (
                <Button
                  component={RouterLink}
                  to="/business"
                  sx={{
                    ...theme.custom.buttons.button3,
                    color: "#000",
                    border: "2px solid #FF6F4D",
                    "&:hover": {
                      bgcolor: "#fff",
                    },
                  }}
                >
                  {component.ucfirst("contact")}
                </Button>
              ) : (
                <Button
                  sx={{
                    ...theme.custom.buttons.button3,
                    color: "#000",
                    border: "2px solid #FF6F4D",
                    "&:hover": {
                      bgcolor: "#fff",
                    },
                  }}
                  onClick={() => handleClick()}
                >
                  {component.ucfirst("contact")}
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={8} sm={4} md={3}>
            <CardMedia
              component="img"
              image={`${filesUrl}/marketing/business-image-2.webp?v=${img_version}`}
              sx={{
                borderRadius: "50%",
                maxWidth: "100%",
              }}
              alt="business image 1"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
