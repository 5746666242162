import App from "../../modules/Core/Components/App";
import template from "../templates/app";
import Services from "../Services/Services";

export default class MainApp extends App {
  template = template;

  onLoad(data) {
    super.onLoad(data);
    this.installUserway();
  }

  installUserway() {
    (function (d) {
      var s = d.createElement("script");
      s.setAttribute("data-account", "Ba1rT85pF4");
      s.setAttribute("src", "https://cdn.userway.org/widget.js");
      (d.body || d.head).appendChild(s);
    })(document);

    return this;
  }

  getOnResizeResolusion(width) {
    if (width < 500) {
      return "mobile";
    }

    if (width >= 500) {
      return "default";
    }
  }

  newsletterSubscribe(email) {
    Services.get("hook").then(async ([hookService]) => {
      const response = await hookService.post("subscribe", {
        email,
      });
      if (response.isValid()) {
        const data = response.getData();
        const valid = data === "ok";
        if (valid) {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.ucfirst("subscribed"),
                type: "success",
                duration: 2000,
              },
            });
        }
      }
    });
  }

  logout() {
    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.delete();
      } catch (e) {
        console.log(e);
      }
      this.getPage().logout();
    });
  }

  priceFormater(price) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price);
  }

  priceFormaterDecimal(price) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price);
  }

  toggleDrawer(id) {
    this.getComponents()
      .findById(id)
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  updateWindowTitle(title) {
    this.getPage().setWindowTitle(title);

    window.dataLayer.push({
      event: "pageviewCustomEvent",
      pagePath: window.location.pathname,
      pageTitle: title,
    });

    return this;
  }

  evaluateOrder(path) {
    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.fetchOrder();
        const order = orderService.getData("order");

        const { shipping_address, spot, date, time, plan } = order;

        if (plan && plan.weekly) {
          if (!shipping_address || !spot) {
            this.getPage().redirect("/weekly/select-address");
          } else if (!time) {
            this.getPage().redirect("/weekly/meal-packs");
          } else if (path === "/menu") {
            this.getPage().redirect("/weekly/menu");
          } else {
            this.getPage().redirect(path);
          }
        } else {
          if (!shipping_address || !spot) {
            this.getPage().redirect("/select-address");
          } else if (!date || !time) {
            this.getPage().redirect("/select-date-time");
          } else {
            this.getPage().redirect(path);
          }
        }
      } catch (e) {
        if (path) {
          this.getPage().redirect(path);
        }
      }
    });
  }

  sendToGA4(product, event, path) {
    const devHelper = this.getHelpers("dev");
    if (product) {
      const {
        slug,
        name,
        price_coozein,
        meal_categories = [],
      } = devHelper.getObjectValue(product, path, {});
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: event,
        ecommerce: {
          items: [
            {
              item_id: slug,
              item_name: name,
              index: 0,
              item_brand: "Coozein",
              item_category:
                meal_categories.length !== 0
                  ? meal_categories[0].item._values.name
                  : null,
              price: price_coozein,
              quantity: 1,
            },
          ],
        },
      });
    }
  }

  sendToGA4Order(order) {
    const {
      amounts,
      coupon,
      guest,
      items,
      paymentType,
      totals,
      _id,
      delivery_type,
      spot,
    } = order;
    const final_items = items.map((pr, index) => {
      const { slug, name, meal_categories = [] } = pr.item;
      return {
        item_id: slug,
        item_name: name,
        affiliation: spot.name,
        index: 0,
        item_brand: "Coozein",
        item_category:
          meal_categories.length !== 0
            ? meal_categories[0].item._values.name
            : null,
        price: pr.price,
        quantity: pr.amount,
      };
    });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: _id,
        user: guest ? "guest" : "subscribed",
        total_items: amounts.items,
        value: totals.grand,
        items_total: totals.items,
        discount: totals.discount ? totals.discount : 0,
        shipping: totals.services,
        payment_type: paymentType.name,
        delivery_type: delivery_type,
        spot: spot.name,
        currency: "EUR",
        coupon: coupon ? coupon.name : null,
        items: final_items,
      },
    });
  }

  dateFormater(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const day = date.split(" ").splice(0, 1).join("");

    const time = date
      .split(" ")
      .splice(-1)
      .join("")
      .split(":")
      .slice(0, 2)
      .join(":");

    return [
      days[new Date(day).getUTCDay()],
      new Date(day).getDate().toString(),
      months[new Date(day).getMonth()],
      time,
    ];
  }

  dayFormater(date) {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    return days[new Date(date).getUTCDay()];
  }

  dateFormaterNumeric(date) {
    const day = date.split(" ").splice(0, 1).join("");

    const time = date
      .split(" ")
      .splice(-1)
      .join("")
      .split(":")
      .slice(0, 2)
      .join(":");

    return `${new Date(day).getUTCDay()}/${new Date(day).getMonth()}/${
      new Date(day).getFullYear() % 100
    } ${time}`;
  }

  statusFormater(status) {
    switch (status) {
      case "completed":
        return (
          <i
            className="fa-solid fa-circle-check"
            style={{ color: "rgb(56, 142, 60)" }}
          />
        );
      case "canceled":
        return (
          <i
            className="fa-solid fa-circle-xmark"
            style={{ color: "rgb(211, 47, 47)" }}
          />
        );
      case "placed":
        return (
          <i
            className="fa-solid fa-circle-ellipsis"
            style={{ color: "#bfbfbf" }}
          />
        );
      default:
        return status;
    }
  }
}
