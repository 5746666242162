import React from "react";
import { styled } from "@mui/material/styles";
import { Select, FormControl, InputBase, MenuItem } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: "#28282B",
    position: "relative",
    border: "none",
    fontSize: 16,
    fontWeight: 600,
    padding: "10px 26px 10px 0px",
    "&:focus": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  "& .MuiSvgIcon-root": {
    color: "#28282B",
  },
}));

export default function Footer(props) {
  const { component } = props;
  const lang = component.getData("default.lang", "en");
  const languages = component.getData("default.languages", []);
  return (
    <FormControl variant="standard" sx={{ ml: 2, pl: 0, minWidth: 45 }}>
      {languages.length !== 0 && (
        <Select
          value={lang}
          onChange={(e) => component.selectLang(e.target.value)}
          input={<BootstrapInput />}
          MenuProps={{ MenuListProps: { disablePadding: true } }}
        >
          {languages.map((language) => {
            const { slug } = language;
            return (
              <MenuItem
                key={`language-select-${slug}`}
                value={slug}
                sx={{ color: "#28282B", fontSize: 16 }}
              >
                {component.ucfirst(slug)}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
}
