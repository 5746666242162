import React from "react";
import { Box, ListItem, Typography, Skeleton, CardMedia } from "@mui/material";

export default function ProductCartView(props) {
  const { component } = props;

  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const dimension = 120;

  return (
    <ListItem
      disablePadding
      sx={{
        position: "relative",
        borderBottom: "1px solid #f7f7f7",
        py: 1,
      }}
    >
      <Box
        sx={{
          minWidth: "unset",
          height: dimension,
          width: dimension,
          marginRight: 1,
          display: "flex",
          position: "relative",
        }}
      >
        {!component.getData("default.imageReady", false) && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{ borderRadius: "8px" }}
          />
        )}
        {/* <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "40%",
                height: "100%",
                backgroundColor: "rgb(255, 130, 90,0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 18,
                }}
              >
                {amount}
              </Typography>
            </Box> */}
        <CardMedia
          component="img"
          sx={{
            height: dimension,
            width: dimension,
          }}
          image={`${filesUrl}/assets/empty-plate.webp?v=${img_version}`}
          alt={`empty plate image`}
          onLoad={() => {
            component.setData({ "default.imageReady": true });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 600,
            lineHeight: 1.2,
          }}
        >
          {component.ucfirst("empty-plate-name")}
        </Typography>

        {/* <Box>
          <Typography
            sx={{
              display: "inline-block",
              fontWeight: 600,
              fontSize: 18,
              lineHeight: 1,
              color: "#FF6F4D",
              mr: 1,
            }}
          >
            0
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              color: "#000",
              fontWeight: 500,
              fontSize: 18,
            }}
          >
            {component.ucfirst("cal")}
          </Typography>
        </Box> */}
        <Box
          sx={{
            mt: 1,
            height: 30,
            width: "100%",
          }}
        />
      </Box>
    </ListItem>
  );
}
