import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Menu,
  Button,
  MenuItem,
  Link,
  CardMedia,
  Container,
  Stepper,
  Step,
  StepButton,
  IconButton,
  Badge,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";

const CouponBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 10,
    top: 5,
    background: "#800080",
    color: "#fff",
  },
}));

const CouponBadge2 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -15,
    top: 15,
    background: "#800080",
    color: "#fff",
  },
}));

export default function MainAppbar(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const img_version = component.getHelpers("value").getValue("images-version");
  const isAuth = component.isAuthenticated();
  const username = component
    .getAuth()
    .getUserAttribute("name")
    .split(" ")
    .map((word) => word.charAt(0))
    .splice(0, 2)
    .join("");

  const {
    order = null,
    showSteps = null,
    coupons = [],
    links = ["/meal-packs", "/select-date-time", "/menu", "/checkout"],
  } = component.getData("default", {});

  const step = component.getPage().getData("default.step", 0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userRole = component.getAuth().getUserAttribute("role");
  const canCreateAddress = !userRole || userRole !== "business";

  //css

  const myMenuItem = {
    color: "#000",
    bgcolor: "transparent",
    fontWeight: 500,
    fontSize: { xs: 18, md: 18 },
    "&:hover": {
      bgcolor: "transparent",
      color: "#FF6F4D",
    },
  };

  const MyButton = {
    fontSize: 16,
    py: 1,
    display: "inline-flex",
    justifyContent: "space-between",
    flexDirection: "row",
    px: 1.5,
    visibility: window.location.pathname.includes("/checkout")
      ? "hidden"
      : "visible",
    width: "auto",
    textTransform: "none",
    background: "#FF6F4D",
    borderRadius: 50,
    border: "1px solid #FF6F4D",
    color: "#fff",
    "&:hover": {
      background: "#FF6F4D",
    },
  };

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: { xs: 1, lg: 4 },
        height: {
          xs: theme.custom.appbar_height.mobile,
          md: theme.custom.appbar_height.desktop,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link component={RouterLink} to="/">
          <CardMedia
            component="img"
            image={`/logos/logo.webp?v=${img_version}`}
            sx={{
              width: { xs: 160, lg: 180 },
              height: "auto",
            }}
            alt="Coozein Home"
            title="Coozein Home"
          />
        </Link>
        {showSteps !== null && !showSteps && (
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Button
              sx={{
                ...theme.custom.buttons.button1,
                ...{ ml: 2 },
              }}
              disableRipple
              onClick={() => component.getApp().evaluateOrder("/menu")}
            >
              {component.ucfirst("menu")}
            </Button>
            <Button
              sx={{
                ...theme.custom.buttons.button1,
                ...{ ml: 2 },
              }}
              disableRipple
              component={RouterLink}
              to="/meal-packs"
            >
              {component.ucfirst("meal-packs")}
            </Button>
          </Box>
        )}
      </Box>
      {/* steps */}
      {showSteps !== null && showSteps && (
        <Stepper nonLinear activeStep={step}>
          {[...new Array(4)].map((label, index) => (
            <Step
              key={index}
              completed={step >= index + 1}
              sx={{ px: { xs: 0, lg: 1 } }}
            >
              <StepButton
                color="inherit"
                disabled={step < index + 1}
                disableRipple
                component={RouterLink}
                to={links[index]}
                sx={{
                  "& .MuiStepLabel-label": {
                    display: { xs: "none", lg: "inline-flex" },
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#000",
                  },
                  "& .Mui-active": {
                    "& .MuiSvgIcon-root": {
                      color: "#FF6F4D",
                    },
                  },
                  "& .Mui-completed": {
                    "& .MuiSvgIcon-root": {
                      color: "#000",
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    height: { xs: 32, lg: 32 },
                    width: { xs: 32, lg: 32 },
                    color: "#000",
                    fontWeight: 600,
                  },
                }}
              >
                {component.ucfirst(
                  devHelper.getObjectValue(order, "plan.weekly")
                    ? `weekly-step-${index + 1}`
                    : `step-${index + 1}`
                )}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}

      <Box
        sx={{
          display: {
            xs: showSteps !== null && showSteps ? "none" : "flex",
            md: "flex",
          },
          alignItems: "center",
        }}
      >
        {showSteps !== null && !showSteps && (
          <Box>
            {!isAuth ? (
              <Button
                sx={{
                  ...theme.custom.buttons.button1,
                  ...{ mr: { xs: 0, md: 0, lg: 1 } },
                }}
                disableRipple
                onClick={() =>
                  component
                    .getComponents()
                    .findById("login-register-dialog")
                    .first()
                    .setData({
                      "default.open": true,
                    })
                }
              >
                {component.ucfirst("sign-in-btn")}
              </Button>
            ) : (
              <CouponBadge badgeContent={coupons.length}>
                <IconButton
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleClick(e, "account")}
                  disableRipple
                  sx={{
                    mr: 1,
                    width: 40,
                    height: 40,
                    color: "#fff",
                    fontSize: 18,
                    bgcolor: "#000",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  {username}
                </IconButton>
              </CouponBadge>
            )}
          </Box>
        )}
        <IconButton
          onClick={() => {
            component.getApp().toggleDrawer("menu-drawer");
          }}
          aria-label="Mobile Menu"
          sx={{ color: "#000", display: { xs: "block", md: "none" } }}
        >
          <i className="fa-solid fa-bars" />
        </IconButton>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {!order || !order.plan ? (
            <Button
              sx={theme.custom.buttons.button2}
              disableRipple
              component={RouterLink}
              to="/meal-packs"
            >
              {component.ucfirst("start-now")}
            </Button>
          ) : (
            <Button
              sx={MyButton}
              disableRipple
              onClick={() => {
                component.getApp().toggleDrawer("cart-drawer");
              }}
            >
              <Box sx={{ fontWeight: 600 }}>
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: "#000",
                    fontSize:
                      String(
                        devHelper.getObjectValue(order, "amounts.items", 0)
                      ).length === 3
                        ? 10
                        : 14,
                    mr: 1,
                    display: "inline-flex",
                  }}
                >
                  {devHelper.getObjectValue(order, "amounts.items", 0)}
                </Avatar>
                {component.ucfirst("view-order")}
              </Box>
              <Box
                sx={{
                  ml: 2.5,
                }}
              >
                {component
                  .getApp()
                  .priceFormater(
                    devHelper.getObjectValue(order, "totals.grand")
                  )}
              </Box>
            </Button>
          )}
        </Box>

        {showSteps !== null && !showSteps && (
          <Box sx={{ display: { xs: "none", md: "inline-flex" } }}>
            <LanguageSelect {...props} />
          </Box>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: "10px",
            // "&:before": {
            //   content: '""',
            //   display: "block",
            //   position: "absolute",
            //   top: 0,
            //   left: "50%",
            //   width: 10,
            //   height: 10,
            //   bgcolor: "background.paper",
            //   transform: "translate(-50%,-50%) rotate(45deg)",
            //   zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <MenuItem
          sx={myMenuItem}
          component={RouterLink}
          to="/profile"
          onClick={handleClose}
        >
          {component.ucfirst("profile-btn")}
        </MenuItem>
        {canCreateAddress && (
          <MenuItem
            sx={myMenuItem}
            component={RouterLink}
            to="/addresses"
            onClick={handleClose}
          >
            {component.ucfirst("addresses-btn")}
          </MenuItem>
        )}
        <MenuItem
          sx={myMenuItem}
          component={RouterLink}
          to="/orders"
          onClick={handleClose}
        >
          {component.ucfirst("orders-btn")}
        </MenuItem>
        <MenuItem
          sx={myMenuItem}
          component={RouterLink}
          to="/coupons"
          onClick={handleClose}
        >
          <CouponBadge2 badgeContent={coupons.length} color="secondary">
            {component.ucfirst("coupons-btn")}
          </CouponBadge2>
        </MenuItem>
        <MenuItem sx={myMenuItem} onClick={() => component.getApp().logout()}>
          {component.ucfirst("logout-btn")}
        </MenuItem>
      </Menu>
    </Container>
  );
}
