import React, { useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Container,
  List,
  Paper,
  LinearProgress,
  Tabs,
  Tab,
  Skeleton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ProductWeeklyListView from "../product_weekly_list_view";
import ProductCartSkeletonView from "../product_cart_skeleton_view";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function WeeklyMenu(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const { menus = [], order } = component.getData("default", {});
  const [selectedWeek, setSelectedWeek] = React.useState(0);
  const [selectedDay, setSelectedDay] = React.useState(0);
  const mobile = useMediaQuery("(max-width:600px)");

  const scrollRefs = {
    "week-0-day-0": useRef(null),
    "week-0-day-1": useRef(null),
    "week-1-day-0": useRef(null),
    "week-1-day-1": useRef(null),
    "week-2-day-0": useRef(null),
    "week-2-day-1": useRef(null),
    "week-3-day-0": useRef(null),
    "week-3-day-1": useRef(null),
  };

  const handleWeekChange = (event, newValue) => {
    const scrollEl = scrollRefs[`week-${selectedWeek}-day-${selectedDay}`];
    if (scrollEl.current) {
      scrollEl.current.scrollTop = 0;
    }
    setSelectedWeek(newValue);
    setSelectedDay(0);
  };

  const handleDayChange = (event, newValue) => {
    const scrollEl = scrollRefs[`week-${selectedWeek}-day-${selectedDay}`];
    if (scrollEl.current) {
      scrollEl.current.scrollTop = 0;
    }
    setSelectedDay(newValue);
  };

  return (
    <Box>
      <Typography
        component="h1"
        align="center"
        sx={{
          ...theme.custom.titles.title,
          ...{ lineHeight: 1.2 },
        }}
      >
        {component.ucfirst("menu-title")}
      </Typography>
      <Typography
        component="h2"
        align="center"
        sx={{
          ...theme.custom.titles.description,
          ...{ fontWeight: 600, mb: 2, lineHeight: 1.2 },
        }}
      >
        {component.ucfirst("menu-subtitle")}
      </Typography>
      <Box sx={{ bgcolor: "#f3f3f3" }}>
        <Container
          maxWidth="md"
          disableGutters
          sx={{
            py: 0,
            px: { xs: 0, md: 2 },
            my: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "#f3f3f3",
            }}
          >
            {menus.length !== 0 ? (
              <Tabs
                value={selectedWeek}
                onChange={handleWeekChange}
                variant="scrollable"
                scrollButtons={false}
                sx={{
                  minHeight: 0,
                  py: 2,
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                {Object.keys(menus).map((menu, index) => {
                  return (
                    <Tab
                      disableRipple
                      key={menu}
                      label={component.ucfirst(`week-${index + 1}`)}
                      sx={{
                        fontWeight: 600,
                        bgcolor: "transparent",
                        p: 1,
                        px: 2,
                        color: "#000",
                        minHeight: 0,
                        border: "2px solid #000",
                        borderRadius: 50,
                        fontSize: 16,
                        mx: 0.5,
                        textTransform: "none",
                        "&.Mui-selected": {
                          color: "#fff",
                          bgcolor: "#000",
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
            ) : (
              <Tabs
                value={false}
                variant="scrollable"
                scrollButtons={false}
                sx={{
                  minHeight: 0,
                  py: 2,
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                {[...new Array(4)].map((item, i) => (
                  <Tab
                    key={`skeleton-category-${i}`}
                    sx={{ p: 0, minHeight: 0, m: 0 }}
                    label={
                      <Skeleton
                        variant="rectangular"
                        height={40}
                        width={130}
                        sx={{
                          mx: "4px",
                          borderRadius: 50,
                        }}
                      />
                    }
                    disabled
                  />
                ))}
              </Tabs>
            )}
          </Box>
          {menus.length !== 0 ? (
            <>
              {Object.keys(menus).map((week, weekIndex) => {
                return (
                  <TabPanel
                    value={selectedWeek}
                    index={weekIndex}
                    key={`week-${weekIndex}`}
                  >
                    <Paper sx={{ p: 0 }} elevation={0}>
                      <Tabs
                        value={selectedDay}
                        onChange={handleDayChange}
                        aria-label="basic tabs example"
                        sx={{
                          minHeight: 0,
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },
                        }}
                        centered
                        variant="fullWidth"
                      >
                        {menus[week].map((menu, dayIndex) => {
                          const { date, max_meals } = menu;
                          const datesTotal = component.getDatesTotal(
                            date,
                            order
                          );
                          return (
                            <Tab
                              key={`tab-week-${weekIndex}-day-${dayIndex}`}
                              sx={{
                                p: 1,
                                bgcolor: "#f3f3f3",
                                "& .MuiLinearProgress-root": {
                                  opacity: 0.6,
                                },
                                "&.Mui-selected": {
                                  bgcolor: "#fff",
                                  "& .MuiLinearProgress-root": {
                                    opacity: 1,
                                  },
                                },
                              }}
                              label={
                                <Stack
                                  sx={{
                                    alignItems: "center",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    component="h6"
                                    sx={{
                                      fontSize: { xs: 18, sm: 22 },
                                      fontWeight: 500,
                                      textTransform: "none",
                                    }}
                                  >
                                    {component
                                      .getApp()
                                      .dateFormater(date)
                                      .slice(0, 3)
                                      .map((value) => component.ucfirst(value))
                                      .join(" ")}
                                  </Typography>
                                  <Box
                                    sx={{
                                      width: { xs: 100, sm: 140 },
                                      position: "relative",
                                    }}
                                  >
                                    <LinearProgress
                                      value={(datesTotal / max_meals) * 100}
                                      variant="determinate"
                                      {...props}
                                      sx={{
                                        height: 20,
                                        borderRadius: 50,
                                        bgcolor: "grey",
                                        "& .MuiLinearProgress-bar": {
                                          bgcolor:
                                            datesTotal === max_meals
                                              ? "#2eb82e"
                                              : "#ff0000",
                                          transition: "transform .2s linear",
                                          WebkitTransition:
                                            "transform .2s linear",
                                        },
                                      }}
                                    />
                                    <Typography
                                      align="center"
                                      sx={{
                                        fontWeight: 600,
                                        position: "absolute",
                                        color: "#fff",
                                        fontSize: { xs: 12, sm: 14 },
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "100%",
                                        textTransform: "none",
                                      }}
                                    >
                                      {`${datesTotal} / ${max_meals} ${component.trans(
                                        "meals"
                                      )}`}
                                    </Typography>
                                  </Box>
                                </Stack>
                              }
                            />
                          );
                        })}
                      </Tabs>
                      {menus[week].map((menu, dayIndex) => {
                        const { date, delivery_date, meals, max_meals } = menu;
                        const datesTotal = component.getDatesTotal(date, order);
                        return (
                          <TabPanel
                            key={`tabpanel-week-${weekIndex}-day-${dayIndex}`}
                            value={selectedDay}
                            index={dayIndex}
                          >
                            <List
                              dense
                              className="hide-scrollbar"
                              ref={
                                scrollRefs[`week-${weekIndex}-day-${dayIndex}`]
                              }
                              sx={{
                                overflowY: mobile ? "auto" : "unset",
                                height: mobile
                                  ? "calc(100vh - 261px)"
                                  : "unset",
                                width: "100%",
                                justifyContent: "top",
                                pt: 0,
                                px: 1,
                                pb: 8,
                                "li:last-child": { borderBottom: "none" },
                              }}
                            >
                              {meals.map((meal, index) => {
                                const { slug } = devHelper.getObjectValue(
                                  meal,
                                  "item._values"
                                );
                                return (
                                  <ProductWeeklyListView
                                    key={`${slug}-${date}-${index}`}
                                    {...{
                                      ...props,
                                      ...{
                                        meal,
                                        order,
                                        date,
                                        delivery_date,
                                        datesTotal,
                                        max_meals,
                                      },
                                    }}
                                  />
                                );
                              })}
                            </List>
                          </TabPanel>
                        );
                      })}
                    </Paper>
                  </TabPanel>
                );
              })}
            </>
          ) : (
            <Paper sx={{ p: 0 }} elevation={0}>
              <Stack direction="row" spacing={1} sx={{ p: 1 }}>
                <Skeleton
                  variant="rounded"
                  sx={{ height: { xs: 60, sm: 65 } }}
                  width="100%"
                />
                <Skeleton
                  variant="rounded"
                  sx={{ height: { xs: 60, sm: 65 } }}
                  width="100%"
                />
              </Stack>
              <List
                dense
                sx={{
                  width: "100%",
                  justifyContent: "top",
                  pt: 0,
                  px: 1,
                  pb: 8,
                }}
              >
                {[...new Array(20)].map((meal, index) => (
                  <ProductCartSkeletonView
                    key={`skeleton-meal-${index}`}
                    {...props}
                  />
                ))}
              </List>
            </Paper>
          )}
        </Container>
      </Box>
    </Box>
  );
}
