import React from "react";
import { SwipeableDrawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CartView from "./cart_view";
import WeeklyCartView from "./weekly_cart_view";

export default function CartDrawer(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const { order = null, opened = false } = component.getData("default", {});

  return (
    <>
      {order && (
        <SwipeableDrawer
          anchor="right"
          open={opened}
          // open={true}
          onClose={() => component.toggle()}
          onOpen={() => {}}
          transitionDuration={{ enter: 500, exit: 500 }}
          swipeAreaWidth={0}
          sx={{
            ".MuiDrawer-paper": {
              height: "100%",
              p: { xs: 1, sm: 2 },
              width: { xs: "100%", sm: "500px" },
              zIndex: 2,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <IconButton
            onClick={() => component.toggle()}
            sx={{
              position: "absolute",
              right: 16,
              top: 8,
              fontSize: 30,
              color: "#000",
              bgcolor: "#f3f3f3",
            }}
          >
            <CloseIcon />
          </IconButton>
          {devHelper.getObjectValue(order, "plan.weekly", false) ? (
            <WeeklyCartView {...props} />
          ) : (
            <CartView {...props} />
          )}
        </SwipeableDrawer>
      )}
    </>
  );
}
