import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, TextField, Typography } from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

export default function Address(props) {
  const { component } = props;

  return (
    <Grid container justifycontent="center" spacing={1}>
      <Grid item xs={12}>
        {!!component.getData("default.address._id") ? (
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              "& i": { mr: 1 },
            }}
          >
            <i className="fa-solid fa-location-dot" />
            {component.getData("default.address.address", "")}
          </Typography>
        ) : (
          <MyTextField
            id="autocomplete"
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("street_address-textfield")}
            name="street"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("bell-textfield")}
          name="bell"
          helperText={
            component.getData("error.address.bell", [{ message: "" }])[0]
              .message
          }
          value={component.getData("default.address.bell", "")}
          onChange={(e) =>
            component.setData({
              "default.address.bell": e.target.value,
            })
          }
          error={component.dataExists("error.address.bell")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("phone-textfield")}
          name="phone"
          helperText={
            component.getData("error.address.phone", [{ message: "" }])[0]
              .message
          }
          value={component.getData("default.address.phone", "")}
          onChange={(e) =>
            component.setData({
              "default.address.phone": e.target.value,
            })
          }
          error={component.dataExists("error.address.phone")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("floor-textfield")}
          name="floor"
          helperText={
            component.getData("error.address.floor", [{ message: "" }])[0]
              .message
          }
          value={component.getData("default.address.floor", "")}
          onChange={(e) =>
            component.setData({
              "default.address.floor": e.target.value,
            })
          }
          error={component.dataExists("error.address.floor")}
        />
      </Grid>
      <Grid item xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          multiline
          fullWidth
          label={component.ucfirst("notes-textfield")}
          name="notes"
          helperText={
            component.getData("error.address.notes", [{ message: "" }])[0]
              .message
          }
          value={component.getData("default.address.notes", "")}
          onChange={(e) =>
            component.setData({
              "default.address.notes": e.target.value,
            })
          }
          error={component.dataExists("error.address.notes")}
        />
      </Grid>
    </Grid>
  );
}
