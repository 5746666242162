import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  Box,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Alert,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function DatetimePicker(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const [selectedDay, setDay] = React.useState(false);
  const [selectedTime, setTime] = React.useState(false);
  const closed = component.getHelpers("value").getValue("closed");

  const { dates = [], order } = component.getData("default", {});

  const handleDayChange = (index) => {
    setDay(index);
    setTime(false);
  };

  return (
    <>
      {dates.length !== 0 && (
        <Container
          disableGutters
          maxWidth="md"
          sx={{ px: { xs: 2, md: 2 }, pb: { xs: "60px", md: 0 } }}
        >
          <Typography
            component="h2"
            align="center"
            sx={{
              ...theme.custom.titles.subtitle,
              ...{ mt: 1 },
            }}
          >
            {component.ucfirst("date-time-picker-title")}
          </Typography>
          <Typography
            align="center"
            sx={{
              color: "#000",
              pl: 1,
              fontSize: 18,
              my: 2,
              fontWeight: 500,
              "& i": {
                mr: "4px",
              },
            }}
          >
            <i className="fa-regular fa-location-dot" />
            {devHelper.getObjectValue(order, "shipping_address.address")}
            <IconButton
              disableRipple
              component={RouterLink}
              to="/select-address"
              sx={{
                color: "#000",
                p: 0,
                mt: "-4px",
                fontSize: 18,
                "&:hover": {
                  color: "#FF6F4D",
                },
                "& i": {
                  ml: 3,
                },
              }}
            >
              <i className="fa-regular fa-pen-to-square" />
            </IconButton>
          </Typography>
          {closed === "true" && (
            <Alert
              severity="info"
              sx={{
                my: 1.5,
              }}
            >
              {component.trans("closed-title")}
            </Alert>
          )}
          {closed === "true" && (
            <Alert
              severity="info"
              sx={{
                my: 1.5,
              }}
            >
              {component.trans("closed-subtitle")}
            </Alert>
          )}
          <Box sx={{ maxWidth: 660, mx: "auto" }}>
            {dates.map((el, index) => {
              return (
                <Accordion
                  key={`day-${index}`}
                  expanded={selectedDay === index}
                  onChange={() => handleDayChange(index)}
                  disableGutters
                  elevation={0}
                  square
                  sx={{
                    border:
                      selectedDay === index
                        ? "2px solid #FF6F4D"
                        : "2px solid #dedede",

                    zIndex: selectedDay === index ? 3 : 1,
                    mt: "-2px",
                    transition: "all .3s ease-in-out",
                    webkitTransition: "all .3s ease-in-out",
                    mx: "5px",
                    "&:before": {
                      display: "none",
                    },
                    "&:first-of-type": {
                      mt: 0,
                    },
                    "&:hover": {
                      zIndex: 2,

                      ml: selectedDay === index ? "5px" : "15px",
                      mr: selectedDay === index ? "5px" : 0,
                    },
                  }}
                >
                  <AccordionSummary sx={{ height: 50 }}>
                    <Typography
                      component="h1"
                      align="center"
                      sx={{
                        width: "100%",
                        fontSize: "20px",
                        fontWeight: 500,
                        conor: "#000",
                      }}
                    >
                      {component
                        .getApp()
                        .dateFormater(
                          devHelper.getObjectValue(el, "_values.date")
                        )
                        .slice(0, 3)
                        .map((value) => component.ucfirst(value))
                        .join(" ")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {selectedDay !== false && (
                        <Grid
                          container
                          direction="row"
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            mx: "auto",
                            maxWidth: 300,
                          }}
                          spacing={1}
                        >
                          {dates[selectedDay]._values.times.map(
                            (time, index) => {
                              const { slug, name } = devHelper.getObjectValue(
                                time,
                                "item._values"
                              );
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  md={6}
                                  key={slug}
                                  sx={{ textAlign: "center" }}
                                >
                                  <Button
                                    fullWidth
                                    sx={{
                                      height: 50,
                                      fontSize: 18,
                                      background: "#fff",
                                      color: "#000",
                                      opacity: selectedTime === index ? 1 : 0.8,
                                      border:
                                        selectedTime === index
                                          ? "2px solid #FF6F4D"
                                          : "1px solid #dedede",
                                      textTransform: "none",
                                      borderRadius: "10px",
                                      "&:hover": {
                                        opacity: 1,
                                        bgcolor: "#fff",
                                      },
                                    }}
                                    onClick={() => setTime(index)}
                                    disableRipple
                                  >
                                    {name}
                                  </Button>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      )}
                    </Box>
                    <Button
                      sx={{
                        ...theme.custom.buttons.submit_btn,
                        ...{ mt: 3.5 },
                      }}
                      variant="contained"
                      disableRipple
                      disabled={selectedDay === false || selectedTime === false}
                      onClick={() =>
                        component.selectDayTime(
                          dates[selectedDay],
                          dates[selectedDay]._values.times[selectedTime]
                        )
                      }
                    >
                      {component.ucfirst("continue")}
                    </Button>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Container>
      )}
    </>
  );
}
