import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

const MyFormControl = styled(FormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

export default function Checkout(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const stringHelper = component.getHelpers("string");

  const { order = null, cutlery = null } = component.getData("default", {});

  var type = "shipping_address";

  const changeValue = (value, attr) => {
    component.setData({
      ["default.order." + type + "." + attr]: value,
      ["default.address." + attr]: value,
    });
    component.deleteData("error." + type + "." + attr);
  };

  const address = devHelper.getObjectValue(order, "shipping_address.address");
  const time = devHelper.getObjectValue(order, "time.name");
  const date = devHelper.getObjectValue(order, "date.date");
  const { delivery_days, weeks_amount } = devHelper.getObjectValue(
    order,
    "weekly",
    {}
  );
  //css
  const info = {
    color: "#000",
    fontSize: 18,
    my: "4px",
    fontWeight: 500,
    "& i": {
      mr: "4px",
    },
  };

  return (
    <>
      {order && (
        <Paper
          elevation={3}
          sx={{
            px: { xs: 2, md: 3 },
            py: 2,
            borderRadius: 1,
            backgroundColor: "#fff",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "20px", sm: "24px", md: "28px" },
              lineHeight: 1,
              mb: 2,
            }}
          >
            {component.ucfirst("checkout-title-1")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 3,
              mb: 1,
            }}
          >
            {devHelper.getObjectValue(order, "plan.weekly") ? (
              <Typography
                variant="h2"
                sx={{
                  fontSize: 24,
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                {`${component.ucfirst(
                  "meal-pack"
                )} ${weeks_amount} ${component.trans("weeks")}`}
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${component.ucfirst(
                    "meal-pack"
                  )} ${devHelper.getObjectValue(order, "plan.min", 0)}`}{" "}
                  {devHelper.getObjectValue(order, "plan.total", 0) -
                    devHelper.getObjectValue(order, "plan.min", 0) >
                    0 && (
                    <Box
                      component="span"
                      sx={{
                        verticalAlign: "super",
                        fontSize: "smaller",
                        ml: -0.4,
                      }}
                    >
                      +
                    </Box>
                  )}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {`${component
                    .getApp()
                    .priceFormater(
                      devHelper.getObjectValue(order, "plan.price")
                    )} / ${component.trans("meal")}`}
                </Typography>
              </>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            {(address || date || time || delivery_days) && (
              <Box
                sx={{
                  border: "3px solid #FF6F4D",
                  width: "100%",
                  borderRadius: "12px",
                }}
              >
                <Typography align="center" sx={info}>
                  <i className="fa-regular fa-location-dot" />
                  {address}
                </Typography>
                {date && (
                  <Typography align="center" sx={info}>
                    <i className="fa-regular fa-calendar-range" />
                    {component
                      .getApp()
                      .dateFormater(
                        devHelper.getObjectValue(order, "date.date")
                      )
                      .slice(0, 3)
                      .map((value) => component.ucfirst(value))
                      .join(" ")}
                  </Typography>
                )}
                {delivery_days && (
                  <Typography align="center" sx={info}>
                    <i className="fa-regular fa-calendar-range" />
                    {delivery_days.split(",").map((day, i) => {
                      if (delivery_days.split(",").length === i + 1) {
                        return component.ucfirst(stringHelper.capitalize(day));
                      } else {
                        return `${component.ucfirst(
                          stringHelper.capitalize(day)
                        )} & `;
                      }
                    })}
                  </Typography>
                )}
                {time && (
                  <Typography align="center" sx={info}>
                    <i className="fa-regular fa-clock" />
                    {time}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Grid container justifycontent="center" spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("phone-textfield")}
                name="phone"
                helperText={
                  component.getData("error." + type + ".phone", [
                    { message: "" },
                  ])[0].message
                }
                value={component.getData("default.address.phone", "")}
                onChange={(e) => changeValue(e.target.value, "phone")}
                error={component.dataExists("error." + type + ".phone")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("bell-textfield")}
                name="bell"
                helperText={
                  component.getData("error." + type + ".bell", [
                    { message: "" },
                  ])[0].message
                }
                value={component.getData("default.address.bell", "")}
                onChange={(e) => changeValue(e.target.value, "bell")}
                error={component.dataExists("error." + type + ".bell")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={component.ucfirst("floor-textfield")}
                name="floor"
                helperText={
                  component.getData("error." + type + ".floor", [
                    { message: "" },
                  ])[0].message
                }
                value={component.getData("default.address.floor", "")}
                onChange={(e) => changeValue(e.target.value, "floor")}
                error={component.dataExists("error." + type + ".floor")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                label={component.ucfirst("notes-textfield")}
                name="notes"
                helperText={
                  component.getData("error." + type + ".notes", [
                    { message: "" },
                  ])[0].message
                }
                value={component.getData("default.address.notes", "")}
                onChange={(e) => changeValue(e.target.value, "notes")}
                error={component.dataExists("error." + type + ".notes")}
              />
            </Grid>
          </Grid>
          {cutlery !== null && (
            <Box sx={{ ml: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& i": {
                    fontSize: 30,
                  },
                }}
              >
                <Box sx={{ color: "#000", mr: 1 }}>
                  <i className="fa-duotone fa-utensils" />
                </Box>
                <MyFormControl>
                  <Select
                    id="select-cutlery-amount"
                    value={cutlery.amount === undefined ? 0 : cutlery.amount}
                    size="small"
                    onChange={(e) => {
                      component.updateCutlery(
                        devHelper.getObjectValue(cutlery, "id"),
                        e.target.value
                      );
                    }}
                  >
                    {[...new Array(component.getCutleryAmount())].map(
                      (_, index) => {
                        return (
                          <MenuItem
                            key={`select-cutlery-amount-${index}`}
                            value={index}
                          >
                            {index}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </MyFormControl>
              </Box>
              <Typography sx={{ mr: 1, fontSize: 14 }}>
                {component.ucfirst("cutleries")}
              </Typography>
            </Box>
          )}
        </Paper>
      )}
    </>
  );
}
