import React from "react";
import { Box, ListItem, Typography, Skeleton, CardMedia } from "@mui/material";
import Translate from "../../../../modules/Core/Components/Translate";

export default function ProductCartView(props) {
  const { component, meal } = props;
  const devHelper = component.getHelpers("dev");
  const { price, amount } = meal;
  const {
    slug,
    name,
    meal_nutrients = [],
  } = devHelper.getObjectValue(meal, "item", {});

  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const dimension = 100;

  return (
    <>
      {meal && (
        <ListItem
          disablePadding
          sx={{
            position: "relative",
            borderBottom: "1px solid #f7f7f7",
            py: 1,
          }}
        >
          <Box
            sx={{
              minWidth: "unset",
              height: dimension,
              width: dimension,
              marginRight: 1,
              display: "flex",
              position: "relative",
            }}
          >
            {!component.getData("default.imageReady", false) && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ borderRadius: "8px" }}
              />
            )}
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "40%",
                height: "100%",
                backgroundColor: "rgb(255, 130, 90,0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 18,
                }}
              >
                {amount}
              </Typography>
            </Box>
            <CardMedia
              component="img"
              sx={{
                height: dimension,
                width: dimension,
              }}
              image={`${filesUrl}/products/webp/${slug}.webp?v=${img_version}`}
              alt={`${name} image`}
              onLoad={() => {
                component.setData({ "default.imageReady": true });
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 16, sm: 18 },
                fontWeight: 600,
                lineHeight: 1.2,
              }}
            >
              <Translate
                entity="item"
                eslug={devHelper.getObjectValue(meal, "item.slug")}
                scope="default"
                slug="title"
                auto
              />
            </Typography>
            {meal_nutrients
              .filter((nutr) => nutr.item._values.slug === "cal")
              .map((nutr) => {
                const { slug, name } = devHelper.getObjectValue(
                  nutr,
                  "item._values"
                );
                return (
                  <Box key={slug}>
                    <Typography
                      sx={{
                        display: "inline-block",
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1,
                        color: "#FF6F4D",
                        mr: 1,
                      }}
                    >
                      {devHelper.getObjectValue(nutr, "value")}
                    </Typography>
                    <Typography
                      sx={{
                        display: "inline-block",
                        color: "#000",
                        fontWeight: 500,
                        fontSize: 18,
                      }}
                    >
                      {component.ucfirst(name)}
                    </Typography>
                  </Box>
                );
              })}
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                pr: { xs: 0, sm: 2 },
              }}
            >
              <Typography
                sx={{
                  visibility: price === 0 ? "hidden" : "visible",
                  fontSize: 18,
                  fontWeight: 500,
                  display: "block",
                  color: "#fff",
                  bgcolor: "#800080",
                  borderBottomLeftRadius: 30,
                  borderTopRightRadius: 30,
                  p: "2px 12px",
                }}
              >
                +{component.getApp().priceFormater(price * amount)}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      )}
    </>
  );
}
