import Page from "../Page";
import templateDefault from "../../templates/default/pages/plans";

export default class Plans extends Page {
  title = "plans";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({ "default.step": 0 });
    this.getApp().updateWindowTitle(
      `${this.ucfirst("meal-packs")} | ${this.trans("window-title")}`
    );
  }
}
