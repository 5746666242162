import React from "react";
import Box from "@mui/material/Box";
import Footer from "../widgets/footer/footer";
import SimpleNavbar from "../../../Components/Widgets/Navbar/SimpleNavbar";
import SingleBanner from "../../../Components/Widgets/Sliders/SingleBanner";
import StepsGrid from "../../../Components/Widgets/Sliders/StepsGrid";
import ExtraInfoGrid from "../../../Components/Widgets/Sliders/ExtraInfoGrid";
import Plans from "../../../Components/Widgets/Steps/Plans";
import MenuSample from "../../../Components/Widgets/Sliders/MenuSample";
import ResetPasswordDialog from "../../../Components/Widgets/Dialogs/ResetPasswordDialog";
import BottomCart from "../../../Components/Widgets/BottomCart";
import Register from "../../../Components/Widgets/Register";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <SimpleNavbar {...props} />
      <SingleBanner
        {...{
          ...props,
          ...{
            scope: "business-top-banner-slider",
            image: "business-top-banner",
            business: true,
          },
        }}
      />
      <Register
        {...{
          ...props,
          ...{
            dialog: false,
          },
        }}
      />
      <StepsGrid
        {...{
          ...props,
          ...{
            scope: "home-steps",
          },
        }}
      />
      <Plans {...props} />
      <MenuSample {...props} />
      <ExtraInfoGrid
        {...{
          ...props,
          ...{
            scope: "home-extra-info",
          },
        }}
      />
      <Footer {...props} />
      <BottomCart {...props} />
      <ResetPasswordDialog {...props} />
    </Box>
  );
};

export default ExportDefault;
