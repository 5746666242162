import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/user/order";
import Services from "../../../Services/Services";

export default class Order extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.retrieveOrder();
  }

  retrieveOrder() {
    const orderId = this.getPage().getParam("id");

    Services.get("user").then(([userService]) => {
      userService
        .fetchOrder(orderId)
        .then((orderService) => {
          const order = orderService.getData("order");

          if (order) {
            this.processOrder(order);
          } else {
            this.redirectTo("/signin");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  processOrder(order) {
    const meals = [];
    const extra = [];
    var type = null;
    const devHelper = this.getHelpers("dev");

    devHelper.getObjectValue(order, "items", []).forEach((meal) => {
      if (meal.item.meal_types.length) {
        type = meal.item.meal_types[0].item._values.slug;
        switch (type) {
          case "deal": {
            meals.push(meal);
            break;
          }
          case "extra": {
            extra.push(meal);
            break;
          }
          default:
            break;
        }
      }
    });

    this.setData({
      "default.order": order,
      "default.meals": meals,
      "default.extra": extra,
    });

    return this;
  }

  getOrdersId() {
    return this.getPage().getParam("id", "");
  }
}
