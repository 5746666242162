import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  TextField,
  IconButton,
  useMediaQuery,
  CardMedia,
  DialogTitle,
  Container,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        color: "#000",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  // const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      fullScreen={fullScreen}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: "0px", sm: "25px" },
        },
      }}
    >
      <MyDialogTitle onClose={() => component.props.setOpen(false)}>
        <CardMedia
          component="img"
          image={`/logos/logo.webp?v=${img_version}`}
          style={{
            width: "140px",
            height: "auto",
          }}
          alt="Coozein Home"
          title="Coozein Home"
        />
      </MyDialogTitle>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            textAlign: "center",
          }}
          onSubmit={(e) => component.sendRequest(e)}
        >
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("fullname-textfield")}
            name="name"
            autoComplete="name"
            helperText={
              component.getData("error.fields.name", [{ message: "" }])[0]
                .message
            }
            value={component.getData("default.fields.name", "")}
            onChange={(e) => {
              component.setData({ "default.fields.name": e.target.value });
              component.deleteData("error.fields.name");
            }}
            error={component.dataExists("error.fields.name")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("email-textfield")}
            name="email"
            helperText={
              component.getData("error.fields.email", [{ message: "" }])[0]
                .message
            }
            value={component.getData("default.fields.email", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.email": e.target.value,
              });
              component.deleteData("error.fields.email");
            }}
            error={component.dataExists("error.fields.email")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("phone-textfield")}
            name="phone"
            helperText={
              component.getData("error.fields.phone", [{ message: "" }])[0]
                .message
            }
            value={component.getData("default.fields.phone", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.phone": e.target.value,
              });
              component.deleteData("error.fields.phone");
            }}
            error={component.dataExists("error.fields.phone")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows={3}
            label={component.ucfirst("message-textfield")}
            name="message"
            helperText={
              component.getData("error.fields.message", [{ message: "" }])[0]
                .message
            }
            value={component.getData("default.fields.message", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.message": e.target.value,
              });
              component.deleteData("error.fields.message");
            }}
            error={component.dataExists("error.fields.message")}
          />
          <Button
            sx={{
              ...theme.custom.buttons.button2,
              ...{ mt: 2, mb: 2 },
            }}
            type="submit"
          >
            {component.ucfirst("submit")}
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
}
