import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/faq/faq";
import Services from "../../Services/Services";

export default class Faq extends Component {
  id = "faq";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getStatics();
  }

  getStatics() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: "faq" },
        })
        .then((response) => {
          const faqs = response.getData() || {};
          this.setData({
            "default.links": faqs,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });

    return this;
  }
}
