import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        color: "#000",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const theme = useTheme();

  return (
    <Dialog
      onClose={() => {
        component.props.setOpen(false);
        component.resetCoupon();
      }}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { md: "25px" },
          minWidth: "280px",
        },
      }}
      maxWidth="sm"
    >
      <MyDialogTitle
        onClose={() => {
          component.props.setOpen(false);
          component.resetCoupon();
        }}
      >
        {component.ucfirst("coupon-dialog-title")}
      </MyDialogTitle>
      <DialogContent sx={{ px: 2, pb: 1 }}>
        <Typography variant="body2">
          {component.trans("coupon-dialog-subtitle")}
        </Typography>
        <form onSubmit={(e) => component.addCoupon(e)}>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="couponCode"
            label={component.trans("promo-code")}
            type="text"
            id="couponCode"
            placeholder={component.trans("promo-holder")}
            helperText={component.getData("error.couponCode")}
            value={component.getData("default.couponCode", "")}
            onChange={(e) => {
              component.setData({
                "default.couponCode": e.target.value,
              });
            }}
            error={component.dataExists("error.couponCode")}
          />
          <button type="submit" hidden></button>
        </form>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        <Button
          sx={{
            ...theme.custom.buttons.button2,
            ...{ mb: 2, display: "block", mx: "auto" },
          }}
          onClick={(e) => component.addCoupon(e)}
        >
          {component.ucfirst("submit-btn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
