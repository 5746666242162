import React from "react";
import Box from "@mui/material/Box";
import NavBar from "../../../Components/Widgets/Navbar/Navbar";
import DatetimePicker from "../../../Components/Widgets/Steps/DatetimePicker";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />
      <DatetimePicker {...props} />
    </Box>
  );
};

export default ExportDefault;
