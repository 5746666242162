import React from "react";
import Box from "@mui/material/Box";
import SampleMenu from "../../../Components/Widgets/SampleMenu";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <SampleMenu {...props} />
    </Box>
  );
};

export default ExportDefault;
