import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextField,
  Grid,
  Grow,
  Checkbox,
} from "@mui/material";
import EpayForm from "./epay_form";

const RadioBtn = styled(Radio)(({ theme }) => ({
  color: "#f05d32",
  "& .MuiSvgIcon-root": {
    fontSize: 18,
  },
  "&.Mui-checked": {
    color: "#f05d32",
    "&:hover": {
      background: "transparent",
    },
  },
  "&:hover": {
    background: "transparent",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

export default function Checkout(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const {
    order = null,
    paymentTypes = [],
    invoice = false,
  } = component.getData("default", {});

  const changeValue = (value, attr) => {
    component.setData({
      ["default.order.business." + attr]: value,
      ["default.business." + attr]: value,
    });
    component.deleteData("error.business." + attr);
  };

  const icons = JSON.parse(
    component.getHelpers("value").getValue("payment-types-icons")
  );

  return (
    <>
      {order && (
        <Paper
          elevation={3}
          sx={{
            px: { xs: 2, md: 3 },
            py: 2,
            borderRadius: "12px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "20px", sm: "24px", md: "28px" },
              lineHeight: 1,
              mb: 2,
            }}
          >
            {component.ucfirst("checkout-title-3")}
          </Typography>
          <FormControlLabel
            sx={{
              mr: 0,
              "&	.MuiFormControlLabel-label": {
                fontSize: 18,
              },
            }}
            control={
              <Checkbox
                sx={{
                  color: "#f05d32",
                  "&.Mui-checked": {
                    color: "#f05d32",
                  },
                  "& .MuiSvgIcon-root": { fontSize: { xs: 20, sm: 20 } },
                }}
                checked={invoice}
                onChange={(e) =>
                  component.setData({ "default.invoice": !invoice })
                }
              />
            }
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: 500,
                  "& div": {
                    textAlign: "center",
                    display: "inline-flex",
                  },
                  "& i": {
                    minWidth: 30,
                    ml: 1,
                    fontSize: 23,
                  },
                }}
              >
                {component.ucfirst("invoice")}
                <Box>
                  <i className="fa-light fa-file-invoice" />
                </Box>
              </Box>
            }
          />

          <Grow in={invoice} style={{ transformOrigin: "0 0 0" }}>
            <Grid
              container
              justifycontent="center"
              spacing={1}
              sx={{
                display: invoice ? "flex" : "none",
              }}
            >
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("textfield-company-name")}
                  helperText={
                    component.getData("error.business.company_name", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.business.company_name", "")}
                  onChange={(e) => changeValue(e.target.value, "company_name")}
                  error={component.dataExists("error.business.company_name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("textfield-profession")}
                  helperText={
                    component.getData("error.business.profession", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.business.profession", "")}
                  onChange={(e) => changeValue(e.target.value, "profession")}
                  error={component.dataExists("error.business.profession")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("textfield-address")}
                  helperText={
                    component.getData("error.business.address", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.business.address", "")}
                  onChange={(e) => changeValue(e.target.value, "address")}
                  error={component.dataExists("error.business.address")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("textfield-tax-office")}
                  helperText={
                    component.getData("error.business.tax_office", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.business.tax_office", "")}
                  onChange={(e) => changeValue(e.target.value, "tax_office")}
                  error={component.dataExists("error.business.tax_office")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("textfield-tax-number")}
                  helperText={
                    component.getData("error.business.tax_number", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.business.tax_number", "")}
                  onChange={(e) => changeValue(e.target.value, "tax_number")}
                  error={component.dataExists("error.business.tax_number")}
                />
              </Grid>
            </Grid>
          </Grow>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: 20, sm: 22, md: 24 },
              lineHeight: 1,
              mb: 2,
              mt: 3,
            }}
          >
            {component.ucfirst("payment-methods")}
          </Typography>
          <RadioGroup
            name="payment_method"
            value={component.findPaymentTypeSelected()}
          >
            {paymentTypes.map((paymentType, index) => {
              const { slug } = devHelper.getObjectValue(
                paymentType,
                "_values",
                {}
              );

              return (
                <FormControlLabel
                  key={slug}
                  value={devHelper.getObjectValue(paymentType, "_id")}
                  control={<RadioBtn disableRipple />}
                  onClick={() =>
                    component.updatePaymentType(
                      devHelper.getObjectValue(paymentType, "_id")
                    )
                  }
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 16,
                        fontWeight: 500,
                        "& div": {
                          textAlign: "center",
                          display: "inline-flex",
                        },
                        "& i": {
                          minWidth: 30,
                          ml: 1,
                          fontSize: 20,
                          color: "#f05d32",
                        },
                      }}
                    >
                      {component.ucfirst(slug)}
                      <Box>
                        <i className={icons[slug]} />
                      </Box>
                    </Box>
                  }
                />
              );
            })}
          </RadioGroup>
        </Paper>
      )}
      <EpayForm {...props} />
    </>
  );
}
