import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";

export default function FirstSection(props) {
  const { component } = props;
  const theme = useTheme();
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const { discount = null } = component.getData("default", {});

  const appbarHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.custom.appbar_height.desktop
    : theme.custom.appbar_height.mobile;

  const scrollToElement = () => {
    var element = document.getElementById("all-plans");
    var offset = -appbarHeight.replace("px", "") * 1; // Replace with the desired offset or minus height
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          py: { xs: 3, md: 4 },
          px: { xs: 3, md: 8 },
          borderRadius: 14,
          bgcolor: "#FF6F4D",
        }}
        rowSpacing={0}
        columnSpacing={{ xs: 0, md: 2 }}
      >
        <Grid item xs={12} md={discount ? 6 : 12} lg={discount ? 7 : 12}>
          <Typography
            component="h1"
            sx={{
              ...theme.custom.titles.title,
              ...{ color: "#fff", mt: { xs: 6, md: 0 } },
            }}
          >
            {component.ucfirst("complete-order-title")}
          </Typography>
          {discount && (
            <Typography
              component="h1"
              sx={{
                ...theme.custom.titles.subtitle,
                ...{ color: "#fff", mt: 4 },
              }}
            >
              {component.ucfirst("complete-order-subtitle")}
            </Typography>
          )}
          <Box sx={{ textAlign: "center" }}>
            <Button
              onClick={() => scrollToElement()}
              sx={{
                ...theme.custom.buttons.button2,
                ...{
                  bgcolor: "#000",
                  color: "#fff",
                  border: "2px solid #000",
                  mt: 6,
                  fontSize: 22,
                  width: "200px",
                  height: "56px",
                  "&:hover": {
                    bgcolor: "#000",
                  },
                },
              }}
              endIcon={
                <i
                  className="fa-duotone fa-chevrons-down fa-beat"
                  style={{ color: "#FF6F4D" }}
                />
              }
            >
              {component.ucfirst("start-now")}
            </Button>
          </Box>
        </Grid>
        {discount && (
          <Grid item xs={12} md={6} lg={5}>
            <CardMedia
              component="img"
              image={`${filesUrl}/marketing/discount.webp?v=${img_version}`}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 10,
              }}
              alt="discount image"
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
