import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/business/business_form";
import Services from "../../Services/Services";

export default class BusinessForm extends Component {
  id = "business-form";

  template = templateDefault;

  amounts = ["1-10", "10-30", "30-50", "50+"];

  default_fields = {
    name: "",
    company: "",
    role: "",
    email: "",
    amount: "",
    phone: "",
    postal_code: "",
  };

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.fields": { ...this.default_fields },
    });
  }

  getAmounts() {
    return this.amounts;
  }

  sendRequest(e) {
    e.preventDefault();

    const fields = this.getData("default.fields", { ...this.default_fields });
    const error = this.validateFields(fields);
    const amount = this.getAmounts()[fields.amount];

    this.setData({
      error,
    });

    if (!error.fields) {
      Services.get("hook").then(async ([hookService]) => {
        try {
          await hookService.post("business-contact-us", {
            ...fields,
            ...{ amount },
          });

          this.setData({
            "default.fields": { ...this.default_fields },
            error: {},
          });

          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans("message-send"),
                type: "success",
                duration: 2000,
              },
            });
        } catch (e) {
          //
        }
      });
    }
  }

  validateFields(fields) {
    const error = {
      fields: this.getHelpers("validate").validate(fields, {
        name: [
          {
            rule: "required",
            message: this.ucfirst("fullname-helpertext"),
          },
        ],
        company: [
          {
            rule: "required",
            message: this.ucfirst("company-helpertext"),
          },
        ],
        // role: [
        //   {
        //     rule: "required",
        //     message: this.ucfirst("role-helpertext"),
        //   },
        // ],
        email: [
          {
            rule: "required",
            message: this.ucfirst("email-helpertext"),
          },
          {
            rule: "email",
            message: this.ucfirst("invalid-email"),
          },
        ],
        amount: [
          {
            rule: "required",
            // message: this.ucfirst("amount-helpertext"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
        postal_code: [
          {
            rule: "required",
            message: this.ucfirst("postal_code-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("postal_code-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("postal_code-helpertext-2"),
            min: 5,
            max: 5,
          },
        ],
      }),
    };

    return error;
  }
}
