import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/coupon_dialog";
import Services from "../../../Services/Services";

export default class CouponDialog extends Component {
  id = "coupon-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
    });
  }

  addCoupon(e) {
    e.preventDefault();

    const { couponCode } = this.getData("default", {});
    const error = {};

    if (!couponCode) {
      error["couponCode"] = this.ucfirst("promo-code-helpertext");
    }

    this.setData({
      error,
    });

    if (Object.keys(error).length === 0) {
      Services.get("coupon").then(([couponService]) => {
        couponService
          .addCoupon(couponCode)
          .then(() => {
            this.props.setOpen(false);
            this.resetCoupon();
            this.props.retrieveCoupons();
          })
          .catch((err) => {
            this.props.setOpen(false);
            this.resetCoupon();
            if (couponService.getData("_response").getMessage() === "error") {
              this.getComponents()
                .findById("main-message")
                .first()
                .setData({
                  message: {
                    text: this.trans("coupon-unavailable-message"),
                    type: "error",
                  },
                });
            }
          });
      });
    }
  }

  resetCoupon() {
    this.setData({
      "default.couponCode": "",
    });
    this.deleteData("error");
  }
}
