import React from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Container,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Translate from "../../../../../modules/Core/Components/Translate";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#000",
  fontWeight: "600",
  marginBottom: "15px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#000",
  fontWeight: "500",
  "& i": {
    fontSize: "20px",
    marginRight: "8px",
  },
}));

export default function TermsAndConditions(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const links = component.getData("default.links", []);
  const [section, setSection] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setSection(newExpanded ? panel : false);
  };

  return (
    <Container
      sx={{
        px: 2,
        my: 3,
      }}
      maxWidth="lg"
    >
      <MainTitle
        variant="h1"
        sx={{
          ...theme.custom.titles.subtitle,
        }}
      >
        {component.ucfirst("faq")}
      </MainTitle>

      {links.map((link, index) => {
        const { slug } = devHelper.getObjectValue(link, "_values");
        return (
          <Accordion
            key={slug}
            expanded={section === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary sx={{ py: 1.5 }}>
              <Title
                variant="h2"
                sx={{
                  ...theme.custom.titles.subtitle,
                  ...{ fontSize: { xs: 18, sm: 20, md: 22 } },
                }}
              >
                <Translate
                  entity="item"
                  eslug={slug}
                  scope="default"
                  slug="name"
                  auto
                />
              </Title>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                px: { xs: 2, md: 5 },
                "& h5": {
                  fontWeight: 400,
                  mt: 0,
                },
                "& h4": {
                  mt: 0,
                },
              }}
            >
              <Typography
                component="h3"
                sx={{
                  ...theme.custom.titles.description,
                }}
              >
                <Translate
                  entity="item"
                  eslug={slug}
                  scope="default"
                  slug="info"
                  auto
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Container>
  );
}
