import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/drawers/menu_drawer";
import Services from "../../../Services/Services";

export default class MenuDrawer extends Component {
  id = "menu-drawer";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.getOrder();
  }

  getOrder() {
    Services.get("order").then(async ([orderService]) => {
      var order = null;
      try {
        await orderService.fetchOrder();
        order = orderService.getData("order");
      } catch (e) {}
      this.setData({
        "default.order": order ? order : {},
      });
    });
  }

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }
}
