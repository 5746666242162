import React from "react";
import { useTheme } from "@mui/material/styles";
import { CardMedia, Grid, Typography, Container, Box } from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function ExtraInfoGrid(props) {
  const theme = useTheme();
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const sliders = component.getData("default.sliders", []);

  return (
    <Box sx={{ mt: 3, pt: 3, pb: 3 }}>
      <Container maxWidth="xl" disableGutters sx={{ px: { xs: 1, md: 2 } }}>
        <Typography
          component="h1"
          align="center"
          sx={{
            ...theme.custom.titles.title,
            ...{ mb: 4 },
          }}
        >
          {component.ucfirst("home-extra-info-title")}
        </Typography>
        <Grid
          container
          direction="column"
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
            my: 1,
            mx: "auto",
          }}
          maxWidth="lg"
          rowSpacing={2}
        >
          {sliders.map((slider) => {
            const { slug, name } = devHelper.getObjectValue(slider, "_values");
            return (
              <Grid item xs={12} md={6} key={slug}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`${filesUrl}/marketing/extra-info.webp?v=${img_version}`}
                    sx={{
                      width: { xs: "35px", md: "45px" },
                      height: { xs: "35px", md: "45px" },
                      mr: 2,
                    }}
                    alt={name}
                    title={name}
                  />
                  <Box>
                    <Typography
                      component="h2"
                      sx={{
                        ...theme.custom.titles.subtitle,
                      }}
                    >
                      <Translate
                        entity="item"
                        eslug={slug}
                        scope="default"
                        slug="title"
                        auto
                      />
                    </Typography>
                    <Typography
                      component="h3"
                      sx={{
                        ...theme.custom.titles.description,
                        ...{ mt: 1, mb: 2 },
                      }}
                    >
                      <Translate
                        entity="item"
                        eslug={slug}
                        scope="default"
                        slug="subtitle"
                        auto
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
