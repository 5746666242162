import React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Container,
  Grid,
  Paper,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function MenuSample(props) {
  const { component } = props;

  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { categories = [], lists = [] } = component.getData("default", {});
  const mobile = useMediaQuery("(max-width:500px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      {categories.length !== 0 && (
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            my: 3,
          }}
        >
          <Typography
            component="h1"
            align="center"
            sx={{
              ...theme.custom.titles.title,
            }}
          >
            {component.ucfirst("menu-title")}
          </Typography>
          <Typography
            component="h2"
            align="center"
            sx={{
              ...theme.custom.titles.subtitle,
              ...{ my: 2 },
            }}
          >
            {component.ucfirst("home-subtitle-2")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              {categories.map((category) => {
                const { slug } = category;
                return (
                  <Tab
                    disableRipple
                    key={slug}
                    label={component.ucfirst(slug)}
                    sx={{
                      fontWeight: 600,
                      bgcolor: "transparent",
                      p: 1,
                      px: 2,
                      color: "#000",
                      minHeight: 0,
                      border: "2px solid #000",
                      borderRadius: 50,
                      fontSize: 16,
                      mx: 0.5,
                      textTransform: "none",
                      "&.Mui-selected": {
                        color: "#fff",
                        bgcolor: "#000",
                      },
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box sx={{ maxWidth: 800, mt: 2, mx: "auto", px: 2 }}>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {lists.map((list, index) => {
                const { meals } = list;
                return (
                  <TabPanel
                    value={value}
                    index={index}
                    key={`list-${index}`}
                    dir={theme.direction}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      {meals.map((meal, i) => {
                        return (
                          <Grid
                            key={`grid-${meal}}`}
                            item
                            md={4}
                            xs={mobile ? 6 : 4}
                          >
                            <Paper
                              elevation={0}
                              sx={{ textDecoration: "none" }}
                            >
                              <CardMedia
                                component="img"
                                image={`${filesUrl}/products/webp/${meal}.webp?v=${img_version}`}
                                alt={meal}
                                sx={{
                                  mx: "auto",
                                }}
                              />
                              <Typography
                                component="h3"
                                align="center"
                                sx={{
                                  color: "#000",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  lineHeight: 1.2,
                                }}
                              >
                                <Translate
                                  entity="item"
                                  eslug={meal}
                                  scope="default"
                                  slug="title"
                                  auto
                                />
                              </Typography>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </TabPanel>
                );
              })}
            </SwipeableViews>
          </Box>
        </Container>
      )}
    </>
  );
}
