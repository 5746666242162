import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/steps/address";
import Services from "../../../Services/Services";

export default class Address extends Component {
  id = "address";
  map = null;
  template = templateDefault;

  default_address = {
    street: "",
    street_number: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
    lat: "",
    lng: "",
    bell: "",
    floor: "",
    notes: "",
  };

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.retrieveAddresses();
  }

  retrieveAddresses() {
    Services.get("order,address").then(
      async ([orderService, addressService]) => {
        try {
          const devHelper = this.getHelpers("dev");
          await orderService.fetchOrder();
          const order = orderService.getData("order");
          const guest = devHelper.getObjectValue(order, "guest");
          this.setNewMarker();
          if (!guest) {
            await addressService.getAddresses();
          }

          const addresses = addressService.getData("addresses", []);
          const autocomplete = guest || addresses.length === 0;

          if (autocomplete) {
            this.initGoogleAutocomplete();
          }

          this.setData({
            "default.order": order,
            "default.guest": guest,
            "default.addresses": addresses,
            "default.autocomplete": autocomplete,
          });
        } catch (e) {
          console.log(e);

          this.checkOrderError(e);
        }
      }
    );

    return this;
  }

  setNewMarker(address) {
    const uluru = address
      ? {
          lat: parseFloat(address.lat),
          lng: parseFloat(address.lng),
        }
      : { lat: 37.98381, lng: 23.727539 };

    this.map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: address ? 18 : 13,
      center: uluru,
      disableDefaultUI: true,
      clickableIcons: false,
      disableDoubleClickZoom: true,
      mapId: "DEMO_MAP_ID",
    });

    this.map.setOptions({
      styles: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    });

    if (address) {
      new window.google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        position: uluru,
      });
    }
  }

  initGoogleAutocomplete() {
    setTimeout(() => {
      let autocomplete;
      if (document.getElementById("autocomplete")) {
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          {
            componentRestrictions: { country: ["gr"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
          }
        );
        autocomplete.addListener("place_changed", () => {
          var place = autocomplete.getPlace();

          this.parseAddressComponents(place);
        });
      }
    }, 500);
  }

  parseAddressComponents(place) {
    let valid = false;
    let missingValues = [];
    const values = [
      "street",
      "street_number",
      "city",
      "postal_code",
      "state",
      "country",
    ];

    let address = { ...this.default_address };
    if (place.geometry) {
      const uluru = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      address["lat"] = uluru.lat;
      address["lng"] = uluru.lng;

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "route": {
            address["street"] = component.short_name;
            break;
          }
          case "street_number": {
            address["street_number"] = component.long_name;
            break;
          }
          case "locality": {
            address["city"] = component.short_name;
            break;
          }
          case "administrative_area_level_5": {
            address["city"] = address["city"]
              ? address["city"]
              : component.short_name;
            break;
          }
          case "postal_code": {
            address["postal_code"] = component.long_name;
            break;
          }
          case "postal_code_suffix": {
            address[
              "postal_code"
            ] = `${address["postal_code"]}-${component.long_name}`;
            break;
          }
          case "administrative_area_level_1": {
            address["state"] = component.short_name;
            break;
          }
          case "administrative_area_level_3": {
            address["state"] = component.short_name;
            break;
          }
          case "country":
            address["country"] = component.long_name;
            break;
          default:
            break;
        }
      }

      values.forEach((val) => {
        if (!address[val]) {
          missingValues.push(val);
        }
      });

      valid = !!!(missingValues.length > 0);

      this.setNewMarker(address);

      if (valid) {
        address[
          "address"
        ] = `${address.street} ${address.street_number}, ${address.city} ${address.postal_code}`;
      } else {
        console.error("missing: " + missingValues);
      }
    }

    this.setData({
      "default.address": address,
      "default.valid": valid,
    });

    if (!valid) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          message: {
            text: this.trans("incomplete-address-message"),
            type: "error",
          },
        });
    }
  }

  setOrdersAddress() {
    var { address, addressId, guest, autocomplete } = this.getData(
      "default",
      {}
    );
    const devHelper = this.getHelpers("dev");
    var order = null;

    if (guest && address) {
      Services.get("order").then(async ([orderService]) => {
        try {
          await orderService.updateAddress({
            shippingAddress: address,
            billingAddress: address,
          });
          order = orderService.getData("order");
          this.setOrder(order);
        } catch (e) {
          this.checkOrderError(e);
        }
      });
    } else {
      Services.get("order,address").then(
        async ([orderService, addressService]) => {
          try {
            if (autocomplete && address) {
              await addressService.createAddress(address);
              const new_address = addressService.getData("new_address");
              addressId = devHelper.getObjectValue(new_address, "_id");
            }
            await orderService.updateAddress({
              shippingAddressId: addressId,
              billingAddressId: addressId,
            });
            order = orderService.getData("order");

            this.setOrder(order);
          } catch (e) {
            this.checkOrderError(e);
          }
        }
      );
    }
  }

  setOrder(order) {
    if (order && order.spot) {
      this.getComponents()
        .findByGroup("order")
        .forEach((comp) => {
          comp.setData({ "default.order": order });
        });
      this.getPage().redirect(
        order.plan.weekly ? "/weekly/meal-packs" : "/select-date-time"
      );
    } else {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          message: {
            text: this.ucfirst("address-out-of-bounds"),
            type: "info",
            duration: 6000,
          },
        });
    }

    return this;
  }

  selectAddress(id) {
    this.setData({
      "default.addressId": id,
    });
    const addresses = this.getData("default.addresses", []);

    this.setNewMarker(addresses.find((addr) => addr.id === id));
    return this;
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          this.getPage().redirect("/meal-packs");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
