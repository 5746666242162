import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";
import MainMessage from "../Components/Widgets/MainMessage";
import LoginRegisterDialog from "../Components/Widgets/Dialogs/LoginRegisterDialog";
import CartDrawer from "../Components/Widgets/Drawers/CartDrawer";
import MenuDrawer from "../Components/Widgets/Drawers/MenuDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: {
      main: "#28282B",
    },
    secondary: {
      main: "#800080",
    },
  },
  custom: {
    appbar_height: {
      desktop: "68px",
      mobile: "56px",
    },
    titles: {
      title: {
        fontSize: { xs: 25, sm: 35, md: 45, lg: 50 },
        fontWeight: 600,
        color: "#000",
      },
      subtitle: {
        fontSize: { xs: 20, sm: 22, md: 25, lg: 30 },
        fontWeight: 600,
        color: "#000",
      },
      description: {
        fontSize: { xs: 17, sm: 17, md: 18, lg: 20 },
        fontWeight: 400,
        color: "#000",
      },
    },
    buttons: {
      button1: {
        color: "#000",
        border: "2px solid transparent",
        borderRadius: 0,
        fontSize: { xs: 18, md: 18 },
        py: 0,
        textTransform: "none",
        fontWeight: 600,
        "&:hover": {
          bgcolor: "transparent",
          borderBottom: "2px solid #FF6F4D",
        },
      },
      button2: {
        fontWeight: 600,
        bgcolor: "#FF6F4D",
        color: "#fff",
        border: "2px solid #FF6F4D",
        borderRadius: 50,
        fontSize: { xs: 18, md: 18 },
        width: "150px",
        height: "46px",
        textTransform: "none",
        "&:hover": {
          bgcolor: "transparent",
          color: "#000",
        },
      },
      button3: {
        fontWeight: 600,
        bgcolor: "#fff",
        color: "#000",
        border: "2px solid #FF6F4D",
        borderRadius: 50,
        fontSize: { xs: 18, md: 18 },
        width: "150px",
        height: "46px",
        textTransform: "none",
        "&:hover": {
          bgcolor: "#FF6F4D",
          color: "#fff",
        },
      },
      submit_btn: {
        fontWeight: 600,
        bgcolor: "#FF6F4D",
        color: "#fff",
        border: "2px solid #FF6F4D",
        borderRadius: 50,
        fontSize: { xs: 18, md: 18 },
        width: { xs: "80%", sm: 200, md: 240 },
        height: "46px",
        textTransform: "none",
        opacity: 1,
        display: "block",
        mx: "auto",
        mt: 2,
        "&:hover": {
          bgcolor: "#FF6F4D",
          opacity: 0.9,
        },
        "&:disabled": {
          display: "none",
        },
      },
    },
  },
});

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <MainMessage {...props} />
          <CartDrawer {...props} />
          <MenuDrawer {...props} />
          <LoginRegisterDialog {...props} />
          <Switch>
            <Route exact path="/" component={Pages.Home} />
            <Route exact path="/business" component={Pages.Business} />
            <Route
              exact
              path="/business/:company"
              component={Pages.RegisterBusiness}
            />
            <Route
              exact
              path="/terms-and-conditions/:section"
              component={Pages.TermsAndConditions}
            />
            <Route exact path="/faq" component={Pages.Faq} />
            <Route exact path="/profile" component={Pages.Profile} />
            <Route exact path="/addresses" component={Pages.Addresses} />
            <Route exact path="/coupons" component={Pages.Coupons} />
            <Route exact path="/orders" component={Pages.Orders} />
            <Route exact path="/order/:id" component={Pages.Order} />
            <Route exact path="/meal/:slug" component={Pages.Product} />
            <Route exact path="/menu/:date" component={Pages.SampleMenu} />
            {/* Steps */}
            <Route exact path="/meal-packs" component={Pages.Plans} />
            <Route exact path="/select-address" component={Pages.Address} />
            <Route
              exact
              path="/select-date-time"
              component={Pages.DatetimePicker}
            />
            <Route exact path="/menu" component={Pages.Menu} />
            <Route exact path="/checkout" component={Pages.Checkout} />
            {/* Weekly Steps */}
            <Route
              exact
              path="/weekly/select-address"
              component={Pages.Address}
            />
            <Route
              exact
              path="/weekly/meal-packs"
              component={Pages.WeeklyPlans}
            />
            <Route exact path="/weekly/menu" component={Pages.WeeklyMenu} />
            <Route exact path="/weekly/checkout" component={Pages.Checkout} />
            <Route
              exact
              path="/thank-you-for-your-order"
              component={Pages.ThankYouPage}
            />
            <Route exact path="/epay-success" component={Pages.EpayResult} />
            <Route exact path="/epay-failure" component={Pages.EpayResult} />
            <Route component={Pages.Redirect} />
          </Switch>
        </Router>
      </ThemeProvider>
    )
  );
};

export default ExportDefault;
