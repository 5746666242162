import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Typography,
  Grid,
  Box,
  Fade,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import { Link as RouterLink } from "react-router-dom";
import Translate from "../../../../../modules/Core/Components/Translate";

function MyCircularProgress(props) {
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        mx: 2,
        my: 1,
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 100,
          height: 100,
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={100}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            color: "#FF6F4D",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={100}
          thickness={4}
          {...props}
          value={props.value}
        />
        <Typography
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            position: "absolute",
            fontWeight: 600,
            fontSize: 22,
          }}
        >
          {props.value}g
        </Typography>
      </Box>
      <Typography
        align="center"
        sx={{ fontWeight: 600, fontSize: 18, mt: "4px" }}
      >
        {props.name}
      </Typography>
    </Box>
  );
}

export default function FirstSection(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const max_meals = component.getHelpers("value").getValue("max-meals") * 1;
  const lang = component.getHelpers("language").getLang("en");

  const {
    ready = false,
    order,
    meal,
    allergens,
  } = component.getData("default", {});
  const amount = component.getProductAmount(
    devHelper.getObjectValue(meal, "_id"),
    order
  );
  const {
    slug,
    name,
    meal_types = [],
    meal_nutrients = [],
    meal_allergens = [],
    meal_categories = [],
    price_coozein,
    weight_package,
    trans = [],
  } = devHelper.getObjectValue(meal, "_values", {});

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const mg = [
    "cholesterol",
    "sodium",
    "vitamin-c",
    "vitamin-d",
    "iron",
    "calcium",
    "potassium",
    "phosphorus",
  ];
  const main_nutrients = [
    "calories",
    "total-fat",
    "cholesterol",
    "sodium",
    "total-carbohydrate",
    "protein",
  ];
  //css

  const tag = {
    display: "inline-block",
    my: 1,
    py: 1,
    px: 2,
    fontWeight: 500,
    lineHeight: 1,
    fontSize: { xs: 16, md: 18 },
    border: "2px solid #FF6F4D",
    bgcolor: "rgb(255, 111, 77,0.15)",
    color: "#000",
    borderRadius: 50,
    mx: 1,
    textAlign: "center",
  };

  const isSpecial =
    component.isAuthenticated() &&
    component.getAuth().getUserAttribute("role") === "special";

  return (
    <Container sx={{ pb: "60px" }} disableGutters maxWidth="xl">
      {meal && (
        <Grid container sx={{ position: "relative" }}>
          <Grid item xs={12} md={6}>
            <Fade in={ready}>
              <CardMedia
                component="img"
                sx={{
                  width: { xs: "100%", sm: "70%", md: 410, lg: 540, xl: 700 },
                  height: { xs: "auto", sm: "auto", md: 410, lg: 540, xl: 700 },
                  position: { xs: "reltive", md: "fixed" },
                  top: { xs: "unset", md: 100 },
                  left: { xs: "unset", md: "50%" },
                  transform: { xs: "unset", md: "translate(-110%,0)" },
                  mx: "auto",
                }}
                image={`${filesUrl}/products/webp/${slug}.webp?v=${img_version}`}
                alt={`${name} image`}
                onLoad={() => {
                  component.setData({ "default.ready": true });
                }}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: 1 }}>
            {/* main info */}
            <Box sx={{ mt: 4, mb: 8 }}>
              <Typography
                component="h1"
                sx={{
                  ...theme.custom.titles.title,
                  ...{
                    fontSize: { xs: 30, sm: 40, md: 50 },
                    lineHeight: 1.2,
                  },
                }}
              >
                {trans.length !== 0 &&
                  trans.find((tran) => tran.lang === lang).translation}
              </Typography>
              {meal_categories
                .filter((cat) => cat.item._values.slug !== "all-meals")
                .map((cat) => {
                  const { slug } = devHelper.getObjectValue(
                    cat,
                    "item._values"
                  );
                  return (
                    <Typography key={slug} component="h5" sx={tag}>
                      {component.ucfirst(slug)}
                    </Typography>
                  );
                })}
              {allergens.map((al) => {
                const { slug } = devHelper.getObjectValue(al, "_values");
                if (
                  !meal_allergens.some((al) => al.item._values.slug === slug)
                ) {
                  return (
                    <Typography key={slug} component="h5" sx={tag}>
                      {component.ucfirst(`${slug}-free`)}
                    </Typography>
                  );
                } else {
                  return <React.Fragment key={slug} />;
                }
              })}
              <Typography
                component="h3"
                sx={{
                  ...theme.custom.titles.description,
                  ...{ mt: 3, mb: 2 },
                }}
              >
                <Translate
                  entity="item"
                  eslug={slug}
                  scope="default"
                  slug="description"
                  auto
                />
              </Typography>
              {/* button section */}
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    display: "inline-block",
                    visibility: price_coozein === 0 ? "hidden" : "visible",
                    fontSize: 22,
                    fontWeight: 500,
                    color: "rgba(0, 0, 0, 0.9)",
                    mr: 2,
                  }}
                >
                  +{component.getApp().priceFormater(price_coozein)}
                </Typography>

                <></>
                {order ? (
                  <Button
                    sx={{
                      ...theme.custom.buttons.button2,
                      ...{
                        display: amount !== 0 ? "none" : "inline-flex",
                        textTransform: "uppercase",
                        bgcolor: "#000",
                        borderColor: "#000",
                        fontSize: 16,
                        visibility: devHelper.getObjectValue(
                          order,
                          "plan.weekly"
                        )
                          ? "hidden"
                          : "visible",
                        "&:hover": {
                          bgcolor: "#000",
                        },
                        "&:disabled": {
                          color: "#fff",
                          opacity: 0.8,
                        },
                      },
                    }}
                    disabled={
                      !isSpecial &&
                      meal_types.some(
                        (type) => type.item._values.slug === "deal"
                      ) &&
                      devHelper.getObjectValue(order, "amounts.deals", 0) !==
                        0 &&
                      devHelper.getObjectValue(order, "amounts.deals", 0) ===
                        max_meals
                    }
                    onClick={() => component.updateCart(meal, 1)}
                  >
                    {component.ucfirst("add-to-cart-btn")}
                  </Button>
                ) : (
                  <Button
                    component={RouterLink}
                    to="/meal-packs"
                    sx={{
                      ...theme.custom.buttons.button2,
                      ...{
                        textTransform: "uppercase",
                        bgcolor: "#000",
                        borderColor: "#000",
                        fontSize: 16,
                        "&:hover": {
                          bgcolor: "#000",
                        },
                      },
                    }}
                  >
                    {component.ucfirst("start-now-btn")}
                  </Button>
                )}
                <ButtonGroup
                  sx={{
                    display: amount === 0 ? "none" : "inline-flex",
                    visibility: devHelper.getObjectValue(order, "plan.weekly")
                      ? "hidden"
                      : "visible",
                    "& .MuiButton-root": {
                      width: "50px",
                      height: "46px",
                      bgcolor: "#000",
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: 600,
                      border: "1px solid #000",
                      "&:hover": {
                        bgcolor: "#000",
                        borderColor: "#000",
                      },
                      "&:disabled": {
                        color: "#fff",
                      },
                      "& > i": {
                        fontSize: 16,
                      },
                    },
                  }}
                >
                  <Button
                    sx={{
                      borderTopLeftRadius: 50,
                      borderBottomLeftRadius: 50,
                    }}
                    onClick={() => {
                      component.updateCart(meal, -1);
                    }}
                  >
                    <i className="fa-solid fa-minus" />
                  </Button>
                  <Button disabled sx={{}}>
                    {amount}
                  </Button>
                  <Button
                    disabled={
                      !isSpecial &&
                      meal_types.some(
                        (type) => type.item._values.slug === "deal"
                      ) &&
                      devHelper.getObjectValue(order, "amounts.deals", 0) !==
                        0 &&
                      devHelper.getObjectValue(order, "amounts.deals", 0) ===
                        max_meals
                    }
                    sx={{
                      borderTopRightRadius: 50,
                      borderBottomRightRadius: 50,
                      "&:disabled": {
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => {
                      component.updateCart(meal, 1);
                    }}
                  >
                    <i className="fa-solid fa-plus" />
                  </Button>
                </ButtonGroup>
              </Box>
              <Typography
                component="h3"
                sx={{
                  ...theme.custom.titles.description,
                  ...{
                    borderTop: "1px solid #FF6F4D",
                    pt: 2,
                    mt: 2,
                    mb: 2,
                    fontSize: 16,
                  },
                }}
              >
                {component.ucfirst("product-extra-info-2")}
              </Typography>
            </Box>
            {/*  nutrition profile */}
            <Box sx={{ mb: 8 }}>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.subtitle,
                  ...{ mb: 4 },
                }}
              >
                {component.ucfirst("product-page-subtitle-1")}
              </Typography>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                columnSpacing={{ xs: 4, md: 0 }}
                rowSpacing={3}
              >
                <Grid item xs={12} sm={4}>
                  {meal_nutrients
                    .filter((nutr) => nutr.item._values.slug === "calories")
                    .map((nutr) => {
                      const { slug } = devHelper.getObjectValue(
                        nutr,
                        "item._values"
                      );
                      return (
                        <Box key={slug}>
                          <Typography
                            component="h4"
                            align="center"
                            sx={{
                              fontWeight: 800,
                              fontSize: { xs: 45, md: 60 },
                              lineHeight: 1,
                              color: "#FF6F4D",
                            }}
                          >
                            {devHelper.getObjectValue(nutr, "value")}
                          </Typography>
                          <Typography
                            component="h4"
                            align="center"
                            sx={{
                              color: "#000",
                              fontWeight: 600,
                              fontSize: 22,
                            }}
                          >
                            {component.ucfirst(slug)}
                          </Typography>
                        </Box>
                      );
                    })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    {meal_nutrients
                      .filter(
                        (nutr) =>
                          nutr.item._values.slug === "protein" ||
                          nutr.item._values.slug === "total-carbohydrate" ||
                          nutr.item._values.slug === "total-fat"
                      )
                      .sort(
                        (a, b) =>
                          b.item._values.position - a.item._values.position
                      )
                      .map((nutr) => {
                        const { slug } = devHelper.getObjectValue(
                          nutr,
                          "item._values"
                        );

                        return (
                          <MyCircularProgress
                            key={slug}
                            value={devHelper.getObjectValue(nutr, "value")}
                            name={`${component.ucfirst(slug)}`}
                          />
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Accordion
                    disableGutters
                    elevation={0}
                    square
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    sx={{
                      border: "2px solid #000",
                      borderRadius: 2,
                      "&:not(:last-child)": {
                        borderBottom: 0,
                      },
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary>
                      <Typography
                        align="center"
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          mx: "auto",
                        }}
                      >
                        {component.trans("nutrients-facts")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: { xs: 1, sm: 4 } }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: 600,
                          fontSize: 18,
                          borderBottom: "3px solid #000",
                          p: 1,
                        }}
                      >
                        {component.trans("serving-per-container")}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: 600,
                          px: 1,
                          py: 2,
                        }}
                      >
                        <Box>{component.trans("serving-size")}</Box>
                        <Box>{`${weight_package}g`}</Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #dedede",
                          fontWeight: 600,
                          py: 1.5,
                        }}
                      >
                        <Box />
                        <Box>{component.trans("per-serving")}</Box>
                      </Box>
                      {meal_nutrients
                        .filter((nutr) => nutr.item._values.slug === "calories")
                        .map((nutr) => {
                          const { slug } = devHelper.getObjectValue(
                            nutr,
                            "item._values"
                          );

                          return (
                            <Box
                              key={slug}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #dedede",
                                fontWeight: 600,
                                p: 1.5,
                              }}
                            >
                              <Box>{component.trans(slug)}</Box>
                              <Box>
                                {`${devHelper.getObjectValue(nutr, "value")}`}
                              </Box>
                            </Box>
                          );
                        })}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: 600,
                          p: 1.5,
                          pb: 0,
                        }}
                      >
                        <Box />
                        <Box>% daily value *</Box>
                      </Box>
                      {meal_nutrients
                        .filter((nutr) => nutr.item._values.slug !== "calories")
                        .sort(
                          (a, b) =>
                            a.item._values.position - b.item._values.position
                        )
                        .map((nutr) => {
                          const { slug } = devHelper.getObjectValue(
                            nutr,
                            "item._values"
                          );

                          return (
                            <Box
                              key={slug}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderTop:
                                  main_nutrients.includes(slug) &&
                                  slug !== "total-fat"
                                    ? "1px solid #dedede"
                                    : "none",
                                borderBottom:
                                  slug === "protein"
                                    ? "1px solid #dedede"
                                    : "none",
                                fontWeight: main_nutrients.includes(slug)
                                  ? 600
                                  : 400,
                                pl: main_nutrients.includes(slug) ? 1.5 : 3,
                                pr: 1.5,
                                pt:
                                  main_nutrients.includes(slug) ||
                                  slug === "vitamin-c"
                                    ? 1.5
                                    : 0,
                                pb: 1.5,
                              }}
                            >
                              <Box>{component.trans(slug)}</Box>
                              <Box>
                                <Box sx={{ display: "inline-block" }}>
                                  {`${devHelper.getObjectValue(nutr, "value")}${
                                    mg.includes(slug) ? "mg" : "g"
                                  }`}
                                </Box>
                                <Box
                                  sx={{
                                    display: "inline-block",
                                    width: 40,
                                    textAlign: "right",
                                    ml: 2,
                                  }}
                                >
                                  {`${devHelper.getObjectValue(
                                    nutr,
                                    "percentage"
                                  )}%`}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      <Box
                        key={slug}
                        sx={{
                          borderTop: "1px solid #dedede",
                          fontWeight: 400,
                          pt: 4,
                          pb: 2,
                        }}
                      >
                        {component.ucfirst("daily-value-description")}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Box>
            {/* ingredients */}
            <Box sx={{ mb: 8 }}>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.subtitle,
                  ...{ mb: 4 },
                }}
              >
                {component.ucfirst("product-page-subtitle-2")}
              </Typography>
              <Typography
                component="h3"
                sx={{
                  ...theme.custom.titles.description,
                  ...{},
                }}
              >
                <Translate
                  entity="item"
                  eslug={slug}
                  scope="default"
                  slug="ingredients"
                  auto
                />
              </Typography>
              <Typography
                component="h3"
                sx={{
                  ...theme.custom.titles.description,
                  ...{
                    borderTop: "1px solid #FF6F4D",
                    pt: 2,
                    mt: 2,
                    fontSize: 16,
                  },
                }}
              >
                {component.ucfirst("product-extra-info-1")}
              </Typography>
            </Box>
            {/*   how to prepare */}
            <Box sx={{}}>
              <Typography
                component="h2"
                sx={{
                  ...theme.custom.titles.subtitle,
                  ...{ mb: 4 },
                }}
              >
                {component.ucfirst("product-page-subtitle-3")}
              </Typography>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
                columnSpacing={{ xs: 1, md: 2 }}
                rowSpacing={{ xs: 4, md: 4 }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      "& > i": {
                        fontSize: 100,
                        color: "#FF6F4D",
                      },
                    }}
                  >
                    <i className="fa-duotone fa-mitten" />
                  </Box>
                  <Typography
                    component="h2"
                    align="center"
                    sx={{
                      ...theme.custom.titles.subtitle,
                      ...{ mb: 2 },
                    }}
                  >
                    {component.ucfirst("meal-details-subtitle-2")}
                  </Typography>
                  <Typography
                    component="h3"
                    align="center"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{},
                    }}
                  >
                    {component.ucfirst("meal-details-text-2")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      mb: 1,
                      "& > i": {
                        fontSize: 100,
                        color: "#FF6F4D",
                      },
                    }}
                  >
                    <i className="fa-duotone fa-microwave" />
                  </Box>
                  <Typography
                    component="h2"
                    align="center"
                    sx={{
                      ...theme.custom.titles.subtitle,
                      ...{ mb: 2 },
                    }}
                  >
                    {component.ucfirst("meal-details-subtitle-1")}
                  </Typography>
                  <Typography
                    component="h3"
                    align="center"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{},
                    }}
                  >
                    {component.ucfirst("meal-details-text-1")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="h2"
                    align="center"
                    sx={{
                      ...theme.custom.titles.subtitle,
                      ...{ mb: 2 },
                    }}
                  >
                    {component.ucfirst("meal-details-subtitle-3")}
                  </Typography>
                  <Typography
                    component="h3"
                    align="center"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{ mb: 1 },
                    }}
                  >
                    {component.ucfirst("meal-details-text-3")}
                  </Typography>
                  <Typography
                    component="h2"
                    align="center"
                    sx={{
                      ...theme.custom.titles.subtitle,
                      ...{ mb: 2 },
                    }}
                  >
                    {component.ucfirst("meal-details-subtitle-4")}
                  </Typography>
                  <Typography
                    component="h3"
                    align="center"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{ mb: 1 },
                    }}
                  >
                    {component.ucfirst("meal-details-text-4")}
                  </Typography>
                  <Typography
                    component="h3"
                    align="center"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{ mb: 2 },
                    }}
                  >
                    {component.ucfirst("meal-details-text-5")}
                  </Typography>
                  <Typography
                    component="h3"
                    align="center"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{ mb: 2, fontWeight: 600 },
                    }}
                  >
                    {component.ucfirst("meal-details-text-6")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
