import Page from "../../Page";
import Services from "../../../Services/Services";
import templateDefault from "../../../templates/default/pages/epay/results";

export default class EpayResult extends Page {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    if (this.getQuery("StatusFlag") === "Success") {
      this.fetchOrder();
    } else {
      this.redirect("/checkout?epayerror=1");
    }
  }

  fetchOrder() {
    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.fetchOrder();
        await orderService.verifyPayment(this.getPage().getQuery());

        if (orderService.getData("order.placed")) {
          try {
            this.getApp().sendToGA4Order(orderService.getData("order"));
          } catch (e) {
            //
          }

          orderService.delete();
          this.redirect("/thank-you-for-your-order");
        }
      } catch (e) {
        //
      }
    });
  }
}
