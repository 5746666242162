import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const dummyData = [
  { total: 3, xs: 12, md: 4 },
  { total: 3, xs: 12, md: 6 },
  { total: 4, xs: 6, md: 3 },
  { total: 2, xs: 6, md: 6 },
];

export default function Plans(props) {
  const theme = useTheme();
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const stringHelper = component.getHelpers("string");

  const [chefChoice, setChefChoice] = React.useState(false);
  const [selectedDay, setDay] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const {
    plan,
    selectedPlan,
    weekly_prices = {},
    times = [],
    weekly_delivery_days = [],
    selectedTime = false,
  } = component.getData("default", {});

  //css

  const my_title = {
    fontSize: 19,
    color: "#000",
    mb: 2.5,
    fontWeight: 500,
    lineHeight: 1,
  };

  const disc = {
    color: "#fff",
    bgcolor: "#800080",
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 30,
    p: "2px 16px",
    "& i": {
      mx: 0.1,
      color: "#fff",
      fontSize: { xs: 12, md: 14 },
    },
  };

  const my_btn = {
    height: 50,
    fontSize: { xs: 16, md: 18 },
    background: "#fff",
    color: "#000",
    textTransform: "none",
    width: "100%",
    borderRadius: "10px",
    "&:hover,&:focus": {
      bgcolor: "#fff",
    },
  };

  const my_cont_grid = {
    mb: 4,
  };

  const myInfo = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    mx: { xs: 1.5, sm: 1 },
    "& i": {
      mr: 0.5,
    },
    "& span": {
      display: { xs: "flex", sm: "unset" },
    },
    "& span:first-of-type": {
      mr: { xs: 0, sm: 0.5 },
    },
    "& div": {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,
    },
  };

  const handleDayChange = (index) => {
    setDay(index);
    component.setData({
      "default.selectedTime": false,
    });
  };

  return (
    <Container disableGutters sx={{ px: 1 }}>
      <Grid container sx={{ mt: 3, mb: 5, justifyContent: "center" }}>
        <Grid item xs={12} sm={7.5}>
          <Divider
            sx={{
              "&::before, &::after": {
                borderColor: "#800080",
                borderWidth: "2px",
              },
            }}
          >
            <Box>
              <Typography
                component="h1"
                sx={{
                  ...my_title,
                  fontSize: 24,
                  mb: 0,
                  lineHeight: 1.2,
                }}
              >
                {component.ucfirst("weekly")}
              </Typography>
              <Typography
                align="center"
                component="h2"
                sx={{
                  ...my_title,
                  fontSize: 24,
                  mb: 0,
                  lineHeight: 1.2,
                }}
              >
                {component.trans("meal-packs")}
              </Typography>
            </Box>
          </Divider>
          <Typography
            align="center"
            component="h2"
            variant="body1"
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            {component.trans("weekly-plans-desc")}
          </Typography>
          {plan ? (
            <>
              <Paper elevation={0} sx={{ p: 3, borderRadius: "10px" }}>
                {/* weekly_plans */}
                <Typography component="h2" sx={{ ...my_title }}>
                  {component.ucfirst("weekly-title-1")}
                </Typography>
                <Grid container spacing={1} sx={{ ...my_cont_grid }}>
                  {devHelper
                    .getObjectValue(plan, "weekly_days")
                    .split(",")
                    .map((value, i) => (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        key={`weekly_days-${value}-${i}`}
                      >
                        <Button
                          sx={{
                            ...my_btn,
                            border:
                              selectedPlan.split("-")[0] === value
                                ? "3px solid #FF6F4D"
                                : "1px solid #dedede",
                            "&:hover": {
                              border:
                                selectedPlan.split("-")[0] === value
                                  ? "3px solid #FF6F4D"
                                  : "1px solid #FF6F4D",
                            },
                          }}
                          onClick={() => {
                            // if (
                            //   selectedPlan.split("-")[2] === "1" &&
                            //   value === "5"
                            // ) {
                            //   component.changePlan(
                            //     `${selectedPlan.split("-")[0]}-${
                            //       selectedPlan.split("-")[1]
                            //     }-2`,
                            //     value,
                            //     0
                            //   );
                            // } else {
                            //   component.changePlan(selectedPlan, value, 0);
                            // }
                            component.changePlan(selectedPlan, value, 0);
                          }}
                          disableRipple
                        >
                          {`${value} ${component.trans("days")}`}
                        </Button>
                      </Grid>
                    ))}
                </Grid>
                {/* weeks */}
                <Typography component="h2" sx={{ ...my_title }}>
                  {component.ucfirst("weekly-title-2")}
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  sx={{ ...my_cont_grid, justifyContent: "center" }}
                >
                  {devHelper
                    .getObjectValue(plan, "weekly_weeks")
                    .split(",")
                    .map((value, i) => (
                      <Grid
                        item
                        xs={12}
                        md={value === "4" ? 12 : 6}
                        key={`weekly_weeks-${value}-${i}`}
                        sx={{ position: "relative" }}
                      >
                        <Button
                          sx={{
                            ...my_btn,
                            justifyContent: {
                              xs: "flex-start",
                              md: "center",
                            },
                            px: { xs: 3, md: 1 },
                            border:
                              selectedPlan.split("-")[1] === value
                                ? "3px solid #FF6F4D"
                                : "1px solid #dedede",
                            "&:hover": {
                              border:
                                selectedPlan.split("-")[1] === value
                                  ? "3px solid #FF6F4D"
                                  : "1px solid #FF6F4D",
                            },
                          }}
                          onClick={() =>
                            component.changePlan(selectedPlan, value, 1)
                          }
                          disableRipple
                        >
                          {`${value} ${component.trans(
                            value === "1" ? "week" : "many-weeks"
                          )}`}
                          {value === "4" && ` / 1 ${component.trans("month")}`}
                        </Button>
                        {(() => {
                          const [planType, , planDuration] =
                            selectedPlan.split("-");
                          const discountKey = Object.keys(weekly_prices).find(
                            (key) => {
                              const [keyType, keyValue, keyDuration] =
                                key.split("-");
                              return (
                                keyType === planType &&
                                keyValue === value &&
                                keyDuration === planDuration
                              );
                            }
                          );

                          // Check if discountKey exists and discount is not 0
                          if (
                            !discountKey ||
                            weekly_prices[discountKey].discount === 0
                          )
                            return null;

                          const discount = weekly_prices[discountKey].discount;

                          return (
                            <Typography
                              sx={{
                                ...disc,
                                position: "absolute",
                                top: { xs: 19, md: 4 },
                                right: { xs: 14, sm: 24, md: -5 },
                              }}
                            >
                              <i
                                className="fa-solid fa-minus"
                                style={{ fontSize: 10 }}
                              />
                              {String(discount)
                                .split("")
                                .map((num, index) => (
                                  <i
                                    key={index}
                                    className={`fa-solid fa-${num}`}
                                  />
                                ))}
                              <i className="fa-solid fa-percent" />
                            </Typography>
                          );
                        })()}
                      </Grid>
                    ))}
                </Grid>
                {/* daily_meals */}
                <Typography component="h2" sx={{ ...my_title }}>
                  {component.ucfirst("weekly-title-3")}
                </Typography>
                <Grid container spacing={1} sx={{ ...my_cont_grid }}>
                  {devHelper
                    .getObjectValue(plan, "weekly_meals")
                    .split(",")
                    .map((value, i) => (
                      <Grid
                        item
                        xs={6}
                        md={3}
                        key={`weekly_meals-${value}-${i}`}
                        // onClick={() =>
                        //   selectedPlan.split("-")[0] === "5" &&
                        //   value === "1" &&
                        //   component
                        //     .getComponents()
                        //     .findById("main-message")
                        //     .first()
                        //     .setData({
                        //       message: {
                        //         text: component.trans(
                        //           "one-meal-per-day-message"
                        //         ),
                        //         type: "info",
                        //       },
                        //     })
                        // }
                      >
                        <Button
                          sx={{
                            ...my_btn,
                            border:
                              selectedPlan.split("-")[2] === value
                                ? "3px solid #FF6F4D"
                                : "1px solid #dedede",
                            "&:hover": {
                              border:
                                selectedPlan.split("-")[2] === value
                                  ? "3px solid #FF6F4D"
                                  : "1px solid #FF6F4D",
                            },
                          }}
                          onClick={() =>
                            component.changePlan(selectedPlan, value, 2)
                          }
                          disableRipple
                          // disabled={
                          //   selectedPlan.split("-")[0] === "5" && value === "1"
                          // }
                        >
                          {`${value} ${component.trans(
                            value === "1" ? "meal" : "meals"
                          )}`}
                        </Button>
                      </Grid>
                    ))}
                </Grid>
                {/* who will pick */}
                <Typography component="h2" sx={{ ...my_title, mb: 0.5 }}>
                  {component.ucfirst("weekly-title-4")}
                </Typography>
                <Typography
                  component="h2"
                  sx={{
                    ...my_title,
                    fontSize: 14,
                    opacity: 0.7,
                    fontWeight: 400,
                  }}
                >
                  {component.ucfirst("weekly-subtitle-4")}
                </Typography>
                <Grid container spacing={1} sx={{ ...my_cont_grid }}>
                  <Grid item xs={6}>
                    <Button
                      sx={{
                        ...my_btn,
                        border: !chefChoice
                          ? "3px solid #FF6F4D"
                          : "1px solid #dedede",
                        "&:hover": {
                          border: !chefChoice
                            ? "3px solid #FF6F4D"
                            : "1px solid #FF6F4D",
                        },
                      }}
                      onClick={() => setChefChoice(false)}
                      disableRipple
                      startIcon={<i className="fa-regular fa-face-smile" />}
                    >
                      {component.ucfirst("i-will-pick")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      sx={{
                        ...my_btn,
                        border: chefChoice
                          ? "3px solid #FF6F4D"
                          : "1px solid #dedede",
                        "&:hover": {
                          border: chefChoice
                            ? "3px solid #FF6F4D"
                            : "1px solid #FF6F4D",
                        },
                      }}
                      onClick={() => setChefChoice(true)}
                      disableRipple
                      startIcon={<i className="fa-light fa-user-chef" />}
                    >
                      {component.ucfirst("chef-selected")}
                    </Button>
                  </Grid>
                </Grid>
                {/* delivery_days */}
                <Typography component="h2" sx={{ ...my_title }}>
                  {component.ucfirst("weekly-title-5")}
                </Typography>
                {weekly_delivery_days.map((daysCombo, index) => {
                  daysCombo = daysCombo.trim();
                  const days = daysCombo.split(",");
                  return (
                    <Accordion
                      key={daysCombo}
                      expanded={selectedDay === index}
                      onChange={() => handleDayChange(index)}
                      disableGutters
                      elevation={0}
                      square
                      sx={{
                        border:
                          selectedDay === index
                            ? "2px solid #FF6F4D"
                            : "2px solid #dedede",

                        zIndex: selectedDay === index ? 3 : 1,
                        mt: "-2px",
                        transition: "all .3s ease-in-out",
                        webkitTransition: "all .3s ease-in-out",
                        mx: "5px",
                        "&:before": {
                          display: "none",
                        },
                        "&:first-of-type": {
                          mt: 0,
                        },
                        "&:hover": {
                          zIndex: 2,
                          ml: selectedDay === index ? "5px" : "15px",
                          mr: selectedDay === index ? "5px" : 0,
                        },
                      }}
                    >
                      <AccordionSummary sx={{ height: 50 }}>
                        <Typography
                          component="h1"
                          align="center"
                          sx={{
                            width: "100%",
                            fontSize: "20px",
                            fontWeight: 500,
                            conor: "#000",
                          }}
                        >
                          {days.map((day, i) => {
                            if (days.length === i + 1) {
                              return component.ucfirst(
                                stringHelper.capitalize(day)
                              );
                            } else {
                              return `${component.ucfirst(
                                stringHelper.capitalize(day)
                              )} & `;
                            }
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ pb: 2 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {times
                            .filter((time) => time.days === daysCombo)
                            .map((time, i) => {
                              const { slug, name } = devHelper.getObjectValue(
                                time,
                                "item._values"
                              );
                              return (
                                <Grid item xs={6} md={6} key={`${slug}-${i}}`}>
                                  <Button
                                    sx={{
                                      ...my_btn,
                                      border:
                                        selectedTime === slug
                                          ? "3px solid #FF6F4D"
                                          : "1px solid #dedede",
                                      "&:hover": {
                                        border:
                                          selectedTime === slug
                                            ? "3px solid #FF6F4D"
                                            : "1px solid #FF6F4D",
                                      },
                                    }}
                                    onClick={() =>
                                      component.setData({
                                        "default.selectedTime": slug,
                                      })
                                    }
                                    disableRipple
                                  >
                                    {name}
                                  </Button>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  py: 4,
                  px: 3,
                  borderRadius: "10px",
                  my: 2,
                  maxWidth: 500,
                  mx: "auto",
                  position: "relative",
                }}
              >
                {devHelper.getObjectValue(
                  weekly_prices[selectedPlan],
                  "discount",
                  0
                ) > 0 && (
                  <Typography
                    sx={{
                      ...disc,
                      ml: 2,
                      display: "inline-block",
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }}
                  >
                    <i className="fa-solid fa-minus" style={{ fontSize: 10 }} />
                    {Array.from(
                      String(weekly_prices[selectedPlan].discount),
                      Number
                    ).map((num, ind) => {
                      return <i key={ind} className={`fa-solid fa-${num}`} />;
                    })}
                    <i className="fa-solid fa-percent" />
                  </Typography>
                )}
                <Stack spacing={3} sx={{ alignItems: "center" }}>
                  <Box
                    sx={{
                      bgcolor: "#fff",
                      py: 1,
                      width: 260,
                      mx: "auto",
                    }}
                  >
                    <Typography
                      align="center"
                      component="h1"
                      sx={{
                        fontSize: 18,
                        color: "#000",
                        fontWeight: 500,
                        lineHeight: 1,
                      }}
                    >
                      {selectedPlan.split("-")[0] * selectedPlan.split("-")[2]}
                      {` ${component.trans("meals")} / ${component.trans(
                        "week"
                      )}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        border: "2px solid #800080",
                        borderRadius: 2,
                        p: 1.5,
                        px: 8,
                      }}
                    >
                      {weekly_prices[selectedPlan].special_price !==
                        weekly_prices[selectedPlan].price && (
                        <Typography
                          align="center"
                          component="h1"
                          sx={{
                            fontSize: 20,
                            fontWeight: 500,
                            lineHeight: 1,
                            mb: 0.5,
                          }}
                        >
                          <strike>
                            {component
                              .getApp()
                              .priceFormater(
                                weekly_prices[selectedPlan].price /
                                  selectedPlan.split("-")[1]
                              )}
                          </strike>
                        </Typography>
                      )}
                      <Typography
                        align="center"
                        component="h1"
                        sx={{
                          fontSize: 28,
                          fontWeight: 600,
                          lineHeight: 1,
                        }}
                      >
                        {component
                          .getApp()
                          .priceFormater(
                            weekly_prices[selectedPlan].special_price /
                              selectedPlan.split("-")[1]
                          )}
                        <Box
                          component="span"
                          sx={{
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {` / ${component.trans("week")}`}
                        </Box>
                      </Typography>
                    </Box>
                    <Typography
                      align="center"
                      component="h4"
                      variant="subtitle1"
                      sx={{
                        mt: 1.5,
                        lineHeight: 1,
                        "& span": {
                          fontWeight: 700,
                        },
                      }}
                    >
                      {`${component.ucfirst("price-per-meal")}: `}
                      <span>
                        {component
                          .getApp()
                          .priceFormater(
                            weekly_prices[selectedPlan].special_price /
                              (selectedPlan.split("-")[0] *
                                selectedPlan.split("-")[1] *
                                selectedPlan.split("-")[2])
                          )}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <LoadingButton
                      sx={{
                        ...theme.custom.buttons.submit_btn,
                        mt: 0,
                        width: "100%",
                        "&:disabled": {
                          visibility: loading ? "visible" : "hidden",
                          bgcolor: "#FF6F4D",
                          color: loading ? "#FF6F4D" : "#fff",
                        },
                      }}
                      loadingIndicator={
                        <CircularProgress size={30} sx={{ color: "#fff" }} />
                      }
                      disableRipple
                      disabled={
                        selectedDay === false ||
                        selectedTime === false ||
                        loading
                      }
                      loading={loading}
                      onClick={() => {
                        component.updatePlan(
                          selectedPlan,
                          weekly_delivery_days[selectedDay].trim(),
                          times,
                          selectedTime,
                          chefChoice
                        );
                        setLoading(true);
                      }}
                    >
                      <span>{component.ucfirst("continue")}</span>
                    </LoadingButton>
                    <Box sx={{ mt: 1, textAlign: "center" }}>
                      <Box
                        sx={{
                          ...myInfo,
                        }}
                      >
                        <i className="fa-regular fa-leaf" />
                        <Box>
                          <span>{component.trans("organic")}</span>
                          <span>{component.trans("ingredients")}</span>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...myInfo,
                        }}
                      >
                        <i className="fa-regular fa-scale-balanced" />
                        <Box>
                          <span>Macro</span>
                          <span>Balanced</span>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...myInfo,
                        }}
                      >
                        <i className="fa-regular fa-dna" />
                        <Box>
                          <span>Premium</span>
                          <span>{component.trans("proteins")}</span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Paper>
            </>
          ) : (
            <>
              <Paper elevation={0} sx={{ p: 3, borderRadius: "10px" }}>
                {dummyData.map((item, index) => {
                  return (
                    <Box key={`skeleton-box-${index}`}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ ...my_title }}
                        width={250}
                        height={19}
                      />
                      <Grid
                        container
                        spacing={{ xs: 1, md: index === 1 ? 2 : 1 }}
                        sx={{ ...my_cont_grid, justifyContent: "center" }}
                      >
                        {[...new Array(item.total)].map((item1, i) => (
                          <Grid
                            item
                            xs={item.xs}
                            md={index === 1 && i === 2 ? 12 : item.md}
                            key={`skeleton-grid-${index}-${i}`}
                          >
                            <Skeleton
                              variant="rounded"
                              height={50}
                              sx={{ borderRadius: "10px" }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  );
                })}
                <Skeleton
                  variant="rectangular"
                  sx={{ ...my_title }}
                  width={250}
                  height={18}
                />
                <Skeleton variant="rectangular" height={182} />
              </Paper>
              <Paper
                elevation={3}
                sx={{
                  py: 4,
                  px: 3,
                  borderRadius: "10px",
                  my: 2,
                  maxWidth: 500,
                  mx: "auto",
                }}
              >
                <Skeleton variant="rounded" height={322} />
              </Paper>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
