import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/steps/menu";
import Services from "../../../Services/Services";

export default class Menu extends Component {
  id = "menu";
  group = "order";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.getMenu();
  }

  getMenu() {
    Services.get("order,hook").then(async ([orderService, hookService]) => {
      var order = null;
      const devHelper = this.getHelpers("dev");

      try {
        await orderService.fetchOrder();
        order = orderService.getData("order");
      } catch (e) {}
      try {
        // const menu_categories = devHelper.getObjectValue(
        //   order,
        //   "date.categories",
        //   []
        // );

        const menu = devHelper.getObjectValue(order, "date.menu");

        const hookService1 = await hookService.exec("category-meals", {
          params: {
            categorySlug: menu ? menu : "default",
          },
        });

        const all_meals = hookService1.getData();

        const hookService2 = await hookService.exec("meal-categories", {
          params: {},
        });
        const categories = hookService2.getData() || [];

        const meals = [];
        const extra = [];
        var type = null;

        all_meals.forEach((meal) => {
          if (meal.item._values.meal_types.length) {
            type = meal.item._values.meal_types[0].item._values.slug;
            switch (type) {
              case "deal": {
                meals.push(meal);
                break;
              }
              case "extra": {
                extra.push(meal);
                break;
              }
              default:
                break;
            }
          }
        });

        this.setData({
          "default.categories": categories,
          "default.order": order,
          "default.meals": meals,
          "default.extra": extra,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  updateCart(meal, amount) {
    Services.get("order").then(async ([orderService]) => {
      try {
        const { _id } = meal.item;
        var order = orderService.getOrder();

        const cart_amount = this.getProductAmount(_id, order);
        amount = cart_amount + amount;

        await orderService.updateItem({
          itemId: _id,
          itemType: "meal",
          amount: amount,
        });

        order = orderService.getOrder();

        const { plan } = order;

        if (plan.changed) {
          const { min, total, price } = plan;

          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: `${this.ucfirst("meal-pack-changed")} ${min}${
                  total - min > 0 ? "+" : ""
                } ${this.trans("with-price")} ${this.getApp().priceFormater(
                  price
                )} / ${this.trans("meal")}`,
                type: "info",
                duration: 2000,
              },
            });
        }

        if (amount > cart_amount) {
          this.getApp().sendToGA4({ ...meal }, "add_to_cart", "item._values");
        }

        this.getComponents()
          .findByGroup("order")
          .forEach((comp) => {
            comp.setData({ "default.order": order });
          });

        this.getComponents()
          .findById("cart-drawer")
          .first()
          .processOrder(order);

        this.setData({
          "default.amount": amount,
        });
      } catch (orderService) {
        this.checkOrderError(orderService);
      }
    });
  }

  getProductAmount(id, order) {
    if (order) {
      const { items = [] } = order;

      const { amount = 0 } =
        items.find((orderItem) => {
          return id === orderItem.item.id;
        }) || {};

      return amount;
    }
    return 0;
  }

  getCategorysMeals(cat_slug, meals) {
    return meals
      .filter(
        (meal) =>
          meal.item._values.meal_categories.findIndex(
            (cat) => cat.item._values.slug === cat_slug
          ) !== -1
      )
      .sort((a, b) => {
        const mealA =
          a.item._values.meal_categories.find(
            (cat) => cat.item._values.slug === cat_slug
          ) || {};

        const positionA = mealA.position || 1000;

        const mealB =
          b.item._values.meal_categories.find(
            (cat) => cat.item._values.slug === cat_slug
          ) || {};

        const positionB = mealB.position || 1000;

        return positionA - positionB;
      });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          this.getPage().redirect("/meal-packs");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
