import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/register";
import Services from "../../Services/Services";

export default class Register extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loadFB({ window, document, page: this.getPage() });
    });

    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loadGoogle({
        window,
        document,
        page: this.getPage(),
        cb: async () => await this.createAddress(),
      });
    });
  }

  signinFB() {
    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loginFB({
        window,
        document,
        page: this.getPage(),
        cb: async () => await this.createAddress(),
      });
    });
  }

  // signIn(e) {
  register(e) {
    e.preventDefault();

    const company = this.getPage().getParam("company");
    const special = this.getPage().getQuery("special");

    const { name, username, password, verify } = this.getData(
      "default.fields",
      {}
    );
    const error = {
      fields: {},
      message: "",
    };

    if (!name) {
      error.fields["name"] = this.ucfirst("fullname-helpertext");
    }
    if (!username) {
      error.fields["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username)) {
        error.fields["username"] = this.ucfirst("invalid-email");
      }
    }

    if (!password) {
      error.fields["password"] = this.ucfirst("password-helpertext");
    }

    if (!verify) {
      error.fields["verify"] = this.ucfirst("confirm-password-helpertext");
    }

    if (password && verify && password !== verify) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          message: {
            text: this.ucfirst("invalid-password-verification"),
            type: "error",
          },
        });
      error.message = this.ucfirst("invalid-password-verification");
    }

    this.setData({
      error,
    });

    if (Object.keys(error.fields).length === 0 && !error.message) {
      Services.get("auth,order,address").then(async ([authService]) => {
        try {
          await authService.register({
            name,
            username,
            password,
            verify,
            company,
            special,
          });
          this.getHelpers("Auth").set(
            authService.getData("_response").getData()
          );
          this.getPage().login();

          await this.createAddress();

          if (!company) {
            this.getComponents()
              .findById("login-register-dialog")
              .first()
              .setData({
                "default.open": false,
              });
          } else {
            this.getPage().redirect("/meal-packs");
          }

          this.getComponents()
            .findById("login-register-dialog")
            .first()
            .setData({
              "default.open": false,
            });
        } catch (service) {
          this.checkOrderError(service);
        }
      });
    }
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("login-register-dialog")
            .first()
            .setData({
              "default.open": false,
            });
          break;
        }
        default:
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          break;
      }
    }

    return this;
  }

  async createAddress() {
    const [orderService, addressService] = await Services.get("order,address");
    const devHelper = this.getHelpers("dev");

    try {
      await orderService.fetchOrder();
      let order = orderService.getData("order");

      if (order) {
        var address = devHelper.getObjectValue(order, "shipping_address", {});

        if (address && !address._id) {
          await addressService.createAddress(address);

          address = addressService.getData("new_address");

          await orderService.updateAddress({
            shippingAddressId: address._id,
            billingAddressId: address._id,
          });

          order = orderService.getData("order");

          this.getComponents()
            .findByGroup("order")
            .forEach((comp) => {
              comp.setData({ "default.order": order });
            });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}
