import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/bottom_cart";
import Services from "../../Services/Services";

export default class BottomCart extends Component {
  id = "main-appbar";
  group = "order";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getOrder();
  }

  getOrder() {
    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.fetchOrder();
        const order = orderService.getOrder();
        this.setData({
          "default.order": order,
        });
      } catch (service) {}
      this.setData({
        "default.fade": true,
      });
    });
  }
}
