import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Container, Box, useMediaQuery } from "@mui/material";
import PlanDesktop from "./plan_desktop";
import PlanMobile from "./plan_mobile";

export default function Plans(props) {
  const theme = useTheme();
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const plans = component.getData("default.plans", []);
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {plans.length !== 0 && (
        <Box
          sx={{
            pt: 3,
            pb: { xs: "60px", md: 6 },
          }}
          id="all-plans"
        >
          <Container maxWidth="xl" disableGutters sx={{ px: { xs: 1, md: 2 } }}>
            <Typography
              component="h1"
              sx={{
                ...theme.custom.titles.title,
              }}
            >
              {component.ucfirst("home-plans-title")}
            </Typography>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: "center",
                alignItems: "stretch",
                mt: { xs: 0, sm: 4 },
              }}
              spacing={{ xs: 3, sm: 1 }}
            >
              {plans.map((plan, index) => {
                const { slug } = devHelper.getObjectValue(plan, "_values");

                return (
                  <Grid item xs={12} sm={6} lg={3} key={slug}>
                    {desktop ? (
                      <PlanDesktop
                        {...{
                          ...props,
                          ...{
                            plan,
                            index,
                          },
                        }}
                      />
                    ) : (
                      <PlanMobile
                        {...{
                          ...props,
                          ...{
                            plan,
                            index,
                          },
                        }}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
