import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/checkout/checkout";
import Services from "../../Services/Services";

export default class Checkout extends Component {
  template = templateDefault;

  group = "order";

  default_address = {
    street: "",
    street_number: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
    lat: "",
    lng: "",
    bell: "",
    floor: "",
    notes: "",
  };

  default_business = {
    company_name: "",
    profession: "",
    address: "",
    tax_office: "",
    tax_number: "",
  };

  onLoad(data) {
    super.onLoad(data);

    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setOrder(order);
          const {
            shipping_address = { ...this.default_address },
            business = { ...this.default_business },
          } = order;

          const devHelper = this.getHelpers("dev");

          const spotSlug = devHelper.getObjectValue(order, "spot.slug", "");
          this.setData({
            "default.address": shipping_address,
            "default.business": business,
            "default.invoice": false,
          });

          this.retrieveCoupons();
          this.retrievePaymentTypes(spotSlug);
          this.verifyPayment();
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  setOrder(order) {
    const cultery = order.services.find(
      (service) => service.slug === "cutlery"
    );

    if (!order.business) {
      order.business = { ...this.default_business };
    }

    this.processOrder(order);

    this.setData({
      "default.cutlery": cultery,
    });

    return this;
  }

  processOrder(order) {
    const meals = [];
    const extra = [];
    var type = null;
    const devHelper = this.getHelpers("dev");
    let weekMenus = {};
    let dates = [];

    if (devHelper.getObjectValue(order, "plan.weekly")) {
      const items = devHelper.getObjectValue(order, "items", []);

      items.forEach((meal) => {
        dates.push(meal.date);
      });

      dates
        .filter((item, index) => dates.indexOf(item) === index)
        .sort()
        .forEach((date) => {
          let week_index = this.getWeekNumber(date);

          if (!weekMenus.hasOwnProperty(`week-${week_index}`)) {
            weekMenus[`week-${week_index}`] = [];
          }

          let dates_meals = items.filter((meal) => meal.date === date);

          weekMenus[`week-${week_index}`].push({
            date,
            dates_total: this.getDatesTotal(date, dates_meals),
            meals: dates_meals,
          });
        });
    } else {
      devHelper.getObjectValue(order, "items", []).forEach((meal) => {
        if (meal.item.meal_types.length) {
          type = meal.item.meal_types[0].item._values.slug;
          switch (type) {
            case "deal": {
              meals.push(meal);
              break;
            }
            case "extra": {
              extra.push(meal);
              break;
            }
            default:
              break;
          }
        }
      });
    }

    this.setData({
      "default.order": order,
      "default.meals": meals,
      "default.extra": extra,
      "default.menus": weekMenus,
    });

    return this;
  }

  getWeekNumber(date) {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(
      currentDate.getDate() + 3 - ((currentDate.getDay() + 6) % 7)
    );
    const yearStart = new Date(currentDate.getFullYear(), 0, 4);
    return 1 + Math.floor((currentDate - yearStart) / 604800000); // 604800000 = 7 * 24 * 3600 * 1000
  }

  getDatesTotal(date, meals) {
    if (meals) {
      let total = 0;
      meals
        .filter((orderItem) => {
          return date === orderItem.date;
        })
        .forEach((orderItem) => (total += orderItem.amount));

      return total;
    }
    return 0;
  }

  /* coupons section */

  retrieveCoupons() {
    Services.get("coupon").then(([couponService]) => {
      couponService
        .getCoupons()
        .then((couponService) => {
          const coupons = couponService.getData("coupons", []);

          this.setData({
            "default.coupons": coupons,
          });
          return this;
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    return this;
  }

  activateCoupon(couponId) {
    Services.get("order").then(([orderService]) => {
      orderService
        .updateCoupon({ couponId })
        .then((orderService) => {
          const order = orderService.getData("order");
          this.retrieveCoupons();
          this.setData({
            "default.couponCode": "",
          });
          this.setOrder(order);
          this.updateCart();
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  updateCouponByCode(e) {
    e.preventDefault();

    const { guest, coupons = [] } = this.getData("default");

    var error = {};

    let { couponCode = "" } = this.getData("default");

    if (couponCode === "") {
      error.couponCode = this.ucfirst("promo-code-helpertext");
    } else {
      couponCode = couponCode.toLowerCase();
    }

    this.setData({
      error,
    });
    if (Object.keys(error).length === 0) {
      if (guest) {
        Services.get("order").then(async ([orderService]) => {
          orderService
            .updateCoupon({
              couponCode,
            })
            .then((orderService) => {
              const order = orderService.getData("order");
              this.setData({
                "default.couponCode": "",
              });
              this.setOrder(order);
            })
            .catch((orderService) => {
              this.setData({
                "error.couponCode": this.ucfirst(orderService.getMessage()),
              });
            });
        });
      } else {
        const existingCoupon = coupons.find(
          (coupon) => coupon.slug === couponCode
        );
        if (!existingCoupon) {
          Services.get("order,coupon").then(([orderService, couponService]) => {
            couponService
              .addCoupon(couponCode)
              .then((couponService) => {
                const couponId = couponService.getData("coupon");
                this.retrieveCoupons();
                return orderService.updateCoupon({
                  couponId,
                });
              })
              .then((orderService) => {
                const order = orderService.getData("order");
                this.setData({
                  "default.couponCode": "",
                });
                this.setOrder(order);
                this.updateCart();
              })
              .catch((err) => {
                if (
                  couponService.getData("_response").getMessage() === "error"
                ) {
                  this.getComponents()
                    .findById("main-message")
                    .first()
                    .setData({
                      message: {
                        text: this.trans("coupon-unavailable-message"),
                        type: "error",
                      },
                    });
                } else {
                  console.log("error", err);
                }
              });
          });
        } else {
          this.activateCoupon(existingCoupon._id);
        }
      }
    }

    return this;
  }

  releaseCoupon() {
    Services.get("order").then(async ([orderService]) => {
      orderService
        .releaseCoupon()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.retrieveCoupons();
          this.setData({ "default.order": order });
          this.updateCart();
        })
        .catch((orderService) => {});
    });
  }

  updateCart() {
    const order = this.getData("default.order");

    if (order) {
      this.getComponents().findById("cart-drawer").first().processOrder(order);
    }

    return this;
  }

  /* payment types section */

  retrievePaymentTypes(slug) {
    Services.get("hook").then(([service]) => {
      service
        .exec("payment-types", {
          params: { spot: slug },
        })
        .then((response) => {
          const paymentTypes = response.getData() || [];

          this.setData({
            "default.paymentTypes": paymentTypes,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });

    return this;
  }

  updatePaymentType(id) {
    const { order } = this.getData("default", {}) || {};
    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updatePaymentType({
            paymentTypeId: id,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setData({ "default.order": order });
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }
  }

  findPaymentTypeSelected() {
    return this.getData("default.order.paymentType.id", "");
  }

  /* services section */

  updateService(id, amount) {
    const { order } = this.getData("default", {}) || {};

    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updateService({
            serviceId: id,
            amount,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setOrder(order);
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }
  }

  /* complete order section */

  complete(e) {
    e.preventDefault();

    const {
      address = {
        ...this.default_address,
      },
      business = {
        ...this.default_business,
      },
      invoice = false,
    } = this.getData("default", {});

    const valid_address = !this.validateAddress(address);
    const valid_business = invoice ? !this.validateBusiness(business) : true;

    if (valid_address && valid_business) {
      this.setData({ "default.order.shipping_address": address });

      Services.get("order,address").then(
        async ([orderService, addressService]) => {
          try {
            await addressService.updateAddress(address._id, address);
            await orderService.updateAddress({
              shippingAddressId: address._id,
              billingAddressId: address._id,
            });

            await orderService.prepare({
              invoice,
              business: invoice ? business : null,
            });
            const { slug = "" } = orderService.getData("order.paymentType", {});

            switch (slug) {
              case "epay-credit": {
                this.completeEpay();
                break;
              }
              default: {
                this.completeDefault();
              }
            }
          } catch (orderService) {
            this.checkOrderError(orderService);
          }
        }
      );
    } else {
      this.getPage().scrollToTop();
    }
  }

  completeDefault() {
    this.setData({ "default.preparing": true });
    Services.get("order").then(async ([orderService]) => {
      this.getApp().sendToGA4Order(orderService.getData("order"));
      await orderService.place();
      const order = orderService.getData("order");

      this.getComponents()
        .findByGroup("order")
        .forEach((comp) => {
          comp.setData({ "default.order": order });
        });

      this.setData({ "default.preparing": false });
      this.getPage().redirect("/thank-you-for-your-order");
    });

    return this;
  }

  completeEpay() {
    this.setData({ "default.preparing": true });

    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.preparePayment();

        const { creditData } =
          orderService.getData("order.paymentType", {}) || {};

        if (creditData) {
          this.epayRedirect(creditData);
        }
      } catch (e) {
        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            message: {
              text: this.trans(e.getMessage()),
              type: "error",
            },
          });
      }

      this.setData({ "default.preparing": false });
    });

    return this;
  }

  epayRedirect(data) {
    for (let i in data) {
      const element = document.querySelector(`[name="${i}"]`);

      if (element) {
        element.value = data[i];
      }
    }

    const form = document.getElementById("epay-redirection-form");

    if (form) {
      form.submit();
    }
  }

  verifyPayment() {
    const { epayerror } = this.getPage().getQuery();

    if (epayerror) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          message: {
            text: this.trans("epay-error"),
            type: "error",
          },
        });
    }

    return this;
  }

  validateAddress(shipping_address) {
    shipping_address.phone = shipping_address.phone
      ? shipping_address.phone.trim()
      : "";
    shipping_address.bell = shipping_address.bell
      ? shipping_address.bell.trim()
      : "";
    shipping_address.floor = shipping_address.floor
      ? shipping_address.floor.trim()
      : "";

    const error = {
      shipping_address: this.getHelpers("validate").validate(shipping_address, {
        bell: [
          {
            rule: "required",
            message: this.ucfirst("bell-helpertext"),
          },
        ],
        floor: [
          {
            rule: "required",
            message: this.ucfirst("floor-helpertext"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
      }),
    };

    if (error.shipping_address) {
      this.setData({
        "error.shipping_address": error.shipping_address,
      });
    }

    return error.shipping_address;
  }

  validateBusiness(business) {
    const error = {
      business: this.getHelpers("validate").validate(business, {
        company_name: [
          {
            rule: "required",
          },
        ],
        profession: [
          {
            rule: "required",
          },
        ],
        address: [
          {
            rule: "required",
          },
        ],
        tax_office: [
          {
            rule: "required",
          },
        ],
        tax_number: [
          {
            rule: "required",
          },
        ],
      }),
    };

    if (error.business) {
      this.setData({
        "error.business": error.business,
      });
    }

    return error.business;
  }

  /* errors section */

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          this.getPage().redirect("/");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }

  getCutleryAmount() {
    return this.getData("default.order.amounts.items", 1) + 1;
  }

  updateCutlery(id, amount) {
    this.updateService(id, amount);
  }
}
