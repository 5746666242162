import Page from "../../Page";
import templateDefault from "../../../templates/default/pages/weekly_steps/weekly_menu";

export default class WeeklyMenu extends Page {
  title = "weekly-menu";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({ "default.step": 2 });
    this.getApp().updateWindowTitle(
      `${this.ucfirst("menu")} | ${this.trans("window-title")}`
    );
  }
}
