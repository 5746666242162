import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  TextField,
  Link,
  Typography,
  IconButton,
  OutlinedInput,
  Container,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ForgotPasswordDialog from "../../../Components/Widgets/Dialogs/ForgotPasswordDialog";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

export default function LogIn(props) {
  const { component, dialog } = props;
  const theme = useTheme();
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [open, setOpen] = React.useState(false);

  const redirect = component.getPage().getQuery("redirect");

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { xs: 1, sm: 2 },
      }}
    >
      <Box>
        <Button
          type="submit"
          fullWidth
          sx={{
            margin: theme.spacing(2, 0),
            borderRadius: "4px",
            height: "40px",
            textTransform: "none",
            bgcolor: "transparent",
            border: "1px solid #dadce0",
            color: "#3c4043",
            fontWeight: 400,
            px: 1,
            "&:hover": {
              background: "rgba(66,133,244,.04)",
              borderColor: "#d2e3fc",
            },
            "& i": {
              fontSize: "20px",
              color: "#3b5998",
            },
          }}
          onClick={() => component.loginFB()}
          startIcon={<i className="fa-brands fa-facebook-f" />}
        >
          {component.ucfirst("register-facebook-btn")}
        </Button>
        <Box id="google-sign-in-btn" type="submit" variant="contained">
          <i
            className="fa-brands fa-google"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          {component.ucfirst("sign-in-google-btn")}
        </Box>
      </Box>
      <div
        style={{
          marginTop: 16,
          paddingTop: 16,
          borderTop: "1px solid rgba(0,0,0,.1)",
          width: "100%",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            zIndex: 1,
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50px",
            color: "#7d7d7d",
            backgroundColor: "#fff",
            border: "1px solid #fff",
            fontSize: "18px",
            padding: "0px 5px",
            width: "30px",
            height: "30px",
          }}
        >
          {component.trans("or")}
        </Typography>
      </div>
      <form
        style={{ width: "100%", marginTop: 8 }}
        onSubmit={(e) => component.login(e)}
      >
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("email-textfield")}
          name="email"
          autoComplete="email"
          helperText={component.getData("error.fields.auth.username")}
          value={component.getData("default.fields.auth.username", "")}
          onChange={(e) => {
            component.setData({
              "default.fields.auth.username": e.target.value,
            });
            component.deleteData("error.fields.auth.username");
          }}
          error={component.dataExists("error.fields.auth.username")}
        />
        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.auth.password")}
        >
          <InputLabel>{component.ucfirst("password-textfield")}</InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.auth.password", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.auth.password": e.target.value,
              });
              component.deleteData("error.fields.auth.password");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  disableRipple
                  sx={{
                    "&:hover, &:focus": { backgroundColor: "transparent" },
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={component.ucfirst("password-textfield")}
          />
          {component.dataExists("error.fields.auth.password") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.auth.password")}
            </FormHelperText>
          )}
        </PasswordField>
        <Button
          type="submit"
          sx={{
            ...theme.custom.buttons.button2,
            ...{ display: "block", mx: "auto", mt: 2 },
          }}
          disableRipple
        >
          {component.ucfirst("sign-in-btn")}
        </Button>
        <Link
          variant="body2"
          component="button"
          underline="hover"
          type="button"
          onClick={() => setOpen(true)}
          style={{
            color: "#7d7d7d",
          }}
        >
          {component.ucfirst("forgot-password")}
        </Link>
      </form>
      {dialog && (
        <>
          <div
            style={{
              marginTop: 16,
              paddingTop: 16,
              borderTop: "1px solid rgba(0,0,0,.1)",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
              {component.ucfirst("no-account-text")}
            </Typography>
          </div>
          <Button
            type="submit"
            sx={theme.custom.buttons.button2}
            disableRipple
            component={RouterLink}
            to={`/register` + (redirect ? `?redirect=${redirect}` : "")}
          >
            {component.ucfirst("register-btn")}
          </Button>
        </>
      )}
      <ForgotPasswordDialog
        {...{
          ...props,
          ...{
            open,
            setOpen: (boolean) => setOpen(boolean),
          },
        }}
      />
    </Container>
  );
}
