import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Typography,
  Box,
  Paper,
  Avatar,
  Button,
  Stack,
} from "@mui/material";

export default function PlanMobile(props) {
  const theme = useTheme();
  const { component, plan, index } = props;
  const { discount = false } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const { plans = [], discountAllowed = null } = component.getData(
    "default",
    {}
  );
  const company = component.getPage().getParam("company");
  const { name, total, price, min, weekly } = devHelper.getObjectValue(
    plan,
    "_values"
  );

  const showDiscount = discount && discountAllowed;

  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("top-banner").offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        border: weekly ? "10px solid #993366" : "6px solid #FF6F4D",
        bgcolor: "#fff",
        borderRadius: "20px",
        position: "relative",
        textAlign: "center",
        mx: "auto",
        pl: weekly ? 0 : 1,
        pr: weekly ? 0 : 2,
        py: weekly ? 2 : 0,
        maxWidth: 400,
        height: weekly ? "unset" : 110,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() =>
        company !== null ? handleClick() : component.selectPlan(plan)
      }
    >
      {weekly ? (
        <Stack
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Box
              sx={{
                height: 80,
                "& lord-icon": {
                  width: 80,
                  height: 80,
                },
              }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/hffrpcip.json"
                trigger="loop"
                stroke="bold"
                delay="1500"
                colors="primary:#121331,secondary:#993366"
              />
            </Box>
            <Box>
              <Typography
                component="h2"
                sx={{
                  color: "#000",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                {component.ucfirst("weekly")}
              </Typography>
              <Typography
                align="center"
                component="h3"
                sx={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: 1,
                }}
              >
                {component.trans("meal-packs")}
              </Typography>
            </Box>
          </Stack>
          <Typography
            align="center"
            component="h4"
            sx={{
              fontSize: 16,
              color: "#000",
              maxWidth: 220,
            }}
          >
            {component.trans("weekly-plan-desc")}
          </Typography>
          <Stack
            spacing={3}
            sx={{
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                "& span": {
                  lineHeight: 1,
                  color: "#000",
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                {component.trans("up-to")}
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 22,
                  fontWeight: 600,
                }}
              >
                {component
                  .getApp()
                  .priceFormater(component.trans("min-weekly-price") * 1)}
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {` / ${component.trans("meal")}`}
              </Box>
            </Stack>
            <Button
              sx={{
                ...theme.custom.buttons.button3,
                bgcolor: "transparent",
                color: "#000",
                border: "2px solid #000",
                "&:hover": {
                  bgcolor: "transparent",
                  color: "#000",
                },
              }}
            >
              {component.ucfirst("select")}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 66,
                height: 54,
                position: "relative",
              }}
            >
              <CardMedia
                component="img"
                image={`${filesUrl}/marketing/bag.webp?v=${img_version}`}
                sx={{
                  width: `${50 + (plans.length - index) * 4}px`,
                  height: `${50 + (plans.length - index) * 4}px`,
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translate(-50%,0)",
                }}
                alt={name}
                title={name}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    ...theme.custom.titles.title,
                    ...{
                      position: "relative",
                      mx: "auto",
                      lineHeight: 1,
                      fontSize: 40,
                    },
                  }}
                >
                  {total - min > 0 && (
                    <Typography
                      style={{
                        fontSize: 24,
                        position: "absolute",
                        top: -15,
                        right: -15,
                        fontWeight: 600,
                        color: "#000",
                        lineHeight: 1,
                      }}
                    >
                      +
                    </Typography>
                  )}
                  {min}
                </Typography>
              </Box>
              <Typography
                align="center"
                component="h3"
                sx={{
                  ...theme.custom.titles.title,
                  fontSize: 16,
                }}
              >
                {component.trans(min > 1 ? "meals" : "meal")}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              display: showDiscount ? "block" : "none",
              position: "absolute",
              top: 0,
              right: 20,
              transform: "translate(0, -50%)",
              "& i": {
                fontSize: 16,
                color: "#FF6F4D",
              },
            }}
          >
            <i
              className="fa-solid fa-minus fa-bounce"
              style={{ fontSize: 16 }}
            />
            <i className="fa-solid fa-1 fa-bounce" />
            <i className="fa-solid fa-0 fa-bounce" />
            <i className="fa-solid fa-percent fa-bounce" />
          </Typography>
          <Stack>
            {showDiscount && (
              <Box
                sx={{
                  color: "#000",
                  opacity: 0.6,
                  fontSize: 15,
                }}
              >
                <del>{component.getApp().priceFormater(price)}</del>
              </Box>
            )}
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                "& span": {
                  lineHeight: 1,
                  color: "#000",
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  color: showDiscount ? "#FF6F4D !important" : "#000",
                  fontSize: 22,
                  fontWeight: 600,
                }}
              >
                {showDiscount
                  ? component.getApp().priceFormater(price - price * 0.1)
                  : component.getApp().priceFormater(price)}
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {` / ${component.trans("meal")}`}
              </Box>
            </Stack>
          </Stack>
          <Avatar
            size="small"
            sx={{ color: "#fff", bgcolor: "#000", width: 30, height: 30 }}
          >
            <i className="fa-regular fa-arrow-right" />
          </Avatar>
        </Box>
      )}
    </Paper>
  );
}
