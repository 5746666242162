import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Order extends Service {
  //
  fetchOrder(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .retrieve(account, repository, {
          params: {
            orderId,
          },
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  createOrder(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "create", "order", {
          orderId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  fetchPaymentTypes(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.paymentTypes(account, repository).then((r) => {
        const result = this.evaluate(r, "paymentTypes");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  updatePlan(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      plan,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "plan", {
          orderId,
          plan,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updatePaymentType(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      paymentTypeId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "paymentType", {
          orderId,
          paymentTypeId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateDate(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      date,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "date", {
          orderId,
          date,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateTime(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      time,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "time", {
          orderId,
          time,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateWeekly(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      days_amount,
      weeks_amount,
      meals_amount,
      delivery_days,
      chef_selected,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "weekly", {
          orderId,
          days_amount,
          weeks_amount,
          meals_amount,
          delivery_days,
          chef_selected,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateSpot(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      spotId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "spot", {
          orderId,
          spotId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateDeliveryType(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      type,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "deliveryType", {
          orderId,
          type,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateAddress(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      billingAddress,
      shippingAddress,
      billingAddressId,
      shippingAddressId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "address", {
          orderId,
          billingAddress,
          shippingAddress,
          billingAddressId,
          shippingAddressId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  clearItems(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "clear", "items", {
          orderId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateItem(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      itemId,
      itemType,
      itemInstance,
      sku = "",
      items = [],
      values = {},
      amount = 1,
      itemDate = "",
      itemDeliveryDate = "",
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "item", {
          orderId,
          itemId,
          itemType,
          itemInstance,
          sku,
          items,
          values,
          amount,
          itemDate,
          itemDeliveryDate,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateService(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      serviceId,
      amount,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "service", {
          orderId,
          serviceId,
          amount: amount ? amount : 0,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateCoupon(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      couponCode,
      couponId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "coupon", {
          orderId,
          couponCode,
          couponId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  releaseCoupon(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "coupon", { orderId })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  addGuest(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      guest,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "add", "guest", { orderId, guest })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  preparePayment(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "prepare", "payment", {
          orderId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  verifyPayment(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "verify", "payment", {
          ...{ orderId },
          ...data,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  prepare(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      invoice = false,
      business = null,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      // this.setError();

      MainApi.order
        .action(account, repository, "prepare", "order", {
          orderId,
          invoice,
          business,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });

      // if (orderId) {

      // } else {
      //   this.setError("order-missing").delete();
      //   reject(this);
      // }
    });
  }

  place(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      transaction = null,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      // this.setError();

      MainApi.order
        .action(account, repository, "place", "order", {
          orderId,
          transaction,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            this.delete();
            resolve(this);
          } else {
            reject(this);
          }
        });

      // if (orderId) {

      // } else {
      //   this.setError("order-missing").delete();
      //   reject(this);
      // }
    });
  }

  evaluate(r, name) {
    const result = super.evaluate(r, name);
    const order = this.getData("order");
    const message = this.getData("_response").getMessage();
    const badMessages = ["order-missing", "order-expired", "order-placed"];

    if (result && order) {
      this.save(order);
    } else {
      if (badMessages.indexOf(message) >= 0) {
        this.setError(message);
        this.delete();
      }
    }

    return result;
  }

  save(order) {
    if (order) {
      Helpers.State.set("order_id", order._id, { path: "/" });
      this.order = order;
    } else {
      this.delete();
    }
    return this;
  }

  delete() {
    Helpers.State.remove("order_id", { path: "/" });
    this.setData("order", null);
  }

  getOrder() {
    return this.getData("order");
  }

  getOrderId() {
    return Helpers.State.get("order_id", null, { path: "/" });
  }

  getOrders(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.user.order
        .collection(account, repository, {
          params: {
            orderBy: "created_at",
            orderType: "desc",
          },
        })
        .then((r) => {
          const result = this.evaluate(r, "user-orders");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }
}

export default new Order();
