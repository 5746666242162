import Page from "../Page";
import template from "../../templates/default/pages/register_business";

export default class Business extends Page {
  title = "business";

  template = template;

  onLoad(data) {
    super.onLoad(data);

    this.getApp().updateWindowTitle(
      `${this.ucfirst("Business")} | ${this.trans("window-title")}`
    );
  }
}
