import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  List,
  Button,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  LinearProgress,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  // Alert,
} from "@mui/material";
import ProductWeeklyView from "../product_weekly_cart_view";
import EmptyPlate from "../empty_plate";

export default function WeeklyCartView(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const { order = null, menus = {} } = component.getData("default", {});
  const [selectedDay, setDay] = React.useState(false);

  const isAuth = component.isAuthenticated();
  const stringHelper = component.getHelpers("string");

  const address = devHelper.getObjectValue(order, "shipping_address.address");
  const time = devHelper.getObjectValue(order, "time.name");
  const {
    delivery_days = null,
    days_amount = null,
    weeks_amount = null,
    day_meals = null,
  } = devHelper.getObjectValue(order, "weekly", {});

  const max_meals = days_amount * weeks_amount * day_meals;

  const isSpecial =
    component.isAuthenticated() &&
    component.getAuth().getUserAttribute("role") === "special";
  //css

  const info = {
    textAlign: "center",
    color: "#000",
    fontSize: 18,
    my: "4px",
    fontWeight: 500,
    "& i": {
      mr: "4px",
    },
  };

  const editButton = {
    color: "#000",
    p: 0,
    mt: "-4px",
    fontSize: 18,
    "&:hover": {
      color: "#FF6F4D",
    },
    "& i": {
      ml: 3,
    },
  };

  const MyButton = {
    fontSize: 16,
    py: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    px: 1.5,
    visibility: window.location.pathname === "/checkout" ? "hidden" : "visible",
    textTransform: "none",
    background: "#FF6F4D",
    borderRadius: 50,
    border: "1px solid #FF6F4D",
    color: "#fff",
    "&:hover": {
      background: "#FF6F4D",
    },
  };

  const handleDayChange = (index) => {
    setDay(index === selectedDay ? false : index);
  };

  const table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 18,
    paddingTop: 0,
    fontWeight: 400,
    maxWidth: 170,
    py: 1,
    px: 2,
  };

  const right_table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 22,
    fontWeight: 600,
    py: 1,
    px: 2,
  };

  const myInfo = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    mx: { xs: 1, sm: 0.5 },
    "& i": {
      mr: 0.5,
    },
    "& span": {
      display: { xs: "flex", sm: "unset" },
    },
    "& span:first-of-type": {
      mr: { xs: 0, sm: 0.5 },
    },
    "& div": {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,
    },
  };

  return (
    <>
      {order && (
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 28, md: 35 },
              fontWeight: 500,
              color: "#000",
              mt: 1,
            }}
          >
            {component.ucfirst("your-order")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 3,
              mb: 1,
            }}
          >
            {weeks_amount && (
              <Typography
                variant="h2"
                sx={{
                  fontSize: 24,
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                {`${component.ucfirst(
                  "meal-pack"
                )} ${weeks_amount} ${component.trans("weeks")}`}
              </Typography>
            )}
          </Box>
          {(address || delivery_days || time) && (
            <Box
              sx={{
                border: "3px solid #FF6F4D",
                width: "100%",
                borderRadius: 1,
              }}
            >
              <Typography sx={info}>
                <i className="fa-regular fa-location-dot" />
                {address}
                <IconButton
                  disableRipple
                  component={RouterLink}
                  to="/select-address"
                  sx={editButton}
                  onClick={() => component.toggle()}
                >
                  <i className="fa-regular fa-pen-to-square" />
                </IconButton>
              </Typography>
              {delivery_days && (
                <Typography sx={info}>
                  <i className="fa-regular fa-calendar-range" />
                  {delivery_days.split(",").map((day, i) => {
                    if (delivery_days.split(",").length === i + 1) {
                      return component.ucfirst(stringHelper.capitalize(day));
                    } else {
                      return `${component.ucfirst(
                        stringHelper.capitalize(day)
                      )} & `;
                    }
                  })}
                </Typography>
              )}
              {time && (
                <Typography sx={info}>
                  <i className="fa-regular fa-clock" />
                  {time}
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ textAlign: "center", width: "100%", mt: 2, mb: 8 }}>
            {Object.keys(menus).map((week, index) => (
              <Box key={week}>
                {menus[week].map((del_date, i) => {
                  const { meals, date, dates_total, max_meals } = del_date;

                  let platesToShow = max_meals - dates_total;
                  return (
                    <Accordion
                      key={date}
                      expanded={selectedDay === date}
                      onChange={() => handleDayChange(date)}
                      disableGutters
                      elevation={0}
                      square
                      sx={{
                        borderBottom: "1px solid #000",
                        "&:before": {
                          display: "none",
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{ height: 50 }}
                        expandIcon={
                          <i className="fa-solid fa-chevron-down"></i>
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            pr: 1,
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              fontSize: { xs: 18, sm: 25 },
                              fontWeight: 500,
                              conor: "#000",
                            }}
                          >
                            {component
                              .getApp()
                              .dateFormater(date)
                              .slice(0, 3)
                              .map((value) => component.ucfirst(value))
                              .join(" ")}
                          </Box>
                          <Box
                            sx={{
                              width: 126,
                              position: "relative",
                              ml: 2,
                            }}
                          >
                            <LinearProgress
                              value={(dates_total / max_meals) * 100}
                              variant="determinate"
                              {...props}
                              sx={{
                                height: 24,
                                borderRadius: 50,
                                bgcolor: "grey",
                                "& .MuiLinearProgress-bar": {
                                  bgcolor:
                                    dates_total === max_meals
                                      ? "#2eb82e"
                                      : "#ff0000",
                                  transition: "transform .2s linear",
                                  WebkitTransition: "transform .2s linear",
                                },
                              }}
                            />
                            <Typography
                              align="center"
                              sx={{
                                fontWeight: 600,
                                position: "absolute",
                                color: "#fff",
                                fontSize: 14,
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "100%",
                              }}
                            >
                              {`${dates_total} / ${max_meals} ${component.trans(
                                "meals"
                              )}`}
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={{ pb: 2 }}>
                        <List
                          dense
                          sx={{ width: "100%", justifyContent: "top", p: 0 }}
                        >
                          {meals.map((meal, index) => (
                            <ProductWeeklyView
                              key={index}
                              {...{
                                ...props,
                                ...{
                                  meal,
                                  order,
                                  meals,
                                  datesTotal: dates_total,
                                  max_meals,
                                },
                              }}
                            />
                          ))}
                          {[
                            ...new Array(platesToShow < 0 ? 0 : platesToShow),
                          ].map((meal, index) => (
                            <EmptyPlate key={index} {...props} />
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            ))}
            {Object.keys(menus).length !== 0 && (
              <Typography
                variant="h3"
                align="right"
                sx={{
                  fontSize: 18,
                  mt: 1,
                  mb: 4,
                  fontWeight: 600,
                  color: "#800080",
                }}
              >
                {component.ucfirst("shelf-life")}
              </Typography>
            )}

            {days_amount && (
              <Box
                sx={{
                  borderBottom: "2px solid #800080",
                  bgcolor: "#fff",
                  py: 1.5,
                  width: 260,
                  mx: "auto",
                  mb: 2,
                }}
              >
                <Typography
                  align="center"
                  component="h1"
                  sx={{
                    fontSize: 18,
                    color: "#000",
                    fontWeight: 500,
                    lineHeight: 1,
                  }}
                >
                  {days_amount * day_meals}
                  {` ${component.trans("meals")} / ${component.trans("week")}`}
                </Typography>
              </Box>
            )}
            <Table sx={{ mb: 2 }}>
              <TableBody>
                {days_amount && (
                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("price-per-meal")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.items") /
                            (days_amount * weeks_amount * day_meals)
                        )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ ...table_cell }}>
                    {component.ucfirst("subtotal")}
                  </TableCell>
                  <TableCell sx={{ ...right_table_cell }} align="right">
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.items")
                      )}
                  </TableCell>
                </TableRow>
                {devHelper.getObjectValue(order, "totals.discount", 0) !==
                  0 && (
                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("discount")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      -
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.discount")
                        )}
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell sx={{ ...table_cell }}>
                    {component.ucfirst("shipping")}
                  </TableCell>
                  <TableCell sx={{ ...right_table_cell }} align="right">
                    {devHelper.getObjectValue(order, "totals.shipping", 0) === 0
                      ? component.ucfirst("free")
                      : component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.shipping")
                          )}
                  </TableCell>
                </TableRow>

                {devHelper.getObjectValue(order, "totals.cutlery", 0) !== 0 && (
                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("cutlery")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.cutlery")
                        )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ ...table_cell, fontWeight: 600 }}>
                    {component.ucfirst("total")}
                  </TableCell>
                  <TableCell sx={{ ...right_table_cell }} align="right">
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.grand")
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box sx={{ px: 2, mx: "auto" }}>
              {!isSpecial &&
              max_meals >
                devHelper.getObjectValue(order, "amounts.items", 0) ? (
                <Button
                  fullWidth
                  sx={{
                    mt: 4,
                    "&:disabled": {
                      fontSize: 18,
                      height: 46,
                      px: 1.5,
                      textTransform: "none",
                      background: "#fff",
                      borderRadius: 50,
                      border: "2px solid #FF6F4D",
                      color: "#000",
                      "&:hover": {
                        background: "#fff",
                      },
                      "& i": {
                        ml: 0.5,
                      },
                    },
                  }}
                  disableRipple
                  disabled
                >
                  {`${component.ucfirst("you-have")} ${
                    max_meals -
                    devHelper.getObjectValue(order, "amounts.items", 0)
                  } ${component.trans(
                    max_meals -
                      devHelper.getObjectValue(order, "amounts.items", 0) >
                      1
                      ? "empty-plates"
                      : "empty-plate"
                  )}`}
                  <i className="fa-regular fa-plate-utensils fa-bounce" />
                </Button>
              ) : (
                <Button
                  fullWidth
                  sx={{
                    ...MyButton,
                    mt: 4,
                  }}
                  disableRipple
                  onClick={() => {
                    isAuth
                      ? component.getApp().evaluateOrder("/weekly/checkout")
                      : component
                          .getComponents()
                          .findById("login-register-dialog")
                          .first()
                          .setData({
                            "default.open": true,
                          });
                    component.getApp().toggleDrawer("cart-drawer");
                  }}
                >
                  <Box sx={{ fontWeight: 600 }}>
                    <Avatar
                      sx={{
                        width: 28,
                        height: 28,
                        bgcolor: "#000",
                        fontSize: 16,
                        mr: 1,
                        display: "inline-flex",
                      }}
                    >
                      {devHelper.getObjectValue(order, "amounts.items", 0)}
                    </Avatar>
                    {component.ucfirst("checkout")}
                  </Box>
                  <Box
                    sx={{
                      ml: 2.5,
                    }}
                  >
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.grand")
                      )}
                  </Box>
                </Button>
              )}
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    ...myInfo,
                  }}
                >
                  <i className="fa-regular fa-leaf" />
                  <Box>
                    <span>{component.trans("organic")}</span>
                    <span>{component.trans("ingredients")}</span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    ...myInfo,
                  }}
                >
                  <i className="fa-regular fa-scale-balanced" />
                  <Box>
                    <span>Macro</span>
                    <span>Balanced</span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    ...myInfo,
                  }}
                >
                  <i className="fa-regular fa-dna" />
                  <Box>
                    <span>Premium</span>
                    <span>{component.trans("proteins")}</span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
