import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Grid,
  Typography,
  Container,
  Box,
  Button,
  useMediaQuery,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));
const MyFormControl = styled(FormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

export default function BusinessForm(props) {
  const theme = useTheme();
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Box sx={{ mb: 3 }} id="business_form">
      <Container maxWidth="lg" disableGutters sx={{ px: { xs: 1, md: 2 } }}>
        <Grid
          container
          sx={{
            dislpay: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
          rowSpacing={2}
          columnSpacing={6}
        >
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              image={`${filesUrl}/marketing/business-form-${view}.webp?v=${img_version}`}
              sx={{
                borderRadius: { xs: 15, md: 30 },
                maxWidth: "100%",
              }}
              alt="business image 1"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              align="center"
              sx={{
                ...theme.custom.titles.subtitle,
                ...{ mb: 4 },
              }}
            >
              {component.ucfirst("business-title-2")}
            </Typography>
            <Box
              component="form"
              sx={{
                textAlign: "center",
                px: 1,
              }}
              onSubmit={(e) => component.sendRequest(e)}
            >
              <Grid container justifycontent="center" spacing={1}>
                <Grid item xs={12}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("fullname-textfield")}
                    name="name"
                    autoComplete="name"
                    helperText={
                      component.getData("error.fields.name", [
                        { message: "" },
                      ])[0].message
                    }
                    value={component.getData("default.fields.name", "")}
                    onChange={(e) => {
                      component.setData({
                        "default.fields.name": e.target.value,
                      });
                      component.deleteData("error.fields.name");
                    }}
                    error={component.dataExists("error.fields.name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("textfield-company-name")}
                    name="company"
                    autoComplete="company"
                    placeholder="Coozein"
                    helperText={
                      component.getData("error.fields.company", [
                        { message: "" },
                      ])[0].message
                    }
                    value={component.getData("default.fields.company", "")}
                    onChange={(e) => {
                      component.setData({
                        "default.fields.company": e.target.value,
                      });
                      component.deleteData("error.fields.company");
                    }}
                    error={component.dataExists("error.fields.company")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("textfield-role")}
                    name="role"
                    autoComplete="role"
                    placeholder="Office Manager"
                    helperText={
                      component.getData("error.fields.role", [
                        { message: "" },
                      ])[0].message
                    }
                    value={component.getData("default.fields.role", "")}
                    onChange={(e) => {
                      component.setData({
                        "default.fields.role": e.target.value,
                      });
                      component.deleteData("error.fields.role");
                    }}
                    error={component.dataExists("error.fields.role")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("email-textfield")}
                    name="email"
                    helperText={
                      component.getData("error.fields.email", [
                        { message: "" },
                      ])[0].message
                    }
                    value={component.getData("default.fields.email", "")}
                    onChange={(e) => {
                      component.setData({
                        "default.fields.email": e.target.value,
                      });
                      component.deleteData("error.fields.email");
                    }}
                    error={component.dataExists("error.fields.email")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyFormControl
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                    error={component.dataExists("error.fields.amount")}
                  >
                    <InputLabel>{`${component.ucfirst(
                      "textfield-staff"
                    )}`}</InputLabel>
                    <Select
                      value={component.getData("default.fields.amount", "")}
                      label={`${component.ucfirst("textfield-staff")}`}
                      onChange={(e) => {
                        component.setData({
                          "default.fields.amount": e.target.value,
                        });
                        component.deleteData("error.fields.amount");
                      }}
                    >
                      {component.getAmounts().map((amount, index) => (
                        <MenuItem
                          key={`business-${amount}-${index}`}
                          value={index}
                        >
                          {amount}
                        </MenuItem>
                      ))}
                    </Select>
                    {component.dataExists("error.fields.amount") && (
                      <FormHelperText error sx={{ m: "3px 14px 0" }}>
                        {
                          component.getData("error.fields.amount", [
                            { message: "" },
                          ])[0].message
                        }
                      </FormHelperText>
                    )}
                  </MyFormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("phone-textfield")}
                    name="phone"
                    helperText={
                      component.getData("error.fields.phone", [
                        { message: "" },
                      ])[0].message
                    }
                    value={component.getData("default.fields.phone", "")}
                    onChange={(e) => {
                      component.setData({
                        "default.fields.phone": e.target.value,
                      });
                      component.deleteData("error.fields.phone");
                    }}
                    error={component.dataExists("error.fields.phone")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("postal_code-textfield")}
                    name="postal_code"
                    placeholder="11111"
                    helperText={
                      component.getData("error.fields.postal_code", [
                        { message: "" },
                      ])[0].message
                    }
                    value={component.getData("default.fields.postal_code", "")}
                    onChange={(e) => {
                      component.setData({
                        "default.fields.postal_code": e.target.value,
                      });
                      component.deleteData("error.fields.postal_code");
                    }}
                    error={component.dataExists("error.fields.postal_code")}
                  />
                </Grid>
              </Grid>
              <Button
                sx={{
                  my: 2,
                  ...theme.custom.buttons.button3,
                  color: "#000",
                  border: "2px solid #FF6F4D",
                  "&:hover": {
                    bgcolor: "#fff",
                  },
                }}
                type="submit"
              >
                {component.ucfirst("submit")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
