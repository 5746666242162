import Page from "../Page";
import templateDefault from "../../templates/default/pages/address";

export default class Address extends Page {
  title = "address";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({
      "default.step": window.location.pathname.includes("/weekly/") ? 0 : 1,
    });
    this.getApp().updateWindowTitle(
      `${this.ucfirst("address")} | ${this.trans("window-title")}`
    );
  }
}
