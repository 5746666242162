import Page from "../Page";
import templateDefault from "../../templates/default/pages/sample_menu";

export default class Menu extends Page {
  title = "sample_menu";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({ "default.step": 2 });

    const date = this.getPage().getParam("date", "");
    this.getApp().updateWindowTitle(
      `${this.ucfirst("menu")} ${this.getApp()
        .dateFormater(date)
        .slice(0, 3)
        .map((value) => this.ucfirst(value))
        .join(" ")} | ${this.trans("window-title")}`
    );
  }
}
