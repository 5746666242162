import React from "react";
import {
  Box,
  ListItem,
  Typography,
  Skeleton,
  IconButton,
  CardMedia,
} from "@mui/material";

export default function ProductCartView(props) {
  const { component, meal, order } = props;
  const devHelper = component.getHelpers("dev");
  const { price, amount } = meal;
  const {
    slug,
    name,
    meal_types = [],
    trans = [],
  } = devHelper.getObjectValue(meal, "item", {});

  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const max_meals = component.getHelpers("value").getValue("max-meals") * 1;
  const lang = component.getHelpers("language").getLang("en");
  const isSpecial =
    component.isAuthenticated() &&
    component.getAuth().getUserAttribute("role") === "special";

  const dimension = 100;

  return (
    <>
      {meal && (
        <ListItem
          disablePadding
          sx={{
            position: "relative",
            borderBottom: "1px solid #f7f7f7",
            py: 1,
          }}
        >
          <Box
            sx={{
              minWidth: "unset",
              height: dimension,
              width: dimension,
              marginRight: 1,
              display: "flex",
              position: "relative",
            }}
          >
            {!component.getData("default.imageReady", false) && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ borderRadius: "8px" }}
              />
            )}
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "30%",
                height: "100%",
                backgroundColor: "rgb(255, 130, 90,0.9)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 18,
                }}
              >
                {amount}
              </Typography>
            </Box>
            <CardMedia
              component="img"
              sx={{
                height: dimension,
                width: dimension,
              }}
              image={`${filesUrl}/products/webp/${slug}.webp?v=${img_version}`}
              alt={`${name} image`}
              onLoad={() => {
                component.setData({ "default.imageReady": true });
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 16, sm: 18 },
                fontWeight: 600,
                lineHeight: 1.2,
              }}
            >
              {trans.length !== 0 &&
                trans.find((tran) => tran.lang === lang).translation}
            </Typography>
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                pr: { xs: 0, sm: 2 },
              }}
            >
              <Typography
                sx={{
                  visibility: price === 0 ? "hidden" : "visible",
                  fontSize: 18,
                  fontWeight: 500,
                  display: "block",
                  color: "#fff",
                  bgcolor: "#800080",
                  borderBottomLeftRadius: 30,
                  borderTopRightRadius: 30,
                  p: "2px 12px",
                }}
              >
                +{component.getApp().priceFormater(price * amount)}
              </Typography>
              <Box
                sx={{
                  mt: 1,
                  "& .MuiIconButton-root": {
                    bgcolor: "transparent",
                    border: "2px solid #000",
                    color: "#000",
                    width: 35,
                    height: 30,
                    fontSize: 14,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                    "&:disabled": {
                      bgcolor: "#000",
                      color: "#fff",
                      opacity: 0.6,
                    },
                  },
                }}
              >
                <IconButton
                  onClick={() => {
                    component.updateCart(meal, -1);
                  }}
                  sx={{ mr: 1 }}
                >
                  <i className="fa-solid fa-minus"></i>
                </IconButton>
                <IconButton
                  onClick={() => {
                    component.updateCart(meal, 1);
                  }}
                  disabled={
                    meal_types.length !== 0 &&
                    devHelper.getObjectValue(
                      meal_types[0],
                      "item._values.slug"
                    ) !== "extra" &&
                    !isSpecial &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) !== 0 &&
                    devHelper.getObjectValue(order, "amounts.deals", 0) ===
                      max_meals
                  }
                >
                  <i className="fa-solid fa-plus" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </ListItem>
      )}
    </>
  );
}
