import React from "react";
import { Box, ListItem, Skeleton } from "@mui/material";

export default function ProductCartSkeletonView(props) {
  return (
    <ListItem
      disablePadding
      sx={{
        position: "relative",
        borderBottom: "1px solid #f7f7f7",
        py: 1,
      }}
    >
      <Box sx={{ mr: 1 }}>
        <Skeleton variant="circular" height={100} width={100} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          width: "100%",
        }}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          sx={{ height: { xs: 40, md: 22 } }}
        />
        <Skeleton variant="rounded" height={20} width="60%" sx={{ mt: 1 }} />
        <Box
          sx={{
            mt: 4.5,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            pr: { xs: 0, sm: 2 },
          }}
        >
          <Skeleton variant="rounded" height={30} width={35} sx={{ mr: 1 }} />
          <Skeleton variant="rounded" height={30} width={35} />
        </Box>
      </Box>
    </ListItem>
  );
}
