import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/navbar/navbar";
import Services from "../../../Services/Services";

export default class Navbar extends Component {
  id = "navbar";
  group = "order";
  pages = [
    "/meal-packs",
    "/select-address",
    "/select-date-time",
    "/menu",
    "/checkout",
    "/weekly/meal-packs",
    "/weekly/select-address",
    "/weekly/menu",
    "/weekly/checkout",
  ];

  template = templateDefault;

  onAuth() {
    this.refresh();
    this.retrieveCoupons();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getOrder();
    this.retrieveCoupons();
  }

  getOrder() {
    Services.get("order").then(async ([orderService]) => {
      var order = null;
      var showSteps = this.pages.some(
        (page) => page === window.location.pathname
      );
      try {
        await orderService.fetchOrder();
        order = orderService.getData("order");
      } catch (e) {}

      this.setData({
        "default.order": order ? order : {},
        "default.showSteps": order
          ? showSteps
          : window.location.pathname === "/meal-packs",
        "default.links":
          order && order.plan && order.plan.weekly
            ? [
                "/weekly/select-address",
                "/weekly/meal-packs",
                "/weekly/menu",
                "/weekly/checkout",
              ]
            : ["/meal-packs", "/select-date-time", "/menu", "/checkout"],
      });
    });
  }

  retrieveCoupons() {
    if (this.isAuthenticated()) {
      Services.get("coupon").then(([couponService]) => {
        couponService
          .getCoupons()
          .then((couponService) => {
            const coupons = couponService.getData("coupons", []);
            this.setData({
              "default.coupons": coupons,
            });
          })
          .catch((err) => {
            console.log("error", err);
          });
      });
    }
  }
}
