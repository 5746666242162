import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/navbar/simple_navbar";

export default class SimpleNavbar extends Component {
  id = "simple_navbar";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
  }
}
