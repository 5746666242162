import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Container,
  FormControl,
  Paper,
  Stack,
} from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));
const MyFormControl = styled(FormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

const truncate = (str, num) => {
  if (str.length <= num) return str;
  return str.slice(0, num) + "...";
};

export default function Address(props) {
  const { component } = props;
  const userRole = component.getAuth().getUserAttribute("role");
  const canCreateAddress = !userRole || userRole !== "business";

  const {
    addresses = [],
    valid = true,
    autocomplete = false,
    addressId,
    address,
  } = component.getData("default", {});

  return (
    <Box sx={{ height: "100vh", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          zIndex: "1",
          top: 50,
          left: 0,
          width: "100%",
        }}
      >
        <Container disableGutters maxWidth="xs">
          <Stack spacing={1} sx={{ alignItems: "flex-start" }}>
            <Paper
              elevation={2}
              sx={{
                bgcolor: "#fff",
                borderRadius: "4px",
                display: "inline-flex",
                flexGrow: 1,
                p: 1,
                width: "100%",
              }}
            >
              {!autocomplete ? (
                <MyFormControl fullWidth error={!valid}>
                  <InputLabel>
                    {component.ucfirst("street_address-textfield")}
                  </InputLabel>
                  <Select
                    value={component.getData("default.addressId", "")}
                    label={component.ucfirst("street_address-textfield")}
                    onChange={(e) => {
                      component.selectAddress(e.target.value);
                    }}
                    MenuProps={{ MenuListProps: { disablePadding: true } }}
                  >
                    {addresses.map((addr) => {
                      const { id, address } = addr;
                      return (
                        <MenuItem
                          key={id}
                          value={id}
                          sx={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                            fontWeight: 500,
                            py: 2,
                          }}
                        >
                          {`${truncate(component.ucfirst(address), 35)} `}
                        </MenuItem>
                      );
                    })}
                    {canCreateAddress && (
                      <MenuItem
                        sx={{
                          flexDirection: "column",
                          color: "#000",
                          py: 2,
                        }}
                        onClick={() => {
                          component.setData({ "default.autocomplete": true });
                          component.initGoogleAutocomplete();
                        }}
                      >
                        <Box sx={{ "& > i": { mr: "4px" }, fontWeight: 600 }}>
                          <i className="fak fa-light-location-dot-circle-plus" />
                          {component.trans("add-new-address-btn")}
                        </Box>
                      </MenuItem>
                    )}
                  </Select>
                </MyFormControl>
              ) : (
                <MyTextField
                  id="autocomplete"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("add-new-address-btn")}
                  error={!valid}
                />
              )}
            </Paper>
            <Button
              startIcon={<i className="fa-solid fa-circle-chevron-left" />}
              variant="contained"
              size="small"
              disableRipple
              onClick={() =>
                component.setData({ "default.autocomplete": false })
              }
              sx={{
                textTransform: "none",
                visibility:
                  autocomplete && addresses.length !== 0 ? "visible" : "hidden",
              }}
            >
              {component.ucfirst("my-addresses")}
            </Button>
          </Stack>
          <Button
            sx={{
              fontWeight: 600,
              bgcolor: "#FF6F4D",
              color: "#fff",
              border: "2px solid #FF6F4D",
              borderRadius: 50,
              fontSize: { xs: 18, md: 18 },
              width: { xs: "80%", sm: 200, md: 240 },
              height: "46px",
              textTransform: "none",
              opacity: 1,
              display: "block",
              mx: "auto",
              mt: 1,
              "&:hover": {
                bgcolor: "#FF6F4D",
              },
              "&:disabled": {
                display: "none",
              },
            }}
            variant="contained"
            disabled={
              !valid ||
              (autocomplete && !address) ||
              (!autocomplete && !addressId)
            }
            disableRipple
            onClick={() => component.setOrdersAddress()}
          >
            {component.ucfirst("continue")}
          </Button>
        </Container>
      </Box>
      <div
        id="map"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "inherit",
          zIndex: "0",
        }}
      ></div>
    </Box>
  );
}
