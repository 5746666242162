import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/thank_you_page/first_section";

export default class ThankYouPage extends Component {
  id = "thank-you-page";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getComponents()
      .findById("cart-drawer")
      .forEach((c) => c.clearCart());

    this.setData({
      "default.discount":
        this.getHelpers("auth").getUserAttribute("role") === "default",
    });
  }
}
