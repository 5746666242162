import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  TextField,
} from "@mui/material";
import ProductCheckoutView from "../product_checkout_view";

const MyTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "rgba(0, 0, 0, 0.7)",
    color: "rgba(0, 0, 0, 0.7)",
  },
}));

export default function Order(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order, meals, extra } = component.getData("default", {});

  const icons = JSON.parse(
    component.getHelpers("value").getValue("payment-types-icons")
  );
  //css

  const title = {
    fontWeight: 500,
    fontSize: { xs: "20px", sm: "24px", md: "28px" },
    lineHeight: 1,
    mb: 2,
  };

  const info = {
    color: "#000",
    fontSize: 18,
    my: "4px",
    fontWeight: 500,
    "& i": {
      mr: "4px",
    },
  };

  const table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 18,
    paddingTop: 0,
    fontWeight: 400,
    maxWidth: 170,
    py: 1,
    px: 2,
  };

  const right_table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 22,
    fontWeight: 600,
    py: 1,
    px: 2,
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        px: { xs: "4px", sm: 2, md: 3 },
      }}
      maxWidth="xl"
      disableGutters
    >
      {!order ? (
        <CircularProgress
          style={{
            margin: "80px 0",
            color: "#000",
          }}
        />
      ) : (
        <Grid
          container
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          direction="row"
          spacing={4}
        >
          <Grid item md={7} xs={12}>
            <Paper
              elevation={3}
              sx={{
                px: { xs: 2, md: 3 },
                py: 2,
                borderRadius: "12px",
              }}
            >
              <Typography
                sx={{
                  ...title,
                  mb: 0.5,
                  "& span": { ml: 3 },
                }}
              >
                {component.ucfirst("checkout-title-1")}
                <span>{component.getApp().statusFormater(order._status)}</span>
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 2 }}>
                {`${component
                  .getApp()
                  .dateFormaterNumeric(
                    devHelper.getObjectValue(order, "_created_at")
                  )} | #${devHelper
                  .getObjectValue(order, "orderId")
                  .slice(-4)}`}
              </Typography>
              <Box sx={{ my: 2 }}>
                <Box
                  sx={{
                    border: "3px solid #FF6F4D",
                    borderRadius: "12px",
                    mx: "auto",
                    textAlign: "center",
                  }}
                >
                  <Typography align="center" sx={info}>
                    <i className="fa-regular fa-location-dot" />
                    {devHelper.getObjectValue(
                      order,
                      "shipping_address.address"
                    )}
                  </Typography>
                  <Typography align="center" sx={info}>
                    <i className="fa-regular fa-calendar-range" />
                    {component
                      .getApp()
                      .dateFormater(
                        devHelper.getObjectValue(order, "date.date")
                      )
                      .slice(0, 3)
                      .map((value) => component.ucfirst(value))
                      .join(" ")}
                  </Typography>
                  <Typography align="center" sx={info}>
                    <i className="fa-regular fa-clock" />
                    {devHelper.getObjectValue(order, "time.name")}
                  </Typography>
                </Box>
              </Box>
              <Grid
                container
                justifycontent="center"
                spacing={1}
                sx={{ mb: 2 }}
              >
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    disabled
                    variant="standard"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("phone-textfield")}
                    name="phone"
                    value={devHelper.getObjectValue(
                      order,
                      "shipping_address.phone"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    disabled
                    variant="standard"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("bell-textfield")}
                    name="bell"
                    value={devHelper.getObjectValue(
                      order,
                      "shipping_address.bell"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    disabled
                    variant="standard"
                    margin="normal"
                    fullWidth
                    label={component.ucfirst("floor-textfield")}
                    name="floor"
                    value={devHelper.getObjectValue(
                      order,
                      "shipping_address.floor"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    disabled
                    variant="standard"
                    margin="normal"
                    fullWidth
                    multiline
                    label={component.ucfirst("notes-textfield")}
                    name="notes"
                    value={devHelper.getObjectValue(
                      order,
                      "shipping_address.notes"
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                mt: 2,
                px: { xs: 2, md: 3 },
                py: 2,
                borderRadius: "12px",
              }}
            >
              <Typography
                sx={{
                  ...title,
                }}
              >
                {component.ucfirst("payment-method")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: 500,
                  mb: 2,
                  "& div": {
                    textAlign: "center",
                    display: "inline-flex",
                  },
                  "& i": {
                    minWidth: 30,
                    ml: 1,
                    fontSize: 20,
                    color: "#f05d32",
                  },
                }}
              >
                {component.ucfirst(
                  devHelper.getObjectValue(order, "paymentType.slug")
                )}
                <Box>
                  <i
                    className={
                      icons[devHelper.getObjectValue(order, "paymentType.slug")]
                    }
                  />
                </Box>
              </Box>
              {devHelper.getObjectValue(order, "invoice") && (
                <Box>
                  <Typography
                    sx={{
                      ...title,
                    }}
                  >
                    {component.ucfirst("invoice")}
                  </Typography>
                  <Grid container justifycontent="center" spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        disabled
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label={component.ucfirst("textfield-company-name")}
                        value={devHelper.getObjectValue(
                          order,
                          "business.company_name"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        disabled
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label={component.ucfirst("textfield-profession")}
                        value={devHelper.getObjectValue(
                          order,
                          "business.profession"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        disabled
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label={component.ucfirst("textfield-address")}
                        value={devHelper.getObjectValue(
                          order,
                          "business.address"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        disabled
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label={component.ucfirst("textfield-tax-office")}
                        value={devHelper.getObjectValue(
                          order,
                          "business.tax_office"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        disabled
                        variant="standard"
                        margin="normal"
                        fullWidth
                        label={component.ucfirst("textfield-tax-number")}
                        value={devHelper.getObjectValue(
                          order,
                          "business.tax_number"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          </Grid>
          {/* order summary */}
          <Grid item md={5} xs={12}>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                px: { xs: 2, md: 3 },
                py: 2,
                borderRadius: "12px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <Typography
                  sx={{
                    ...title,
                  }}
                >
                  {component.ucfirst("checkout-title-4")}
                </Typography>
                <List dense sx={{ width: "100%", justifyContent: "top" }}>
                  {meals.map((meal, index) => (
                    <ProductCheckoutView
                      key={index}
                      {...{
                        ...props,
                        ...{
                          meal,
                          order,
                        },
                      }}
                    />
                  ))}
                  {extra.length !== 0 && (
                    <Typography
                      component="h1"
                      align="center"
                      sx={{
                        fontSize: { xs: 20, sm: 22, md: 25, lg: 30 },
                        fontWeight: 500,
                        color: "#000",
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      {component.ucfirst("extras")}
                    </Typography>
                  )}
                  {extra.map((meal, index) => (
                    <ProductCheckoutView
                      key={index}
                      {...{
                        ...props,
                        ...{
                          meal,
                          order,
                        },
                      }}
                    />
                  ))}
                </List>
                <Typography
                  variant="h3"
                  align="right"
                  sx={{
                    fontSize: 18,
                    mt: 1,
                    fontWeight: 600,
                    color: "#800080",
                  }}
                >
                  {component.ucfirst("shelf-life")}
                </Typography>
                <Box sx={{ maxWidth: 400, mx: "auto", mt: 3 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ ...table_cell }}>
                          {component.ucfirst("subtotal")}
                        </TableCell>
                        <TableCell sx={{ ...right_table_cell }} align="right">
                          {component
                            .getApp()
                            .priceFormater(
                              devHelper.getObjectValue(order, "totals.items")
                            )}
                        </TableCell>
                      </TableRow>
                      {devHelper.getObjectValue(order, "totals.discount", 0) !==
                        0 && (
                        <TableRow>
                          <TableCell sx={{ ...table_cell }}>
                            {component.ucfirst("discount")}
                          </TableCell>
                          <TableCell sx={{ ...right_table_cell }} align="right">
                            -
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(
                                  order,
                                  "totals.discount"
                                )
                              )}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell sx={{ ...table_cell }}>
                          {component.ucfirst("shipping")}
                        </TableCell>
                        <TableCell sx={{ ...right_table_cell }} align="right">
                          {devHelper.getObjectValue(
                            order,
                            "totals.shipping",
                            0
                          ) === 0
                            ? component.ucfirst("free")
                            : component
                                .getApp()
                                .priceFormater(
                                  devHelper.getObjectValue(
                                    order,
                                    "totals.shipping"
                                  )
                                )}
                        </TableCell>
                      </TableRow>
                      {devHelper.getObjectValue(order, "totals.cutlery", 0) !==
                        0 && (
                        <TableRow>
                          <TableCell sx={{ ...table_cell }}>
                            {component.ucfirst("cutlery")}
                          </TableCell>
                          <TableCell sx={{ ...right_table_cell }} align="right">
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(
                                  order,
                                  "totals.cutlery"
                                )
                              )}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell sx={{ ...table_cell, fontWeight: 600 }}>
                          {component.ucfirst("total")}
                        </TableCell>
                        <TableCell sx={{ ...right_table_cell }} align="right">
                          {component
                            .getApp()
                            .priceFormater(
                              devHelper.getObjectValue(order, "totals.grand")
                            )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
