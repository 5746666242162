import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  List,
  Button,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
} from "@mui/material";
import ProductCartView from "../product_cart_view";
import EmptyPlate from "../empty_plate";

export default function CartView(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const {
    order = null,
    meals = [],
    extra = [],
  } = component.getData("default", {});
  const isAuth = component.isAuthenticated();

  const address = devHelper.getObjectValue(order, "shipping_address.address");
  const time = devHelper.getObjectValue(order, "time.name");
  const date = devHelper.getObjectValue(order, "date.date");
  let platesToShow =
    devHelper.getObjectValue(order, "plan.min", 0) -
    devHelper.getObjectValue(order, "amounts.deals", 0);

  const isSpecial =
    component.isAuthenticated() &&
    component.getAuth().getUserAttribute("role") === "special";
  //css

  const info = {
    textAlign: "center",
    color: "#000",
    fontSize: 18,
    my: "4px",
    fontWeight: 500,
    "& i": {
      mr: "4px",
    },
  };

  const editButton = {
    color: "#000",
    p: 0,
    mt: "-4px",
    fontSize: 18,
    "&:hover": {
      color: "#FF6F4D",
    },
    "& i": {
      ml: 3,
    },
  };

  const MyButton = {
    fontSize: 16,
    py: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    px: 1.5,
    visibility: window.location.pathname === "/checkout" ? "hidden" : "visible",
    textTransform: "none",
    background: "#FF6F4D",
    borderRadius: 50,
    border: "1px solid #FF6F4D",
    color: "#fff",
    "&:hover": {
      background: "#FF6F4D",
    },
  };

  const table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 18,
    paddingTop: 0,
    fontWeight: 400,
    maxWidth: 170,
    py: 1,
    px: 2,
  };

  const right_table_cell = {
    color: "#000",
    borderBottom: "none",
    fontSize: 22,
    fontWeight: 600,
    py: 1,
    px: 2,
  };

  const myInfo = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    mx: { xs: 1, sm: 0.5 },
    "& i": {
      mr: 0.5,
    },
    "& span": {
      display: { xs: "flex", sm: "unset" },
    },
    "& span:first-of-type": {
      mr: { xs: 0, sm: 0.5 },
    },
    "& div": {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,
    },
  };

  return (
    <>
      {order && (
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: 28, md: 35 },
              fontWeight: 500,
              color: "#000",
              mt: 1,
            }}
          >
            {component.ucfirst("your-order")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 3,
              mb: 1,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: 24,
                fontWeight: 500,
                color: "#000",
              }}
            >
              {`${component.ucfirst("meal-pack")} ${devHelper.getObjectValue(
                order,
                "plan.min",
                0
              )}`}{" "}
              {devHelper.getObjectValue(order, "plan.total", 0) -
                devHelper.getObjectValue(order, "plan.min", 0) >
                0 && (
                <Box
                  component="span"
                  sx={{ verticalAlign: "super", fontSize: "smaller", ml: -0.7 }}
                >
                  +
                </Box>
              )}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: 24,
                fontWeight: 500,
                color: "#000",
              }}
            >
              {`${component
                .getApp()
                .priceFormater(
                  devHelper.getObjectValue(order, "plan.price")
                )} / ${component.trans("meal")}`}
            </Typography>
          </Box>
          {(address || date || time) && (
            <Box
              sx={{
                border: "3px solid #FF6F4D",
                width: "100%",
                borderRadius: 1,
              }}
            >
              <Typography sx={info}>
                <i className="fa-regular fa-location-dot" />
                {address}
                <IconButton
                  disableRipple
                  component={RouterLink}
                  to="/select-address"
                  sx={editButton}
                  onClick={() => component.toggle()}
                >
                  <i className="fa-regular fa-pen-to-square" />
                </IconButton>
              </Typography>
              {date && (
                <Typography sx={info}>
                  <i className="fa-regular fa-calendar-range" />
                  {component
                    .getApp()
                    .dateFormater(devHelper.getObjectValue(order, "date.date"))
                    .slice(0, 3)
                    .map((value) => component.ucfirst(value))
                    .join(" ")}
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    to="/select-date-time"
                    sx={editButton}
                    onClick={() => component.toggle()}
                  >
                    <i className="fa-regular fa-pen-to-square" />
                  </IconButton>
                </Typography>
              )}
              {time && (
                <Typography sx={info}>
                  <i className="fa-regular fa-clock" />
                  {time}
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    to="/select-date-time"
                    sx={editButton}
                    onClick={() => component.toggle()}
                  >
                    <i className="fa-regular fa-pen-to-square" />
                  </IconButton>
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ textAlign: "center", width: "100%", mb: 8 }}>
            <List dense sx={{ width: "100%", justifyContent: "top", p: 0 }}>
              {meals.map((meal, index) => (
                <ProductCartView
                  key={index}
                  {...{
                    ...props,
                    ...{
                      meal,
                      order,
                      meals,
                    },
                  }}
                />
              ))}
              {[...new Array(platesToShow < 0 ? 0 : platesToShow)].map(
                (meal, index) => (
                  <EmptyPlate key={index} {...props} />
                )
              )}
              {extra.length !== 0 && (
                <Typography
                  component="h1"
                  align="center"
                  sx={{
                    fontSize: { xs: 28, md: 35 },
                    fontWeight: 500,
                    color: "#000",
                    mt: 4,
                  }}
                >
                  {component.ucfirst("extras")}
                </Typography>
              )}
              {extra.map((meal, index) => (
                <ProductCartView
                  key={index}
                  {...{
                    ...props,
                    ...{
                      meal,
                      order,
                    },
                  }}
                />
              ))}
            </List>
            <Typography
              variant="h3"
              align="right"
              sx={{
                fontSize: 18,
                mt: 1,
                mb: 2,
                fontWeight: 600,
                color: "#800080",
              }}
            >
              {component.ucfirst("shelf-life")}
            </Typography>
            <Table sx={{ mb: 2 }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...table_cell }}>
                    {component.ucfirst("subtotal")}
                  </TableCell>
                  <TableCell sx={{ ...right_table_cell }} align="right">
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.items")
                      )}
                  </TableCell>
                </TableRow>
                {devHelper.getObjectValue(order, "totals.discount", 0) !==
                  0 && (
                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("discount")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      -
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.discount")
                        )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ ...table_cell }}>
                    {component.ucfirst("shipping")}
                    <br />
                    <span style={{ fontSize: 14 }}>
                      {component.ucfirst("free-shipping")}
                    </span>
                  </TableCell>
                  <TableCell sx={{ ...right_table_cell }} align="right">
                    {devHelper.getObjectValue(order, "totals.shipping", 0) === 0
                      ? component.ucfirst("free")
                      : component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.shipping")
                          )}
                  </TableCell>
                </TableRow>
                {devHelper.getObjectValue(order, "totals.cutlery", 0) !== 0 && (
                  <TableRow>
                    <TableCell sx={{ ...table_cell }}>
                      {component.ucfirst("cutlery")}
                    </TableCell>
                    <TableCell sx={{ ...right_table_cell }} align="right">
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(order, "totals.cutlery")
                        )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ ...table_cell, fontWeight: 600 }}>
                    {component.ucfirst("total")}
                  </TableCell>
                  <TableCell sx={{ ...right_table_cell }} align="right">
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.grand")
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box sx={{ px: 2, mx: "auto" }}>
              {!isSpecial &&
              devHelper.getObjectValue(order, "amounts.deals", 0) <
                devHelper.getObjectValue(order, "plan.min", 0) ? (
                <Button
                  fullWidth
                  sx={{
                    mt: 4,
                    "&:disabled": {
                      fontSize: 18,
                      height: 46,
                      px: 1.5,
                      textTransform: "none",
                      background: "#fff",
                      borderRadius: 50,
                      border: "2px solid #FF6F4D",
                      color: "#000",
                      "&:hover": {
                        background: "#fff",
                      },
                      "& i": {
                        ml: 0.5,
                      },
                    },
                  }}
                  disableRipple
                  disabled
                >
                  {`${component.ucfirst("you-have")} ${
                    devHelper.getObjectValue(order, "plan.min", 0) -
                    devHelper.getObjectValue(order, "amounts.deals", 0)
                  } ${component.trans(
                    devHelper.getObjectValue(order, "plan.min", 0) -
                      devHelper.getObjectValue(order, "amounts.deals", 0) >
                      1
                      ? "empty-plates"
                      : "empty-plate"
                  )}`}
                  <i className="fa-regular fa-plate-utensils fa-bounce"></i>
                </Button>
              ) : (
                <Button
                  fullWidth
                  sx={{
                    ...MyButton,
                    mt: 4,
                  }}
                  disableRipple
                  onClick={() => {
                    isAuth
                      ? component.getApp().evaluateOrder("/checkout")
                      : component
                          .getComponents()
                          .findById("login-register-dialog")
                          .first()
                          .setData({
                            "default.open": true,
                          });
                    component.getApp().toggleDrawer("cart-drawer");
                  }}
                >
                  <Box sx={{ fontWeight: 600 }}>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        bgcolor: "#000",
                        fontSize: 16,
                        mr: 1,
                        display: "inline-flex",
                      }}
                    >
                      {devHelper.getObjectValue(order, "amounts.items", 0)}
                    </Avatar>
                    {component.ucfirst("checkout")}
                  </Box>
                  <Box
                    sx={{
                      ml: 2.5,
                    }}
                  >
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(order, "totals.grand")
                      )}
                  </Box>
                </Button>
              )}
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    ...myInfo,
                  }}
                >
                  <i className="fa-regular fa-leaf" />
                  <Box>
                    <span>{component.trans("organic")}</span>
                    <span>{component.trans("ingredients")}</span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    ...myInfo,
                  }}
                >
                  <i className="fa-regular fa-scale-balanced" />
                  <Box>
                    <span>Macro</span>
                    <span>Balanced</span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    ...myInfo,
                  }}
                >
                  <i className="fa-regular fa-dna" />
                  <Box>
                    <span>Premium</span>
                    <span>{component.trans("proteins")}</span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
