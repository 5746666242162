import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/single_banner";
import Services from "../../../Services/Services";

export default class SingleBanner extends Component {
  id = "single-banner";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: this.props.scope },
        })
        .then((response) => {
          const sliders = response.getData() || [];

          this.setData({
            "default.slider": sliders.length !== 0 ? sliders[0] : null,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
