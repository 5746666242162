import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { Box, Fade, Button, Avatar } from "@mui/material";

export default function BackToTop(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const theme = useTheme();
  const { order, fade = false } = component.getData("default", {});

  //css

  const MyButton = {
    fontSize: 16,
    py: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    px: 1.5,
    visibility: window.location.pathname === "/checkout" ? "hidden" : "visible",
    width: { xs: "100%", sm: "auto" },
    textTransform: "none",
    background: "#FF6F4D",
    borderRadius: 50,
    border: "1px solid #FF6F4D",
    color: "#fff",
    "&:hover": {
      background: "#FF6F4D",
    },
  };

  return (
    <Fade in={fade}>
      <Box
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
          position: "fixed",
          zIndex: 1100,
          bottom: { xs: 0, md: 32 },
          right: { xs: "unset", md: 16 },
          left: { xs: 0, md: "unset" },
          textAlign: "center",
          width: { xs: "100%", md: "unset" },
          padding: 1,
        }}
      >
        {!order || !order.plan ? (
          <Button
            sx={{
              ...theme.custom.buttons.button2,
              ...{
                width: { xs: "100%", sm: "300px" },
                px: 2,
                "&:hover": {
                  bgcolor: "#FF6F4D",
                },
              },
            }}
            variant="contained"
            component={RouterLink}
            to="/meal-packs"
          >
            {component.ucfirst("start-now")}
          </Button>
        ) : (
          <Button
            sx={MyButton}
            disableRipple
            onClick={() => {
              component.getApp().toggleDrawer("cart-drawer");
            }}
          >
            <Box sx={{ fontWeight: 600 }}>
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: "#000",
                  fontSize:
                    String(devHelper.getObjectValue(order, "amounts.items", 0))
                      .length === 3
                      ? 10
                      : 14,
                  mr: 1,
                  display: "inline-flex",
                }}
              >
                {devHelper.getObjectValue(order, "amounts.items", 0)}
              </Avatar>
              {component.ucfirst("view-order")}
            </Box>
            <Box
              sx={{
                ml: 2.5,
              }}
            >
              {component
                .getApp()
                .priceFormater(devHelper.getObjectValue(order, "totals.grand"))}
            </Box>
          </Button>
        )}
      </Box>
    </Fade>
  );
}
