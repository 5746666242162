import Page from "../Page";
import templateDefault from "../../templates/default/pages/home";

export default class Home extends Page {
  title = "home";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.trans("window-title")} | Healthy & Fitness meals`
    );

    this.openRegister();
  }

  openRegister() {
    const company = this.getQuery("company");
    const special = this.getQuery("special");

    if (company || special) {
      this.getComponents()
        .findById("login-register-dialog")
        .first()
        .setData({
          "default.open": true,
          "default.onCompany": company ? true : false,
          "default.onSpecial": special ? true : false,
        });
    }
  }
}
