import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/weekly_steps/weekly_plans";
import Services from "../../../Services/Services";

export default class WeeklyPlans extends Component {
  id = "weekly-plans";
  group = "order";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.getOrder();
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");

          if (order) {
            const devHelper = this.getHelpers("dev");
            const weekly_prices = JSON.parse(
              devHelper.getObjectValue(order, "plan.weekly_prices", {})
            );

            this.setData({
              "default.plan": devHelper.getObjectValue(order, "plan", {}),
              "default.times": devHelper.getObjectValue(
                order,
                "spot.times",
                []
              ),
              "default.weekly_delivery_days":
                devHelper
                  .getObjectValue(order, "spot.weekly_delivery_days", "")
                  .split("&") || [],
              "default.weekly_prices": weekly_prices,
              "default.selectedPlan": order.weekly
                ? order.weekly.combination
                : "5-4-2",
              "default.selectedTime": order.time
                ? devHelper.getObjectValue(order, "time.slug")
                : devHelper.getObjectValue(order, "spot.times", []).length > 0
                ? devHelper.getObjectValue(
                    order.spot.times[0],
                    "item._values.slug"
                  )
                : false,
            });
          }
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  changePlan(s_plan, value, index) {
    const new_plan = s_plan.split("-");
    new_plan[index] = value;

    this.setData({
      "default.selectedPlan": new_plan.join("-"),
    });

    return this;
  }

  updatePlan(plan, day, times, selectedTime, chefChoice) {
    const time = times.find((time) => time.item._values.slug === selectedTime);
    const c_time = { ...{ id: time.item._id }, ...time.item._values };

    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.updateWeekly({
          days_amount: plan.split("-")[0],
          weeks_amount: plan.split("-")[1],
          meals_amount: plan.split("-")[2],
          delivery_days: day,
          chef_selected: chefChoice,
        });
        await orderService.updateTime({ time: c_time });

        const order = orderService.getData("order");

        this.getComponents()
          .findByGroup("order")
          .forEach((comp) => {
            comp.setData({ "default.order": order });
          });

        this.getComponents()
          .findById("cart-drawer")
          .forEach((c) => c.processOrder(order));

        this.getPage().redirect("/weekly/menu");
      } catch (orderService) {
        this.checkOrderError(orderService);
      }
    });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "not-allowed":
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              message: {
                text: this.trans(type),
                type: "error",
              },
            });
          this.getPage().redirect("/meal-packs");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
