import Page from "../Page";
import templateDefault from "../../templates/default/pages/menu";

export default class Menu extends Page {
  title = "menu";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({ "default.step": 2 });
    this.getApp().updateWindowTitle(
      `${this.ucfirst("menu")} | ${this.trans("window-title")}`
    );
  }
}
