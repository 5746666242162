import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Container, Box } from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function StepsGrid(props) {
  const theme = useTheme();
  const { component } = props;
  const { business = false } = component.props;

  const devHelper = component.getHelpers("dev");
  const sliders = component.getData("default.sliders", []);

  const gifs = [
    "https://cdn.lordicon.com/ydhvlyao.json",
    "https://cdn.lordicon.com/xatffqdr.json",
    "https://cdn.lordicon.com/tpbobdlz.json",
  ];

  const businessGifs = [
    "https://cdn.lordicon.com/bekzhvvw.json",
    "https://cdn.lordicon.com/zzjjvkam.json",
    "https://cdn.lordicon.com/tpbobdlz.json",
  ];

  return (
    <Box sx={{ my: 3 }}>
      <Container maxWidth="xl" disableGutters sx={{ px: { xs: 1, md: 2 } }}>
        <Box sx={{ borderTop: "2px solid #000", width: "50%", mx: "auto" }} />
        <Typography
          component="h1"
          sx={{
            ...theme.custom.titles.title,
            my: 4,
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {component.ucfirst("home-steps-title")}
        </Typography>
        <Grid
          container
          sx={{ justifyContent: "center", alignItems: "flex-start" }}
          rowSpacing={5}
          columnSpacing={1}
        >
          {sliders.map((slider, i) => {
            const { slug } = devHelper.getObjectValue(slider, "_values");
            return (
              <Grid item xs={12} md={4} key={slug}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                  rowSpacing={0}
                  columnSpacing={0}
                >
                  <Grid item xs={3.5} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        "& lord-icon": {
                          width: { xs: 100, md: 150 },
                          height: { xs: 100, md: 150 },
                        },
                      }}
                    >
                      <lord-icon
                        src={business ? businessGifs[i] : gifs[i]}
                        trigger="loop"
                        delay="1500"
                        colors="primary:#000000,secondary:#ff6f4c"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8.5} md={12}>
                    <Typography
                      component="h2"
                      sx={{
                        ...theme.custom.titles.subtitle,
                        textAlign: { xs: "left", md: "center" },
                      }}
                    >
                      <Translate
                        entity="item"
                        eslug={slug}
                        scope="default"
                        slug="title"
                        auto
                      />
                    </Typography>
                    <Box
                      sx={{
                        borderBottom: "3px solid #FF6F4D",
                        width: "50%",
                        mx: { xs: 0, md: "auto" },
                      }}
                    ></Box>
                    <Typography
                      component="h3"
                      align="center"
                      sx={{
                        ...theme.custom.titles.description,
                        mt: { xs: 2, md: 2 },
                        textAlign: { xs: "left", md: "center" },
                      }}
                    >
                      <Translate
                        entity="item"
                        eslug={slug}
                        scope="default"
                        slug="subtitle"
                        auto
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
