import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#FF6F4D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "#FF6F4D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FF6F4D",
    },
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        color: "#000",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ForgotPasswordDialog(props) {
  const { component } = props;
  const { open = false } = component.props;
  const theme = useTheme();

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      open={open}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: "25px",
          minWidth: { xs: "95%", sm: "400px" },
        },
      }}
    >
      <MyDialogTitle
        id="customized-dialog-title"
        onClose={() => component.props.setOpen(false)}
      >
        {component.trans("reset-password-title")}
      </MyDialogTitle>
      <DialogContent sx={{ p: 2, pt: 0 }}>
        <Typography variant="body2">
          {component.trans("reset-password-text-1")}
        </Typography>
        <form onSubmit={(e) => component.requestPassword(e)}>
          <MyTextField
            error={component.dataExists("error.fields.request.username")}
            value={component.getData("default.fields.request.username", "")}
            helperText={component.getData("error.fields.request.username")}
            onChange={(e) => {
              component.setData({
                "default.fields.request.username": e.target.value,
              });
              component.deleteData("error.fields.request.username");
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={component.ucfirst("email-textfield")}
            name="email"
          />
          <button type="submit" hidden></button>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button
          sx={{
            ...theme.custom.buttons.button2,
            ...{ display: "block", mx: "auto" },
          }}
          disableRipple
          onClick={() => component.requestPassword()}
        >
          {component.trans("submit-btn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
