import React from "react";
import ReactDOM from "react-dom";
import App from "./app/Components/App";
// import Env from "./modules/Core/Helpers/Env";
// import * as ServiceWorker from './serviceWorkerRegistration';

// const env = Env.get("env");

ReactDOM.render(<App />, document.getElementById("root"));

// if (env === 'production') {
// ServiceWorker.register();
// }
