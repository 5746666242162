import Page from "../Page";
import templateDefault from "../../templates/default/pages/product";

export default class Product extends Page {
  title = "product";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
  }
}
