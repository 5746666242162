import React from "react";
import { Container, Grid, Box } from "@mui/material";
import InfoSection from "../../widgets/checkout/info_section";
import CouponsSection from "../../widgets/checkout/coupons_section";
import PaymentSection from "../../widgets/checkout/payment_section";
import SummarySection from "../../widgets/checkout/summary_section";

export default function Checkout(props) {
  const { component } = props;

  const { order = null } = component.getData("default", {});

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: { xs: "4px", sm: 2, md: 3 },
          mt: { xs: 2, md: 3 },
          mb: { xs: 10, md: 3 },
        }}
        maxWidth="xl"
        disableGutters
      >
        {order && (
          <Grid
            container
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
            direction="row"
            spacing={4}
          >
            <Grid item md={7} xs={12}>
              <InfoSection {...props} />
              <CouponsSection
                {...{
                  ...props,
                  ...{ order },
                }}
              />
              <PaymentSection {...props} />
            </Grid>
            {/* order summary */}
            <Grid item md={5} xs={12}>
              <SummarySection {...props} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
