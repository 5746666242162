import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Container,
  Grid,
  Skeleton,
} from "@mui/material";
import ProductListView from "./product_list_view";
import ProductListSkeletonView from "./product_list_skeleton_view";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ my: 3 }}>{children}</Box>}
    </div>
  );
}

export default function SampleMenu(props) {
  const { component } = props;
  const date = component.getPage().getParam("date", "");
  const devHelper = component.getHelpers("dev");
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const {
    categories = [],
    meals = [],
    extra = [],
    order,
  } = component.getData("default", {});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography
        component="h1"
        align="center"
        sx={{
          ...theme.custom.titles.subtitle,
          ...{ lineHeight: 1.2 },
        }}
      >
        {component
          .getApp()
          .dateFormater(date)
          .slice(0, 3)
          .map((value) => component.ucfirst(value))
          .join(" ")}
      </Typography>
      <Typography
        component="h2"
        align="center"
        sx={{
          ...theme.custom.titles.description,
          ...{ fontWeight: 600, mb: 2, lineHeight: 1.2 },
        }}
      >
        {component.ucfirst("menu-title")}
      </Typography>
      <Box sx={{ bgcolor: "#f3f3f3" }}>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            pt: 3,
            pb: 8,
            px: { xs: 0, md: 2 },
            my: 0,
            minHeight: 1200,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "#f3f3f3",
            }}
          >
            {categories.length !== 0 ? (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                sx={{
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                {categories.map((category) => {
                  const { slug } = devHelper.getObjectValue(
                    category,
                    "_values"
                  );
                  return (
                    <Tab
                      disableRipple
                      key={slug}
                      label={component.ucfirst(slug)}
                      sx={{
                        fontWeight: 600,
                        bgcolor: "transparent",
                        p: 1,
                        px: 2,
                        color: "#000",
                        minHeight: 0,
                        border: "2px solid #000",
                        borderRadius: 50,
                        fontSize: 16,
                        mx: 0.5,
                        textTransform: "none",
                        "&.Mui-selected": {
                          color: "#fff",
                          bgcolor: "#000",
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
            ) : (
              <Tabs value={false}>
                {[...new Array(7)].map((item, i) => (
                  <Tab
                    key={`skeleton-category-${i}`}
                    sx={{ p: 0, minHeight: 0, m: 0 }}
                    label={
                      <Skeleton
                        variant="rectangular"
                        height={40}
                        width={130}
                        sx={{
                          mx: "4px",
                          borderRadius: 50,
                        }}
                      />
                    }
                    disabled
                  />
                ))}
              </Tabs>
            )}
          </Box>
          {categories.length !== 0 ? (
            <>
              {categories.map((category, index) => {
                const { slug } = devHelper.getObjectValue(category, "_values");
                return (
                  <TabPanel value={value} index={index} key={slug}>
                    <Grid
                      container
                      spacing={{ xs: 1, md: 2 }}
                      sx={{ px: { xs: 1, md: 2 } }}
                    >
                      {component.getCategorysMeals(slug, meals).map((meal) => {
                        const { slug } = devHelper.getObjectValue(
                          meal,
                          "item._values"
                        );
                        return (
                          <ProductListView
                            key={`${slug}-${index}`}
                            {...{
                              ...props,
                              ...{
                                meal,
                                order,
                                deal: true,
                                addToCart: false,
                              },
                            }}
                          />
                        );
                      })}
                    </Grid>
                  </TabPanel>
                );
              })}
              {extra.length !== 0 && (
                <Typography
                  component="h1"
                  align="center"
                  sx={{
                    ...theme.custom.titles.title,
                    ...{
                      mt: { xs: 4, md: 12 },
                      fontSize: { xs: 30, sm: 35, md: 40, lg: 45 },
                    },
                  }}
                >
                  {component.ucfirst("extras")}
                </Typography>
              )}
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                sx={{ px: { xs: 1, md: 2 }, pt: 4, mb: 0 }}
              >
                {component.getCategorysMeals("all-meals", extra).map((meal) => {
                  const { slug } = devHelper.getObjectValue(
                    meal,
                    "item._values"
                  );
                  return (
                    <ProductListView
                      key={`${slug}`}
                      {...{
                        ...props,
                        ...{
                          meal,
                          order,
                          addToCart: false,
                        },
                      }}
                    />
                  );
                })}
              </Grid>
            </>
          ) : (
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              sx={{ px: { xs: 1, md: 2 }, pt: 3, mb: 8 }}
            >
              {[...new Array(20)].map((item, i) => (
                <ProductListSkeletonView
                  key={`skeleton-meal-${i}`}
                  {...props}
                />
              ))}
            </Grid>
          )}
        </Container>
      </Box>
    </Box>
  );
}
