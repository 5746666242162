import CorePage from "../../modules/Core/Components/Page";

export default class Page extends CorePage {
  onLoad(data) {
    super.onLoad(data);

    let chat = document.getElementById("tidio-chat-iframe");

    const toggleChat = () => {
      if (chat) {
        chat.style.display =
          window.location.pathname === "/select-address" ||
          window.location.pathname === "/select-date-time" ||
          window.location.pathname.includes("/menu") ||
          window.location.pathname === "/checkout" ||
          window.location.pathname === "/weekly/select-address" ||
          window.location.pathname === "/weekly/meal-packs" ||
          window.location.pathname === "/weekly/menu" ||
          window.location.pathname === "/weekly/checkout"
            ? "none"
            : "block";
      }
    };

    if (!chat) {
      const intervalId = setInterval(() => {
        chat = document.getElementById("tidio-chat-iframe");

        if (chat) {
          clearInterval(intervalId);
          toggleChat();
        }
      }, 100);
    } else {
      toggleChat();
    }

    this.scrollToTop();
  }
}
