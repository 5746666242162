import Page from "../Page";
import templateDefault from "../../templates/default/pages/datetime_picker";

export default class DatetimePicker extends Page {
  title = "datetime-picker";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.setData({ "default.step": 1 });
    this.getApp().updateWindowTitle(
      `${this.ucfirst("date-time")} | ${this.trans("window-title")}`
    );
  }
}
