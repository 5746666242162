import React from "react";
// import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  SwipeableDrawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  IconButton,
  CardMedia,
  Link,
} from "@mui/material";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";
import CloseIcon from "@mui/icons-material/Close";

export default function MenuDrawer(props) {
  const { component } = props;
  // const theme = useTheme();
  const isAuth = component.isAuthenticated();
  const img_version = component.getHelpers("value").getValue("images-version");

  const myButton = {
    fontWeight: 600,
    fontSize: 24,
    color: "#000",
    textTransform: "none",
  };

  return (
    <SwipeableDrawer
      anchor="left"
      sx={{
        ".MuiDrawer-paper": {
          height: "100%",
          p: { xs: 1, sm: 2 },
          width: { xs: "100%", sm: "500px" },
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        },
        p: 0,
        minHeight: "unset",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0 8px 0 0",
        },
      }}
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
    >
      <IconButton
        onClick={() => component.toggle()}
        sx={{
          position: "absolute",
          right: 2,
          top: 2,
          fontSize: 25,
          color: "#000",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Link
        component={RouterLink}
        to="/"
        sx={{ mt: 1 }}
        onClick={() => component.toggle()}
      >
        <CardMedia
          component="img"
          image={`/logos/logo.webp?v=${img_version}`}
          style={{
            width: 180,
            height: "auto",
          }}
          alt="Coozein Home"
          title="Coozein Home"
        />
      </Link>
      <Box sx={{ mt: 3 }}>
        <LanguageSelect {...props} />
      </Box>
      <Button
        sx={{
          ...myButton,
          ...{ mt: 2 },
        }}
        disableRipple
        onClick={() => {
          component.toggle();
          component.getApp().evaluateOrder("/menu");
        }}
      >
        {component.ucfirst("menu")}
      </Button>
      <Button
        sx={{
          ...myButton,
          ...{ mt: 3 },
        }}
        disableRipple
        component={RouterLink}
        to="/faq"
        onClick={() => component.toggle()}
      >
        {component.ucfirst("faq")}
      </Button>
      {isAuth && (
        <Accordion
          disableGutters
          elevation={0}
          square
          sx={{
            mb: 1,
            "&::before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary sx={{ p: 0 }}>
            <Button
              sx={{
                ...myButton,
                ...{ mt: 3 },
              }}
              disableRipple
            >
              {component.trans("my-account")}
            </Button>
          </AccordionSummary>
          <AccordionDetails
            sx={{ p: 0, display: "flex", flexDirection: "column" }}
          >
            <Button
              sx={{
                ...myButton,
                ...{ mt: 1 },
              }}
              disableRipple
              component={RouterLink}
              to="/profile"
              onClick={() => component.toggle()}
            >
              {component.ucfirst("profile-btn")}
            </Button>

            <Button
              sx={{
                ...myButton,
                ...{ mt: 1 },
              }}
              disableRipple
              component={RouterLink}
              to="/addresses"
              onClick={() => component.toggle()}
            >
              {component.ucfirst("addresses-btn")}
            </Button>
            <Button
              sx={{
                ...myButton,
                ...{ mt: 1 },
              }}
              disableRipple
              component={RouterLink}
              to="/orders"
              onClick={() => component.toggle()}
            >
              {component.ucfirst("orders-btn")}
            </Button>
            <Button
              sx={{
                ...myButton,
                ...{ mt: 1 },
              }}
              disableRipple
              component={RouterLink}
              to="/coupons"
              onClick={() => component.toggle()}
            >
              {component.ucfirst("coupons-btn")}
            </Button>
          </AccordionDetails>
        </Accordion>
      )}
      {isAuth ? (
        <Button
          sx={{
            ...myButton,
            ...{ mt: 3 },
          }}
          disableRipple
          onClick={() => {
            component.getApp().logout();
            component.toggle();
          }}
        >
          {component.ucfirst("logout")}
        </Button>
      ) : (
        <Button
          sx={{
            ...myButton,
            ...{ mt: 3 },
          }}
          disableRipple
          onClick={() => {
            component
              .getComponents()
              .findById("login-register-dialog")
              .first()
              .setData({
                "default.open": true,
              });
            component.toggle();
          }}
        >
          {component.ucfirst("sign-in-btn")}
        </Button>
      )}
    </SwipeableDrawer>
  );
}
