import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/contact_us";
import Services from "../../../Services/Services";

export default class ContactUs extends Component {
  id = "contact-us";

  template = templateDefault;

  default_fields = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.fields": { ...this.default_fields },
    });
  }

  sendRequest(e) {
    e.preventDefault();

    // const { fields } = this.getData("default", {
    //   name: "",
    //   email: "",
    //   phone: "",
    //   message: "",
    // });

    // const error = {
    //   fields: {},
    // };

    // const { name, email, phone, message } = this.getData("default.fields", {});

    // if (!name) {
    //   error.fields["name"] = this.ucfirst("fullname-helpertext");
    // }

    // if (!email) {
    //   error.fields["email"] = this.ucfirst("email-helpertext");
    // } else {
    //   if (!this.getHelpers("validate").email(email)) {
    //     error.fields["email"] = this.ucfirst("invalid-email");
    //   }
    // }

    // if (phone && phone.length !== 10) {
    //   error.fields["phone"] = this.ucfirst("phone-helpertext-2");
    // }

    // if (!message) {
    //   error.fields["message"] = this.ucfirst("message-helpertext");
    // }

    // this.setData({
    //   error,
    // });

    const fields = this.getData("default.fields", { ...this.default_fields });

    const error = this.validateFields(fields);

    this.setData({
      error,
    });

    if (!error.fields) {
      // if (Object.keys(error.fields).length === 0) {
      Services.get("hook").then(async ([hookService]) => {
        await hookService.post("contact-us", fields);

        this.setData({
          "default.fields": { ...this.default_fields },
        });

        this.props.setOpen(false);

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            message: {
              text: this.trans("message-send"),
              type: "success",
              duration: 2000,
            },
          });
      });
    }
  }

  validateFields(fields) {
    const error = {
      fields: this.getHelpers("validate").validate(fields, {
        name: [
          {
            rule: "required",
            message: this.ucfirst("fullname-helpertext"),
          },
        ],
        email: [
          {
            rule: "required",
            message: this.ucfirst("email-helpertext"),
          },
          {
            rule: "email",
            message: this.ucfirst("invalid-email"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
        message: [
          {
            rule: "required",
            message: this.ucfirst("message-helpertext"),
          },
        ],
      }),
    };

    return error;
  }
}
