import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Link,
  CircularProgress,
  Box,
} from "@mui/material";

export default function Address(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const { orders = null } = component.getData("default", {});

  //css

  const info = {
    fontSize: { xs: 18, md: 20 },
    fontWeight: 500,
    color: "#000",
    my: "4px",
    display: "flex",
    alignItems: "center",
    "& i": {
      mr: 0.5,
    },
    "& span": {
      ml: 3,
    },
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="xl"
    >
      <Typography
        variant="h1"
        sx={{
          ...theme.custom.titles.title,
          ...{ mt: 2 },
        }}
      >
        {component.ucfirst("orders")}
      </Typography>
      {!orders ? (
        <CircularProgress
          style={{
            margin: "200px 0",
            color: "#FF6F4D",
          }}
        />
      ) : (
        <>
          {orders.length !== 0 ? (
            <Grid container maxWidth="md" sx={{ my: "30px" }} spacing={2}>
              {orders.map((order) => {
                const { id, orderId } = order;

                return (
                  <Grid item sm={6} xs={12} key={id}>
                    <Link
                      underline="none"
                      component={RouterLink}
                      to={"/order/" + id}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          borderRadius: "12px",
                          position: "relative",
                        }}
                        elevation={2}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            right: 16,
                            top: "50%",
                            transform: "translate(0, -50%)",
                            fontSize: 22,
                          }}
                          title={component.ucfirst(order._status)}
                        >
                          <i className="fa-solid fa-chevron-right" />
                        </Box>
                        <Typography sx={{ ...info }}>
                          <i className="fa-regular fa-calendar-range" />
                          {component
                            .getApp()
                            .dateFormater(
                              devHelper.getObjectValue(order, "date.date")
                            )
                            .slice(0, 3)
                            .map((value) => component.ucfirst(value))
                            .join(" ")}
                          <span>
                            {component.getApp().statusFormater(order._status)}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ ...info, mb: 2, fontSize: { xs: 16, md: 18 } }}
                        >
                          <i className="fa-regular fa-clock" />
                          {devHelper.getObjectValue(order, "time.name")}
                        </Typography>
                        <Typography sx={{ ...info }}>
                          {`${devHelper.getObjectValue(
                            order,
                            "amounts.items"
                          )} ${component.trans(
                            devHelper.getObjectValue(order, "amounts.items") > 1
                              ? "meals"
                              : "meal"
                          )}`}
                        </Typography>
                        <Typography sx={{ fontSize: 16 }}>
                          {`${component
                            .getApp()
                            .dateFormaterNumeric(
                              devHelper.getObjectValue(order, "_created_at")
                            )} | #${orderId.slice(-4)}`}
                        </Typography>
                      </Paper>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <div
              style={{
                textAlign: "center",
                margin: "175px 0",
              }}
            >
              <Typography>{component.ucfirst("zero-orders")}</Typography>
            </div>
          )}
        </>
      )}
    </Container>
  );
}
